/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { DeepPartial, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { camelCase, isEmpty } from 'lodash';

import { Button } from '@threeplayground/index';
import { Form } from '@threeplayground/unstable';

import { virtualEncodingOrderInput } from '~/schemas/live/orders/virtualEncodingOrderInput';
import { ThreeplayAPIContext } from '~/logic/unstable/ThreeplayApiProvider';

import { BaseInfo } from '../shared/BaseInfo';
import { LpcInfo } from '../shared/LpcInfo';
import { InputInfo } from '../shared/InputInfo';
import { OutputInfo } from '../shared/OutputInfo';
import { CaptionConfig } from '../shared/CaptionConfig';
import { ContactInfo } from '../shared/ContactInfo';
import {
  CreateLiveEventMutationPayload,
  Mutation,
  MutationCreateLiveEventArgs,
  MutationError,
} from '~/api/appApi';
import {
  DynamicSchemaValue,
  DynamicSchema,
  DynamicSchemaSection,
  RootProps,
  ServiceTypeOption,
} from '../../_types';
import { BestPracticesContext } from '../../BestPracticesProvider';
import { OrderSummary } from '../shared/OrderSummary';
import { ExtractedGraphQLError } from '~/logic/unstable/ThreeplayApiV2';
import { GraphQLGlobalError } from '~/packages/threeplayground/components/Form/Form';
import { liveEventsPaths } from '../../liveEventsPaths';

const GENERAL_ERROR_FOR_FIELD_ERRORS: ExtractedGraphQLError = {
  code: 'GENERAL_ERROR',
  path: 'global',
  message: 'Please address all input errors before continuing.',
};

const CREATE_LIVE_EVENT_MUTATION = `
mutation createLiveEvent($data: LiveEventInput!) {
  createLiveEvent(data: $data) {
    data {
      baseInfo {
        name
        eventStartTime
        serviceType
      }
      inputInfo {
        inputRtmpKey
        inputStreamUrl
      }
      outputInfo {
        embedCodeIframe
        embedCodeJavascript
      }
  }
    errors { path code message }
  }
}
`;

function getDefaultValues<T extends Record<string, unknown>>(
  dynamicSchemaSection: DynamicSchemaSection<T>
): DynamicSchemaValue<T> {
  return Object.keys(dynamicSchemaSection).reduce<DynamicSchemaValue<T>>((acc, key) => {
    return {
      ...acc,
      [key]: dynamicSchemaSection[key]?.default,
    };
  }, {});
}

interface OrderFormProps extends RootProps {
  /**
   * The code name for the live order type
   *
   * Note: This needs to be extended for each new live order type.
   */
  orderType: 'virtual_encoding';

  /**
   * The schema for the live order type.
   *
   * Note: This needs to be extended for each new live order type.
   */
  schema: typeof virtualEncodingOrderInput;

  /**
   * The live services that are applicable to the live order type.
   */
  serviceTypeOptions: ServiceTypeOption[];
}

/**
 * The generic OrderForm component to be used by all live order types
 */
export default function OrderForm(props: OrderFormProps) {
  type OrderInputType = z.infer<typeof props.schema>;
  const client = useContext(ThreeplayAPIContext);
  const { updateBestPractices } = useContext(BestPracticesContext);
  const navigate = useNavigate();

  const getOrderTypeInformation = (): { beta: boolean; displayName: string } => {
    const defaultOrderTypeInformation = {
      beta: false,
      displayName: 'Order',
    };

    const liveOrderType = props.liveOrderTypes.find(
      (liveOrderType) => liveOrderType.codeName === props.orderType
    );

    if (!liveOrderType) {
      return defaultOrderTypeInformation;
    }

    return {
      beta: liveOrderType.beta,
      displayName: liveOrderType.displayName,
    };
  };

  const getDefaults = (dynamicSchema: DynamicSchema): DeepPartial<OrderInputType> => {
    return {
      baseInfo: dynamicSchema.baseInfo && {
        ...getDefaultValues(dynamicSchema.baseInfo),
        orderType: props.orderType,
      },
      lpcInfo: dynamicSchema.lpcInfo && getDefaultValues(dynamicSchema.lpcInfo),
      inputInfo: dynamicSchema.inputInfo && getDefaultValues(dynamicSchema.inputInfo),
      outputInfo: dynamicSchema.outputInfo && getDefaultValues(dynamicSchema.outputInfo),
      captionConfig: dynamicSchema.captionConfig && getDefaultValues(dynamicSchema.captionConfig),
      contactInfo: dynamicSchema.contactInfo && getDefaultValues(dynamicSchema.contactInfo),
    };
  };

  const { mutateAsync } = useMutation<
    | CreateLiveEventMutationPayload
    | Pick<Mutation, 'createLiveEvent'>
    | { globalErrors: ExtractedGraphQLError[] }
    | null,
    unknown,
    MutationCreateLiveEventArgs
  >(
    (data) =>
      client.request<Pick<Mutation, 'createLiveEvent'>>(
        CREATE_LIVE_EVENT_MUTATION,
        data,
        'createLiveEvent'
      ),
    {}
  );

  const [fieldErrors, setFieldErrors] = useState<MutationError[] | undefined>(undefined);
  const [globalErrors, setGlobalErrors] = useState<GraphQLGlobalError[] | undefined>(undefined);

  const methods = useForm<OrderInputType>({
    defaultValues: getDefaults(props.dynamicSchema),
    resolver: zodResolver(props.schema),
  });
  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    if (!isEmpty(errors)) {
      setGlobalErrors([GENERAL_ERROR_FOR_FIELD_ERRORS]);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<OrderInputType> = async (inputData) => {
    // TODO: Fix the typing here
    const data = { data: inputData } as unknown as MutationCreateLiveEventArgs;

    // Attempt to create the order with the data passed
    const response = await mutateAsync(data);

    // Handle missing response
    if (!response) {
      setGlobalErrors([
        {
          code: 'GENERAL_ERROR',
          path: 'global',
          message: 'Unknown Error. Please contact support team.',
        } as ExtractedGraphQLError,
      ]);
      return;
    }

    // If there are any global errors, set them and return
    // Otherwise clear them
    if ('globalErrors' in response) {
      setGlobalErrors(response.globalErrors);
      return;
    }
    setGlobalErrors(undefined);

    // This should not happen since we pass in an extract key to the api request
    if ('createLiveEvent' in response) {
      return;
    }

    // Get the data and errors from the response
    const { data: responseData, errors } = response;

    // Set any global errors
    if (errors) {
      const globalErrors = errors.filter(
        (error) => error.path === 'global'
      ) as GraphQLGlobalError[];
      if (globalErrors.length) {
        setGlobalErrors(globalErrors);
      } else {
        setGlobalErrors([GENERAL_ERROR_FOR_FIELD_ERRORS]);
      }
    } else {
      setGlobalErrors(undefined);
    }

    // Set any field errors
    setFieldErrors(
      errors?.map((error) => ({
        ...error,
        path: error.path
          .replace(/^\//, '')
          .split('/')
          .map((segment) => camelCase(segment))
          .join('.'),
      })) || undefined
    );

    // If there is data in the response, then route to the success page
    if (responseData) {
      navigate(liveEventsPaths.orderSuccess.route, { state: { orderData: responseData } });
    }
  };

  const serviceType = watch('baseInfo.serviceType');
  const lpcEvent = serviceType === 'Professional';
  const eventDuration = (() => {
    switch (serviceType) {
      case 'Professional':
        return watch('lpcInfo.duration') || 0;
      default:
        return 0;
    }
  })();

  const validTranscoders = props.transcoders.filter((transcoder) =>
    lpcEvent ? transcoder.validForLpc : transcoder.validForLac
  );

  useEffect(() => {
    switch (serviceType) {
      case 'Professional':
        updateBestPractices({ add: ['lpc'], remove: ['lac'] });
        break;
      default:
        updateBestPractices({ add: ['lac'], remove: ['lpc'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType]);

  /**
   * The shape of the schema. This allows the OrderForm component to only display
   * sections that apply for each live order type.
   */
  const schemaShape = props.schema._def.schema.shape;

  return (
    <Container className="mt-3">
      <Row>
        <Col md={12} lg={7}>
          <h1>
            {getOrderTypeInformation().displayName}
            {getOrderTypeInformation().beta && (
              <span className="badge badge-danger ml-2">Beta</span>
            )}
          </h1>
        </Col>
      </Row>
      <Form errors={fieldErrors} globalErrors={globalErrors} onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-5">
          <Col md={12} lg={7}>
            <BaseInfo
              batches={props.batches}
              methods={methods}
              pricing={props.pricing}
              serviceTypeOptions={props.serviceTypeOptions}
              userTimeZone={props.userTimeZone}
            />
            <hr />
            {lpcEvent && 'lpcInfo' in schemaShape && (
              <>
                <LpcInfo
                  liveEventTypes={props.liveEventTypes}
                  methods={methods}
                  pricing={props.pricing}
                />
                <hr />
              </>
            )}
            {'inputInfo' in schemaShape && (
              <>
                <InputInfo
                  dynamicSchema={props.dynamicSchema.inputInfo}
                  lpcEvent={lpcEvent}
                  methods={methods}
                  transcoders={validTranscoders}
                />
                <hr />
              </>
            )}
            {'outputInfo' in schemaShape && (
              <>
                <OutputInfo methods={methods} />
                <hr />
              </>
            )}
            <CaptionConfig methods={methods} />
            <hr />
            <ContactInfo methods={methods} />
            <p>*Indicates required field</p>
          </Col>
          <Col md={12} lg={5}>
            <OrderSummary
              eventDuration={eventDuration}
              eventName={watch('baseInfo.name') || 'New Event'}
              eventStartTime={watch('baseInfo.eventStartTime')}
              pricing={props.pricing}
              serviceType={serviceType}
              submitButton={() => (
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Submit
                </Button>
              )}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
