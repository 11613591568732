import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function FacebookPaginator(props) {
  function goToPage(pageToken, type) {
    if (type === 'after') {
      props.setPageAfter(pageToken);
      props.setPageBefore('');
    } else {
      props.setPageBefore(pageToken);
      props.setPageAfter('');
    }
  }

  return (
    <ButtonGroup className="mr-2" aria-label="Pagination button group">
      <Button
        aria-label="Previous Page"
        className="m-1"
        disabled={props.paginationDetails.pageBefore === null}
        onClick={() => goToPage(props.paginationDetails.pageBefore, 'before')}
        variant="outline-primary"
      >
        <i className="fa fa-angle-double-left"></i>
      </Button>
      <Button
        aria-label="Next Page"
        className="m-1"
        disabled={props.paginationDetails.pageAfter === null}
        onClick={() => goToPage(props.paginationDetails.pageAfter, 'after')}
        variant="outline-primary"
      >
        <i className="fa fa-angle-double-right"></i>
      </Button>
    </ButtonGroup>
  );
}

FacebookPaginator.propTypes = {
  paginationDetails: PropTypes.object,
  setPageAfter: PropTypes.func,
  setPageBefore: PropTypes.func,
};

export default FacebookPaginator;
