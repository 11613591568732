import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import AlertMessage from '~/components/app/common/AlertMessage';
import WordlistTipPanel from './WordlistTipPanel';

function WordlistModal({
  eventName,
  wordlist,
  onClose,
  show,
  onUpdate,
  onSave,
  alertNoteMessage = null,
  readOnly = false,
}) {
  const mode = wordlist === null ? 'Add' : 'Edit';
  const modalTitle = `${mode} Wordlist: ${eventName}`;

  return (
    <Modal dialogClassName="modal-width-large" onHide={onClose} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {alertNoteMessage && (
            <AlertMessage message={alertNoteMessage} variant={readOnly ? 'warning' : 'primary'} />
          )}
          <Row>
            <Col>
              <Form.Group controlId="wordListTextArea">
                <Form.Label>
                  <strong>Add a different phrase or word on separate line</strong>
                </Form.Label>
                <Form.Control
                  aria-label="Enter words and phrases on each line"
                  as="textarea"
                  onChange={(e) => onUpdate(e.target.value)}
                  placeholder="Enter words and phrases on each line"
                  rows="12"
                  value={wordlist || ''}
                  readOnly={readOnly}
                />
              </Form.Group>
            </Col>
            <Col>
              <WordlistTipPanel />
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onSave()} disabled={readOnly}>
          {`${mode} Wordlist`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

WordlistModal.propTypes = {
  onClose: PropTypes.func,
  eventName: PropTypes.string,
  show: PropTypes.bool,
  onUpdate: PropTypes.func,
  onSave: PropTypes.func,
  wordlist: PropTypes.string,
  alertNoteMessage: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default WordlistModal;
