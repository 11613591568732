import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import InfoPopover from '~/components/app/common/InfoPopover';

import DateTimeComponent from '../DateTimeComponent';
import StartTimeOptions from '../../common/StartTimeOptions';

import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

import moment from 'moment-timezone';
import {
  getAvailableCaptionerStartTimeOptions,
  getDefaultCaptionerStartTime,
} from '../../common/startTimeHelper';
import { profileSettingsPath } from '~/helpers/app/paths';

const Streamless = ({
  eventsDispatch,
  userTimeZone,
  selectedEvents,
  setIntegrationId,
  defaultEvent,
}) => {
  const [calendarOpen, setCalenderOpen] = useState(false);

  const selectedEvent = selectedEvents[0];
  const eventStartTime = selectedEvent?.eventStartTime;

  const availableCaptionerStartTimes = useMemo(() => {
    return getAvailableCaptionerStartTimeOptions(eventStartTime, userTimeZone);
  }, [eventStartTime, userTimeZone]);

  const captionerJoinTime = useMemo(() => {
    return getDefaultCaptionerStartTime(availableCaptionerStartTimes);
  }, [availableCaptionerStartTimes]);

  // TODO: Move the common methods from this and rtmp into a common file
  function dispathSelectedEvent(action) {
    if (selectedEvent?.id) {
      eventsDispatch({ id: selectedEvent?.id, ...action });
    }
  }

  function validDate() {
    if (!selectedEvent) {
      return true;
    }

    const eventStartTime = selectedEvent.eventStartTime;

    const momentStartTime = moment(eventStartTime).tz(userTimeZone, true);
    const momentNow = moment().tz(userTimeZone, true);
    return momentStartTime.isValid() && momentStartTime.isSameOrAfter(momentNow.add(5, 'minutes'));
  }

  const setupEventDefaults = () => {
    setIntegrationId(null);

    const streamlessEventDefaultOverrides = {
      professionalCaptioning: true,
      captionerJoinTime: captionerJoinTime,
      hasLiveStream: false,
    };
    const event = {
      ...defaultEvent,
      ...streamlessEventDefaultOverrides,
      id: '1',
    };
    eventsDispatch({ type: 'setEvents', events: [event] });
  };

  useEffect(() => {
    // Selected Events are cleared on platform selection, triggering this form setup step only once
    if (selectedEvents.length === 0) {
      setupEventDefaults();
    }
  }, [selectedEvents]);

  return (
    <>
      <Alert className="w-50" variant="warning">
        <strong>Note:</strong> Events captioned with audio through a meeting link or phone call can
        only be captioned professionally, and will not have automatic captions as a fallback option.
        Captions can be delivered to the 3Play Second Screen or 3Play Embed.
      </Alert>
      <p className="my-1 pt-1">
        <strong>Service Type</strong>
      </p>
      <div>
        <Form.Control className="w-25" as="select" size="sm" disabled>
          <option>Professional Captions</option>
        </Form.Control>
      </div>
      <p className="my-0 pt-3">
        <strong>When will your event start?</strong>{' '}
        <InfoPopover linkText="Edit Timezone" title="Event Start Time">
          The <strong>Event Start Time</strong> is the expected start time your event will be
          displayed to your audience.
        </InfoPopover>
      </p>
      <div className="w-25">
        <div className="pb-1">Captions will start at this time.</div>
        Your timezone is currently set to <strong>{userTimeZone}</strong>.{' '}
        <a href={profileSettingsPath}>Edit Timezone</a>{' '}
        <InfoPopover linkText="Edit Timezone" title="Editing Timezones">
          If you have updated your timezone and are in the process of ordering a service in another
          tab, please restart your order to ensure your timezone is updated appropriately.
        </InfoPopover>
        <DateTimeComponent
          calendarOpen={calendarOpen}
          handleTimeChange={(time) => dispathSelectedEvent({ type: 'eventStartTime', time })}
          isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
          setCalenderOpen={setCalenderOpen}
          validDate={validDate}
          value={selectedEvent?.eventStartTime}
        />
      </div>
      <p className="my-0 pt-3">
        <strong>When will captioners be able to join the meeting?</strong>
      </p>
      <div className="pb-1">
        3Play recommends you start your event 20 minutes before to allow for captioners to check in
        and prepare captions for your event.{' '}
      </div>
      <div className="w-25">
        <StartTimeOptions
          availableStartTimes={availableCaptionerStartTimes}
          eventId={selectedEvent?.id}
          startTime={selectedEvent?.captionerJoinTime}
          setStartTime={(_eventId, time) =>
            dispathSelectedEvent({ type: 'captionerJoinTime', time })
          }
        />
      </div>
      <p className="my-0 pt-3">
        <strong>
          Meeting Location
          <ThreePlayTooltip tooltipText="The URL or phone number to allow the captioner to access your event audio (for example, a link to join a WebEx meeting). Include any username & password required to access the event audio." />
        </strong>
      </p>
      <Form.Control
        as="textarea"
        className="pt-2 w-25"
        rows={3}
        onChange={(e) =>
          dispathSelectedEvent({ type: 'meetingLocation', meetingLocation: e.target.value })
        }
        value={selectedEvent?.meetingLocation}
      />
      <p className="my-0 pt-3">
        <strong>
          Additional Instructions
          <ThreePlayTooltip tooltipText="Additional information that 3Play needs to know. This could include a secondary method for accessing the event and a primary event contact (email &/or phone)." />
        </strong>
      </p>
      <Form.Control
        as="textarea"
        className="pt-2 w-25"
        rows={3}
        onChange={(e) =>
          dispathSelectedEvent({
            type: 'additionalInstructions',
            additionalInstructions: e.target.value,
          })
        }
        value={selectedEvent?.additionalInstructions}
      />
      <p className="my-1">
        <strong>Event Name</strong>
      </p>
      <Form.Control
        aria-label="Event Name"
        className="w-25"
        placeholder="Event Name"
        size="sm"
        onChange={(e) => dispathSelectedEvent({ type: 'name', name: e.target.value })}
        value={selectedEvent?.name}
      />
    </>
  );
};

Streamless.propTypes = {
  defaultEvent: PropTypes.object,
  eventsDispatch: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(PropTypes.object),
  setIntegrationId: PropTypes.func,
  userTimeZone: PropTypes.string,
};

export default Streamless;
