import camelCase from 'lodash/camelCase';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import AccessibilityQuote from '~/components/app/common/AccessibilityQuote';
import TextBoxWithCopyButton from '~/components/app/common/TextBoxWithCopyButton';
import WithIcon from '~/components/app/common/WithIcon';

import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '../common/constants';
import { lacDashboardPath, lacScheduleNewEventPath } from '~/helpers/app/paths';
import { pluralize } from '~/helpers/strings';
import { scheduledEventsShape } from '../common/shapes';

import { FlipperFlagContext } from './OrderPage';

function EventDetailsTableHeader(props) {
  return (
    <>
      {props.tableHeaderFields[camelCase(props.platform)].map((header, i) => {
        return (
          <th className={css(styles.column)} key={i}>
            {header}
          </th>
        );
      })}
    </>
  );
}

const DisplayFieldWithValue = ({ value }) => {
  return (
    <td>
      <TextBoxWithCopyButton text={value} />
    </td>
  );
};

const NotRequiredField = ({ children }) => {
  return <td>{children}</td>;
};

function EventDetailsTableValues(props) {
  return (
    <>
      {props.tableValueFields[camelCase(props.platform)].map((field, i) => {
        return <DisplayFieldWithValue key={i} value={eval(field)} />;
      })}
    </>
  );
}

function EventDetailsTable(props) {
  function displayCaptionStartTimeForPlatform(platform) {
    return platform !== 'RTMP';
  }

  return (
    <Table bordered>
      <thead>
        <tr>
          <th className={css(styles.nameColumn)}>Event Name</th>
          <th className={css(styles.column)}>Event Start Time</th>
          {displayCaptionStartTimeForPlatform(props.platform) && (
            <th className={css(styles.column)}>Stream Start Time</th>
          )}
          <th className={css(styles.column)}>Service Type</th>
          <EventDetailsTableHeader
            platform={props.platform}
            tableHeaderFields={props.tableHeaderFields}
          />
        </tr>
      </thead>
      <tbody>
        {props.events.map((event) => {
          return (
            <tr key={event.id}>
              <td>{event.name}</td>
              <td>
                {moment(event.eventStartTime).tz(props.userTimeZone, true).format(DATE_TIME_FORMAT)}
              </td>
              {displayCaptionStartTimeForPlatform(props.platform) && (
                <td>
                  {moment(event.streamStartTime)
                    .tz(props.userTimeZone, true)
                    .format(DATE_TIME_FORMAT)}
                </td>
              )}
              <td>{event.halEvent ? 'Professional' : 'Automatic'}</td>
              <EventDetailsTableValues
                event={event}
                platform={props.platform}
                tableValueFields={props.tableValueFields}
              />
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function BrightcoveNote() {
  return (
    <p>
      Double-check that your event in Brightcove is activated before the event start time to ensure
      that captions are displayed successfully.
    </p>
  );
}

function ZoomNote(props) {
  return (
    <>
      <p>
        Due to limitations in Zoom’s system, you will need to copy the stream URL and stream key
        information into the Zoom application when you start each event.
      </p>
      <p>
        3Play may send an email reminder to <b>{props.userEmail}</b> 15 minutes before each event’s
        start time with instructions on how to finish setting up captions
      </p>
    </>
  );
}

function OrderConfirmationBody(props) {
  const successfullyScheduled = props.events?.length > 0;
  const eventType = props.platform === 'RTMP' ? 'live' : props.platform;
  const hasErrors = props.eventErrors?.length > 0;

  return (
    <Col sm={12}>
      {successfullyScheduled && !hasErrors && (
        <h2 className="text-success">
          <WithIcon icon="fa fa-check-circle-o">
            Captions were successfully scheduled for{' '}
            {pluralize(`${eventType} event`, props.events.length)}!
          </WithIcon>
        </h2>
      )}
      {props.eventErrors?.length > 0 && (
        <Alert className="mx-auto w-75" variant="danger">
          <Alert.Heading>
            <i className="fa fa-exclamation-triangle text-danger mr-1"></i>
            We were not able to schedule captions for your{' '}
            {props.events.length > 1 ? ' events:' : ' event:'}
          </Alert.Heading>
          <ul>
            {props.eventErrors.map((error, i) => {
              return (
                <li className="text-left" key={i}>
                  {error}
                </li>
              );
            })}
          </ul>
          <hr />
          <p>Please try scheduling captions for this event again or try again later.</p>
        </Alert>
      )}
      {successfullyScheduled && (
        <>
          <div className="mx-auto w-50">
            {props.platform === 'Zoom' && <ZoomNote userEmail={props.userEmail} />}
            {props.platform === 'Brightcove' && <BrightcoveNote />}
          </div>
          <EventDetailsTable
            events={props.events}
            platform={props.platform}
            tableHeaderFields={props.tableHeaderFields}
            tableValueFields={props.tableValueFields}
            userTimeZone={props.userTimeZone}
          />
        </>
      )}
      <a className="btn btn-outline-secondary btn-sm" href={lacDashboardPath} role="button">
        Back to Dashboard
      </a>
      <a className="ml-4 btn btn-primary btn-sm" href={lacScheduleNewEventPath} role="button">
        Schedule Another Event
      </a>
      <div className="mx-auto w-50">
        <AccessibilityQuote />
      </div>
    </Col>
  );
}
function OrderConfirmation(props) {
  const TABLE_HEADERS = {
    brightcove: ['Embed Code (iFrame)', 'Embed Code (Javascript)'],
    captionConductor: [],
    cvent: ['Embed Code (iFrame)', 'Embed Code (Javascript)'],
    facebook: ['Embed Code (iFrame)'],
    rtmp: [
      'Input Stream URL',
      'Input Stream Key',
      'Embed Code (iFrame)',
      'Embed Code (Javascript)',
    ],
    streamless: [
      'Embed Code (iFrame)',
      'Embed Code (Javascript)',
      'External Webpage with Captions',
    ],
    youTube: ['Stream URL', 'Stream Key'],
    zoom: ['Stream URL', 'Stream Key'],
  };

  const TABLE_VALUES = {
    brightcove: ['props.event.embedCodeIframe', 'props.event.embedCodeJs'],
    captionConductor: [],
    cvent: ['props.event.embedCodeIframe', 'props.event.embedCodeJs'],
    facebook: ['props.event.embedCodeIframe'],
    rtmp: [
      'props.event.streamDetails.streamUrl',
      'props.event.streamDetails.rtmpKey',
      'props.event.embedCodeIframe',
      'props.event.embedCodeJs',
    ],
    streamless: [
      'props.event.embedCodeIframe',
      'props.event.embedCodeJs',
      'props.event.externalWebpageWithCaptionsUrl',
    ],
    youTube: ['props.event.streamDetails.streamUrl', 'props.event.streamDetails.rtmpKey'],
    zoom: ['props.event.streamDetails.streamUrl', 'props.event.streamDetails.rtmpKey'],
  };

  const filteredrtmpTableHeaderFields = TABLE_HEADERS;
  const filteredrtmpTableValueFields = TABLE_VALUES;

  if (props.events.length > 0) {
    checkInputStreamKeyField();
  }

  function checkInputStreamKeyField() {
    if (
      props.platform === 'RTMP' &&
      props.events[0].streamDetails &&
      props.events[0].streamDetails.rtmpKey === null
    ) {
      filteredrtmpTableHeaderFields.rtmp = filteredrtmpTableHeaderFields.rtmp.filter(
        (header) => header !== 'Input Stream Key'
      );

      filteredrtmpTableValueFields.rtmp = filteredrtmpTableValueFields.rtmp.filter(
        (value) => value !== 'props.event.streamDetails.rtmpKey'
      );
    }
  }

  return (
    <Container className="py-2 text-center" fluid>
      <Row>
        <OrderConfirmationBody
          {...props}
          tableHeaderFields={filteredrtmpTableHeaderFields}
          tableValueFields={filteredrtmpTableValueFields}
        />
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  nameColumn: {
    width: '25vw',
    maxWidth: '400px',
  },
  column: {
    minWidth: '10vw',
  },
});

DisplayFieldWithValue.propTypes = {
  value: PropTypes.string,
};
EventDetailsTableHeader.propTypes = {
  platform: PropTypes.string,
  tableHeaderFields: PropTypes.object,
};

EventDetailsTableValues.propTypes = {
  event: scheduledEventsShape,
  platform: PropTypes.string,
  tableValueFields: PropTypes.object,
};

EventDetailsTable.propTypes = {
  events: PropTypes.arrayOf(scheduledEventsShape),
  platform: PropTypes.string,
  tableHeaderFields: PropTypes.object,
  tableValueFields: PropTypes.object,
  userTimeZone: PropTypes.string,
};

NotRequiredField.propTypes = {
  children: PropTypes.node,
};

OrderConfirmation.propTypes = {
  events: PropTypes.arrayOf(scheduledEventsShape),
  eventErrors: PropTypes.arrayOf(PropTypes.string),
  platform: PropTypes.string,
  userEmail: PropTypes.string,
  userTimeZone: PropTypes.string,
};

OrderConfirmationBody.propTypes = {
  events: PropTypes.arrayOf(scheduledEventsShape),
  eventErrors: PropTypes.arrayOf(PropTypes.string),
  platform: PropTypes.string,
  tableHeaderFields: PropTypes.object,
  tableValueFields: PropTypes.object,
  userEmail: PropTypes.string,
  userTimeZone: PropTypes.string,
};

ZoomNote.propTypes = {
  userEmail: PropTypes.string,
};

export default OrderConfirmation;
