import React from 'react';
import type { UseFormReturn, Path } from 'react-hook-form';
import moment from 'moment';
import { Form } from 'react-bootstrap';

import { Input, Select } from '@threeplayground/index';

import { BaseInfoInput, Batch, Pricing, ServiceTypeOption } from '../../_types';
import { profileSettingsPath } from '~/helpers/app/paths';

interface NestedBaseInfoInput {
  baseInfo: BaseInfoInput;
}

/**
 * The form partial for the base information
 *
 * Note: This is currently present in all live order types, so it always returns
 * a JSX.Element. If this changes, then this will need an overload that returns
 * null when the baseInfo is not present for a given order type to avoid
 * typing issues in the generic OrderForm component.
 */
export function BaseInfo<TFieldValues extends NestedBaseInfoInput>({
  batches,
  methods,
  pricing,
  serviceTypeOptions,
  userTimeZone,
}: {
  batches: Batch[];
  methods: UseFormReturn<TFieldValues>;
  pricing: Pricing;
  serviceTypeOptions: ServiceTypeOption[];
  userTimeZone: string;
}) {
  const {
    register,
    formState: { errors },
  } = methods;

  const displayedServiceTypeOptions = [
    {
      value: 'Professional',
      label: `Professional (${pricing.lpcCustomerProfessionalPricerate.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}/min)`,
      description:
        'Get matched to a highly-trained captioner for your event to ensure more accurate captions.',
    },
    {
      value: 'Automatic',
      label: `Automatic (${pricing.liveAutoCaptioningPricerate.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}/min)`,
      description:
        "Rely on 3Play's proprietary technology to automatically generate captions (accuracy not guaranteed).",
    },
  ].filter((option) => serviceTypeOptions.includes(option.value as ServiceTypeOption));

  return (
    <div className="mt-5 mb-3">
      <h3>Event Information</h3>
      <Input
        label="Event name*"
        error={errors?.baseInfo?.name}
        {...{
          ...register('baseInfo.name' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Input
        label="Event start time*"
        type="datetime-local"
        descriptionText={
          <>
            The time when captions will start based off your configured timezone. Your timezone is
            currently set to <strong>{userTimeZone}</strong>.{' '}
            <a href={profileSettingsPath} rel="noreferrer noopener" target="_blank">
              Edit Timezone <i className="fa fa-external-link"></i>
            </a>
          </>
        }
        error={errors?.baseInfo?.eventStartTime}
        {...{
          ...register('baseInfo.eventStartTime' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
        defaultValue={moment().add(10, 'minutes').format('YYYY-MM-DDTHH:mm')}
      />
      <Select
        label="File storage location*"
        options={batches.map(({ id, name }) => ({ id: id.toString(), name }))}
        error={errors?.baseInfo?.batchId}
        {...{
          ...register('baseInfo.batchId' as Path<TFieldValues>, { valueAsNumber: true }),
          min: undefined,
          max: undefined,
        }}
      />
      <fieldset>
        <Form.Group>
          <Form.Label>Service type*</Form.Label>
          {displayedServiceTypeOptions.map(({ description, label, value }) => (
            <Form.Check className="mb-2" key={value} type="radio" id={`service-type-${value}`}>
              <Form.Check.Input
                type="radio"
                aria-describedby={`service-type-${value}-describedby`}
                value={value}
                {...register('baseInfo.serviceType' as Path<TFieldValues>)}
              />
              <Form.Check.Label>{label}</Form.Check.Label>
              <Form.Text id={`service-type-${value}-describedby`}>{description}</Form.Text>
            </Form.Check>
          ))}
        </Form.Group>
      </fieldset>
    </div>
  );
}
