import React, { useState, useCallback, useMemo } from 'react';
import {
  AssetFileTransitionsEnum,
  useCreateAssetFileMutation,
  useTransitionAssetFileStateMutation,
  AssetClassNameEnum,
} from '~/api/jobsApi';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FileUpload } from './FileUpload';
import { keyBy } from 'lodash';

interface FileUploaderProps {
  assetId: string;
  assetType: AssetClassNameEnum;
  allowedFileFormats: AllowedFileFormat[];
  uploadedFiles: AssetFile[] | null;
}

export type FormData = {
  fileFormatId: string;
  file: FileList;
};

interface S3UploadParams {
  presignedUrl: string;
  file: File;
}

interface UploadFileParams {
  presignedUrl: string;
  file: File;
  assetFileId: string;
}

interface AssetFile {
  id: string;
  assetFileFormat: FileFormat;
  presignedUploadUrl: string;
}

interface AllowedFileFormat {
  assetFileFormat: FileFormat;
  required: boolean;
}

interface FileFormat {
  id: string;
  code: string;
  extension: string;
  displayName: string;
}

const uploadToS3 = ({ presignedUrl, file }: S3UploadParams): Promise<Response> =>
  fetch(presignedUrl, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'multipart/form-data',
      'Content-Length': file.size.toString(),
    }),
    body: file,
  });

// useDropzone can only accept common file type extension.
// For example, it can limit file uploads by the extension ".xml" but not ".starfish.xml"
// example conversions: ".starfish.xml" -> ".xml" and ".xml" -> ".xml"
const formatExtension = (extension: string): string => {
  const lastPeriod = extension.lastIndexOf('.');
  if (lastPeriod == -1) {
    return '';
  }

  return extension.substring(lastPeriod);
};

export const FileUploader = ({
  assetId,
  assetType,
  allowedFileFormats,
  uploadedFiles,
}: FileUploaderProps) => {
  const fileFormats = useMemo(() => {
    const assetFileFormats = allowedFileFormats.map(({ assetFileFormat, required }) => {
      return {
        id: assetFileFormat.id,
        code: assetFileFormat.code,
        displayName: `${assetFileFormat.displayName} ${required ? '(required)' : ''}`,
        extension: formatExtension(assetFileFormat.extension),
      };
    });

    return keyBy(assetFileFormats, 'id');
  }, [allowedFileFormats]);

  const [selectedFileFormat, setSelectedFileFormat] = useState<FileFormat | undefined>(
    Object.values(fileFormats)[0]
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { status: createAssetStatus, mutate: createAssetFileMutate } = useCreateAssetFileMutation();
  const { status: updateFileStateStatus, mutate: updateFileStateMutate } =
    useTransitionAssetFileStateMutation();

  const onDrop = useCallback(
    (files: File[]) => {
      if (!selectedFileFormat) {
        setErrorMessage('Could not find selected file format.');
        return;
      }

      const assetFile = getAssetFile(selectedFileFormat.id);
      const file = files[0];

      if (!file) {
        setErrorMessage('Could not find file.');
        return;
      }

      if (assetFile) {
        const { presignedUploadUrl: presignedUrl, id: assetFileId } = assetFile;
        uploadFile({ file, assetFileId, presignedUrl });
      } else {
        // this will handle creation & upload
        createAssetFile(file, selectedFileFormat.id);
      }
    },
    [selectedFileFormat]
  );

  const resetUploadMessages = () => {
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (allowedFileFormats.length == 0 || !selectedFileFormat) {
    setErrorMessage(
      'No allowed file formats found for this asset.  Please reach out to ops to resolve this issue.'
    );
  }

  const getAssetFile = (fileFormatId: string): AssetFile | undefined =>
    uploadedFiles?.find((file) => file.assetFileFormat.id == fileFormatId);

  const createAssetFile = (file: File, fileFormatId: string): void => {
    createAssetFileMutate(
      {
        data: {
          assetFileFormatId: fileFormatId,
          assetType: assetType,
          assetId: assetId,
        },
      },
      {
        onSuccess: ({ createAssetFile }) => {
          if (!createAssetFile || !createAssetFile.data || createAssetFile.errors) {
            setErrorMessage('Could not create the asset file record.');
            return;
          }

          const { presignedUploadUrl: presignedUrl, id: assetFileId } = createAssetFile.data;
          uploadFile({ presignedUrl, file, assetFileId });
        },
        onError: (e) => {
          setErrorMessage(e.message);
        },
      }
    );
  };

  const updateAssetFileState = (id: string, successfullyUploaded: boolean): void => {
    const state = successfullyUploaded
      ? AssetFileTransitionsEnum.UploadComplete
      : AssetFileTransitionsEnum.UploadFailed;

    updateFileStateMutate(
      {
        where: { idEq: id, assetType: assetType },
        transition: state,
      },
      {
        onSuccess: ({ transitionAssetFileState }) => {
          if (
            !transitionAssetFileState ||
            transitionAssetFileState.errors ||
            !transitionAssetFileState.data
          ) {
            setErrorMessage('Error: Failed to transition file state.');
          }

          if (!successfullyUploaded) {
            setErrorMessage('Error: Failed to upload file.');
            return;
          }
          setSuccessMessage('Successfully uploaded file! Please refresh the page.');
        },
        onError: () => {
          setErrorMessage('Error: Failed to transition file state.');
        },
      }
    );
  };

  const uploadFile = ({ presignedUrl, file, assetFileId }: UploadFileParams): void => {
    setIsFileUploading(true);

    uploadToS3({ presignedUrl, file })
      .then((response) => {
        setIsFileUploading(false);
        updateAssetFileState(assetFileId, response.ok);
      })
      .catch(() => setErrorMessage('Could not upload file.'))
      .finally(() => {
        setIsFileUploading(false);
      });
  };

  const isLoading =
    createAssetStatus == 'loading' || updateFileStateStatus == 'loading' || isFileUploading;

  const closeModal = () => {
    resetUploadMessages();
  };

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    setSelectedFileFormat(fileFormats[id]);
  };

  return (
    <>
      <Modal show={isLoading}>
        <Modal.Header>
          <Modal.Title>Please Wait. File Uploading.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please wait as your file uploads.</p>
        </Modal.Body>
      </Modal>

      <Modal show={!!errorMessage} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>File could not be uploaded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
      </Modal>

      <Modal show={!!successMessage} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>File successfully uploaded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{successMessage}</p>
        </Modal.Body>
      </Modal>

      <Form>
        <Form.Group controlId="formFileFormat" className="mb-3">
          <Form.Label>Select File Format</Form.Label>
          <Form.Control as="select" value={selectedFileFormat?.id} onChange={onFormChange}>
            {Object.values(fileFormats).map((assetFileFormat) => (
              <option value={assetFileFormat.id} key={assetFileFormat.id}>
                {assetFileFormat.displayName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>

      {selectedFileFormat && (
        <FileUpload extension={selectedFileFormat.extension} onDrop={onDrop} />
      )}
    </>
  );
};
