export const BRIGHTCOVE_PLATFORMS = ['Brightcove', 'Cvent'];

export const CAPTION_START_TIME_OPTIONS = {
  0: 'At Event Start Time',
  5: '5 Minutes Before',
  10: '10 Minutes Before',
  15: '15 Minutes Before',
  20: '20 Minutes Before',
  30: '30 Minutes Before',
  45: '45 Minutes Before',
  60: '60 Minutes Before',
  75: '75 Minutes Before',
  90: '90 Minutes Before',
};

export const CAPTIONER_START_TIME_STREAMLESS_EVENT_OPTIONS = {
  0: 'At Event Start Time',
  5: '5 Minutes Before',
  10: '10 Minutes Before',
  15: '15 Minutes Before',
  20: '20 Minutes Before',
  30: '30 Minutes Before',
  45: '45 Minutes Before',
};

export const LANGUAGE_OPTIONS = {
  1: 'English',
  13: 'Spanish',
};

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A z';
export const DATE_TIME_FORMAT_WITH_SECONDS = 'MM/DD/YYYY h:mm:ss A';
export const DATE_TIME_FORMAT_WITHOUT_TIMEZONE = 'MM/DD/YYYY h:mm A';

export const LAC_EVENT_PER_MIN_RATE = 0.6;
export const HAL_EVENT_FLAT_FEE = 50;
export const HAL_EVENT_PER_MIN_RATE = 2.5;

export const HAL_EVENT_THRESHOLD = 90;

export const MAXIMUM_EMAIL_REMINDER_TIME = 120;

export const MINIMUM_STREAM_WAIT_TIME = 10;
export const MAXIMUM_STREAM_WAIT_TIME = 120;

export const MAXIMUM_CAPTIONING_DELAY = 15000;
export const MINIMUM_CAPTIONING_DELAY = 2000;

export const PROFANITY_FILTER_LEVELS = {
  NONE: 0,
  NORMAL: 1,
  HIGH: 2,
  STRICT: 3,
};

export const PROFANITY_FILTER_OPTIONS = [
  {
    name: 'None',
    level: PROFANITY_FILTER_LEVELS.NONE,
    description: 'No offensive or explicit words will be filtered.',
  },
  {
    name: 'Normal',
    level: PROFANITY_FILTER_LEVELS.NORMAL,
    description:
      'Most explicit and offensive words will be filtered out. Recommended as the default setting.',
  },
  {
    name: 'Strict',
    level: PROFANITY_FILTER_LEVELS.STRICT,
    description: 'All explicit and offensive words and phrases will be filtered out and replaced.',
  },
];

export const RECOMMENDED_CAPTION_START_TIME = '15';
export const RECOMMENDED_CAPTIONER_START_TIME_STREAMLESS_EVENT = '20';

export const YOUTUBE_VIDEO_UNAVAILABLE_MESSAGE =
  'This video is unavailable because it is private or deleted';
