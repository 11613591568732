// Filter and format transcoder data based on lpc/lac selection

import { useMemo } from 'react';

export function useLiveTranscoders(liveTranscoders, { lpc }) {
  const filteredTranscoders = useMemo(() => {
    return lpc ? lpcTranscoders(liveTranscoders) : lacTranscoders(liveTranscoders);
  }, [liveTranscoders, lpc]);
  return filteredTranscoders;
}

function lpcTranscoders(transcoders) {
  return transcoders.filter((transcoder) => transcoder.validForLpc);
}

function lacTranscoders(transcoders) {
  return transcoders.filter((transcoder) => transcoder.validForLac);
}
