import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import '~/polyfills';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import ErrorOverlay from '~/components/app/common/error_boundaries/ErrorOverlay';

import ActionBar from './ActionBar';
import AddAlignment from './AddAlignment';
import BlankLoadingOverlay from '~/components/app/common/BlankLoadingOverlay';
import CollapsiblePanel from '~/components/app/common/CollapsiblePanel';
import ConfirmModal from '~/components/app/common/ConfirmModal';
import DarkOverlay from '~/components/app/common/DarkOverlay';
import DetailsBody from './DetailsBody';
import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';
import OrderedServices from './OrderedServices';
import OrderServicesButton from './OrderServicesButton';
import OverviewBody from './OverviewBody';
import PanelHeader from './PanelHeader';
import ReformatButton from './ReformatButton';
import ReviseButton from './ReviseButton';
import TabContainer from './TabContainer';
import Transcript from './Transcript';
import UnarchiveButton from './file_actions/UnarchiveButton';

import AmaraOrderModal from '~/components/app/media_files/modals/AmaraOrderModal';
import CancelServiceModal from '~/components/app/media_files/modals/CancelServiceModal';
import CheatSheetModal from '~/components/app/media_files/modals/CheatSheetModal';
import DeleteCaptionImportModal from '~/components/app/media_files/modals/DeleteCaptionImportModal';
import EditEncodedCaptionsModal from '~/components/app/media_files/modals/EditEncodedCaptionsModal';
import ImportTranslationModal from '~/components/app/media_files/modals/ImportTranslationModal';
import LinkIntegrationModal from '~/components/app/media_files/modals/LinkIntegrationModal';
import ManageTagsModal from '~/components/app/media_files/modals/ManageTagsModal';
import MoveToFolderModal from '~/components/app/media_files/modals/MoveToFolderModal';
import PreviewModal from '~/components/app/media_files/modals/PreviewModal';
import PublishVideoModal from '~/components/app/media_files/modals/PublishVideoModal';
import QuickLinkModal from '~/components/app/media_files/modals/QuickLinkModal';
import ReplaceSourceModal from '~/components/app/media_files/modals/ReplaceSourceModal';
import DownloadADModal from '~/components/app/media_files/modals/DownloadADModal';
import GetItNowModal from '~/components/app/media_files/modals/GetItNowModal';

import { getUrlParams, getUrlParam, setUrlParams } from '~/logic/UrlManager';
import { highlightFileSelection, unhighlightAllFileSelection, toggleSidebar } from '../IndexHelper';
import { SERVICE_TYPES } from '~/logic/services';
import { triggeredFromModal } from '~/components/app/common/Helpers';
import { ESCAPE_KEY_CODE, SPACE_KEY_CODE, TAB_KEY_CODE } from '~/helpers/constants';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';
import { fileDataQuery } from './data/queries';
import { archiveFileMutation, unarchiveFileMutation } from './data/mutations';
import { FeatureFlagContext } from './FeatureFlagContext';

const FOCUSABLE_ELEMENTS_QUERY =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

function refreshIndex() {
  window['$']('form.media-file-search-refresh').submit();
}

function ShowPanel({
  actionBarData,
  authToken,
  canReplaceSourceWithLinks,
  featureFlags,
  isInternalUser,
  isManagedAccount,
  open,
  setOpen,
  showGetItNow,
  showProgressBar,
  supportedFormats,
}) {
  const [activeTab, setActiveTab] = useState('overview');
  const [contentPublishing, setContentPublishing] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorsAlert, setErrorsAlert] = useState([]);
  const [enabledFeatures, setEnabledFeatures] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fileData, setFileData] = useState({});
  const [fileId, setFileId] = useState(null);
  const [fileInfoOpen, setFileInfoOpen] = useState(true);
  const [generalInfoOpen, setGeneralInfoOpen] = useState(true);
  const [orderedServicesOpen, setOrderedServicesOpen] = useState(true);
  const [refreshToken, setRefreshToken] = useState(null);
  const [servicesOpen, setServicesOpen] = useState({});
  const [success, setSuccess] = useState(null);
  const [transcriptId, setTranscriptId] = useState(null);
  const [transcriptLanguageCode, setTranscriptLanguageCode] = useState(null);
  const [transcriptOpen, setTranscriptOpen] = useState(true);
  const [userTimeZone, setUserTimeZone] = useState('America/New_York');

  const [audioDescriptionId, setAudioDescriptionId] = useState(null);
  const [cancelServiceServices, setCancelServiceServices] = useState(null);
  const [cheatSheetServiceType, setCheatSheetServiceType] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [deleteCaptionImportService, setDeleteCaptionImportService] = useState(null);
  const [downloadADModal, setShowDownloadADModal] = useState(false);
  const [encodedCaptionId, setEncodedCaptionId] = useState(null);
  const [format, setFormat] = useState(null);
  const [linkIntegrationVideoId, setLinkIntegrationVideoId] = useState(null);
  const [linkIntegrationVpis, setLinkIntegrationVpis] = useState([]);
  const [showAmaraOrderModal, setShowAmaraOrderModal] = useState(false);
  const [showCancelServiceModal, setShowCancelServiceModal] = useState(false);
  const [showCheatSheetModal, setShowCheatSheetModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationActions, setConfirmationActions] = useState({});
  const [showDeleteCaptionImportModal, setShowDeleteCaptionImportModal] = useState(false);
  const [showEditEncodedCaptionsModal, setShowEditEncodedCaptionsModal] = useState(false);
  const [showGetItNowModal, setShowGetItNowModal] = useState(false);
  const [showImportTranslationModal, setShowImportTranslationModal] = useState(false);
  const [showLinkIntegrationModal, setShowLinkIntegrationModal] = useState(false);
  const [showManageTagsModal, setShowManageTagsModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPublishVideoModal, setShowPublishVideoModal] = useState(false);
  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const [showReplaceSourceModal, setShowReplaceSourceModal] = useState(false);
  const [transcriptList, setTranscriptList] = useState(null);
  const [transcriptionService, setTranscriptionService] = useState(null);

  const file = fileData || {};
  const fileActions = file.fileActions || {};
  const orderProps = fileActions?.order || {};

  // Listen for clicks on the file links
  // Do this sort of thing as little as possible; this only exists to bridge
  // the gap between non-React land and thie component.
  useEffect(() => {
    window.addEventListener('mousedown', handleShowPanelClickEvent);
    return () => {
      window.removeEventListener('mousedown', handleShowPanelClickEvent);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      window.addEventListener('resize', checkToCloseFilter);
    }
    return () => {
      window.removeEventListener('resize', checkToCloseFilter);
    };
  });

  useEffect(initializeStateFromParams, []);

  function initializeStateFromParams() {
    const params = getUrlParams();
    if (params.hasOwnProperty('fid')) {
      setFileId(params.fid);
      openPanel(params.fid);
    }
    if (params.hasOwnProperty('tab')) {
      const tab = ['overview', 'details'].includes(params.tab) ? params.tab : 'overview';
      setActiveTab(tab);
    }
    if (params.hasOwnProperty('os')) {
      setOrderedServicesOpen(params.os === '1');
    }
    if (params.hasOwnProperty('txid')) {
      setTranscriptId(params.txid);
    }
    if (params.hasOwnProperty('tx')) {
      setTranscriptOpen(params.tx === '1');
    }
    if (params.hasOwnProperty('s')) {
      setServicesOpen(Object.fromEntries(params.s.split(',').map((type) => [type, true])));
    }
    if (params.hasOwnProperty('dfi')) {
      setFileInfoOpen(params.dfi === '1');
    }
    if (params.hasOwnProperty('gfi')) {
      setGeneralInfoOpen(params.gfi === '1');
    }
  }

  function checkToCloseFilter() {
    const { width } = getWindowDimensions();
    if (width < 1200 && !$('#sidebar').hasClass('inactive')) {
      toggleSidebar();
    }
  }

  // Use an effect to update the current browser URL when `value` is changed
  // * paramName: Name of querystring parameter.
  // * value: Value to watch. Usually or always a state variable
  // * transform (optional): how to translate `value` into a query param value
  //   * e.g. `x => x ? "1" : "0"` means `?param=1` or `?param=0` for a boolean.
  //   * Default bahvior is to use the value as-is (string param value)
  function bindUrlParam(paramName, value, transform) {
    // React Hooks MUST be called in the same order, so MAKE SURE that this
    // function is always called with the same param names & values, in the same
    // order, without conditional statements, for any state of this component.
    // See https://reactjs.org/docs/hooks-rules.html#explanation for more.
    useEffect(() => {
      if (open) {
        const useValue = transform ? transform(value) : value;
        setUrlParams(paramName, useValue);
      } else {
        setUrlParams(paramName, null);
      }
    }, [value, open]);
  }

  bindUrlParam('fid', fileId);
  bindUrlParam('tab', activeTab);
  bindUrlParam('os', orderedServicesOpen, (x) => (x ? '1' : '0'));
  bindUrlParam('txid', transcriptId);
  bindUrlParam('tx', transcriptOpen, (x) => (x ? '1' : '0'));
  bindUrlParam('s', servicesOpen, (s) => {
    return Object.keys(s)
      .filter((id) => servicesOpen[id])
      .join(',');
  });
  bindUrlParam('dfi', fileInfoOpen, (x) => (x ? '1' : '0'));
  bindUrlParam('gfi', generalInfoOpen, (x) => (x ? '1' : '0'));

  const handleShowPanelClickEvent = (event) => {
    const showPanelEvent = event.target.getAttributeNames().includes('data-file-id');
    if (showPanelEvent) {
      event.preventDefault();
      unhighlightAllFileSelection();
      const file = $(event.target).attr('data-file-id');
      highlightFileSelection(file);
      setFileId(file);
      openPanel(file);

      const serviceName = $(event.target).attr('data-service-name');
      if (serviceName) {
        const serviceKey = SERVICE_TYPES[serviceName];
        setServicesOpen((prev) => ({ ...prev, [serviceKey]: true }));
      }
    }
  };

  const handleKeyDown = (event) => {
    // This prevents actions if the below actions are performed from a modal
    if (triggeredFromModal(event)) {
      return;
    }

    const code = event.charCode || event.keyCode;
    if (code === ESCAPE_KEY_CODE) {
      userLogger.logEvent('FileShow', 'Close panel', { Method: 'Esc' });
      closePanel();
    } else if (code === SPACE_KEY_CODE) {
      handleShowPanelClickEvent(event);
    } else if (code === TAB_KEY_CODE) {
      const focusableElements = $('#show_panel').find(FOCUSABLE_ELEMENTS_QUERY);
      const firstFocusable = focusableElements[0];
      const lastFocusable = focusableElements[focusableElements.length - 1];
      if (event.shiftKey && document.activeElement === firstFocusable) {
        lastFocusable.focus();
        event.preventDefault();
      } else if (!event.shiftKey && document.activeElement === lastFocusable) {
        firstFocusable.focus();
        event.preventDefault();
      }
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function openPanel(file) {
    refreshData(file);
    setOpen(true);
    if ($('#close-panel')) {
      setTimeout(() => {
        $('#close-panel').focus();
      }, 0);
    }
    $('.more-actions-dropdown').attr('aria-disabled', 'true');
    $('.more-actions-dropdown').removeAttr('tabindex');
    $('.file-listing-component').attr('aria-hidden', true);
    $('.flash').remove();
    checkToCloseFilter();
  }

  function closePanel() {
    const currentFileId = getUrlParam('fid');
    setTimeout(() => {
      $(`#file-${currentFileId}`).trigger('focus');
    }, 0);

    unhighlightAllFileSelection();
    setFileId(null);
    setOpen(false);
    $('.more-actions-dropdown').attr('aria-disabled', 'false');
    $('.more-actions-dropdown').removeAttr('tabindex');
    $('.file-listing-component').attr('aria-hidden', false);
  }

  async function refreshData(fileIdToFetch) {
    if (!fileIdToFetch) {
      return;
    }
    setFetching(true);
    if (fileIdToFetch !== fileId) {
      resetFileResources();
      setFileId(fileIdToFetch);
    }
    unhighlightAllFileSelection();
    highlightFileSelection(fileIdToFetch);
    setFileId(fileIdToFetch);

    setRefreshToken(String(new Date().getTime()));
    const { errors, data = {} } = await threeplayApi.request(fileDataQuery, {
      fileId: fileIdToFetch,
      showProgressBar,
    });
    if (errors) {
      setFileData({});
      setContentPublishing([]);
      setEnabledFeatures([]);
      setUserTimeZone('America/New_York');
      setErrors(errors.map((error) => error.message));
    } else {
      setFileData(data.file);
      setContentPublishing(data.project.contentPublishing);
      setEnabledFeatures(data.project.enabledFeatures);
      setUserTimeZone(data.project.userTimeZone);
      setErrors([]);
      openSingleService(data.file);
    }
    setFetching(false);
  }

  function resetFileResources() {
    setErrorsAlert([]);
    setSuccess(null);
    setTranscriptId(null);
    setServicesOpen({});
  }

  function changeTab(tab) {
    setActiveTab(tab);
  }

  function openSingleService(fileInfo) {
    if (fileInfo.serviceGroups.length === 1 && fileInfo.serviceGroups[0].services.length === 1) {
      const key = SERVICE_TYPES[fileInfo.serviceGroups[0].name];
      setServicesOpen((prev) => ({ ...prev, [key]: true }));
    }
  }

  async function updateFile(func) {
    setFetching(true);
    const result = func && typeof func === 'function' ? await func() : undefined;
    refreshIndex();
    refreshData(fileId);
    return result;
  }

  async function archiveFile() {
    setFetching(true);
    const {
      data: { errors },
    } = await threeplayApi.request(archiveFileMutation, { fileId });
    if (errors) {
      setErrors(errors);
      setFetching(false);
    } else {
      setErrors([]);
      refreshIndex();
      refreshData(fileId);
    }
  }

  async function unarchiveFile() {
    setFetching(true);
    const {
      data: { errors },
    } = await threeplayApi.request(unarchiveFileMutation, { fileId });
    if (errors) {
      setErrors(errors);
      setFetching(false);
    } else {
      setErrors([]);
      refreshIndex();
      refreshData(fileId);
    }
  }

  function updateTranscript(transcript) {
    if (transcript) {
      setTranscriptId(transcript.id);
      setTranscriptLanguageCode(transcript.language.iso6391Code);
    }
  }

  function closeCancelServiceModal() {
    setShowCancelServiceModal(false);
  }

  function fileHasCaptionStyling() {
    let hasCaptionStyling = false;
    if (fileData.serviceGroups) {
      fileData.serviceGroups.forEach((group) => {
        group.services.forEach((service) => {
          if (service.type === 'CaptionStylingService') {
            hasCaptionStyling = true;
          }
        });
      });
    }
    return hasCaptionStyling;
  }

  const setShowModal = {
    manageTags: setShowManageTagsModal,
    cheatSheet: (show, serviceType) => {
      setShowCheatSheetModal(show);
      setCheatSheetServiceType(serviceType);
    },
    confirm: (show, { message, onCancel, onConfirm }) => {
      setConfirmMessage(message);
      setShowConfirmModal(show);
      setConfirmationActions({ onCancel, onConfirm });
    },
    importTranslations: setShowImportTranslationModal,
    publishVideo: setShowPublishVideoModal,
    amaraOrder: setShowAmaraOrderModal,
    preview: setShowPreviewModal,
    cancelService: (show, services) => {
      setShowCancelServiceModal(show);
      setCancelServiceServices(services);
    },
    move: setShowMoveModal,
    linkIntegration: (show, vpis, videoId) => {
      setShowLinkIntegrationModal(show);
      setLinkIntegrationVpis(vpis);
      setLinkIntegrationVideoId(videoId);
    },
    editEncodedCaptions: (show, encodedCaptionId) => {
      setShowEditEncodedCaptionsModal(show);
      setEncodedCaptionId(encodedCaptionId);
    },
    quickLink: (show, transcriptList, format) => {
      setShowQuickLinkModal(show);
      setTranscriptList(transcriptList);
      setFormat(format);
    },
    replaceSource: setShowReplaceSourceModal,
    downloadAd: (show, audioDescriptionId) => {
      setShowDownloadADModal(show);
      setAudioDescriptionId(audioDescriptionId);
    },
    deleteImport: (show, service) => {
      setShowDeleteCaptionImportModal(show);
      setDeleteCaptionImportService(service);
    },
    getItNow: (show, service) => {
      setShowGetItNowModal(show);
      setTranscriptionService(service);
    },
  };

  const fileActionFunctions = {
    archiveFile,
    unarchiveFile,
  };

  if (!open || !fileId) {
    return <div className={css(styles.panel, styles.nullPanel)} />;
  } else if (open && errors.length > 0) {
    return (
      <div className={css(styles.panel)}>
        <Button
          variant="light"
          aria-label="Close panel"
          id="close-panel"
          onClick={() => {
            userLogger.logEvent('FileShow', 'Close panel', { Method: 'Error button' });
            closePanel();
          }}
          className={css(styles.panelCloseIcon, styles.errorCloseButton)}
        >
          <i className="fa fa-times" />
        </Button>
        <ErrorOverlay messages={errors} />
        {fetching && <BlankLoadingOverlay />}
      </div>
    );
  }

  function successAlert(success) {
    return (
      <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        {success}
      </Alert>
    );
  }
  function errorAlert(errorsAlert) {
    return (
      <Alert
        variant="danger"
        className={css(styles.alertDanger)}
        onClose={() => setErrorsAlert([])}
        dismissible
      >
        <Alert.Heading>Error!</Alert.Heading>
        <p>{errorsAlert}</p>
      </Alert>
    );
  }

  function CollapsibleServicePanel() {
    return (
      <CollapsiblePanel
        title="Ordered Services"
        open={orderedServicesOpen}
        setOpen={setOrderedServicesOpen}
        actionItems={
          <>
            {fileActions?.order?.enabled && (
              <OrderServicesButton
                enabledFeatures={enabledFeatures}
                reprocessingAssets={fileData.reprocessingAssets}
                fileId={fileData.id}
                fileName={fileData.name}
                orderActions={orderProps}
                fileHasSource={file.hasSource}
                setShowModal={setShowModal}
              />
            )}
            {fileActions?.order?.revisionServices?.length > 0 && (
              <ReviseButton fileId={fileData.id} services={fileActions.order.revisionServices} />
            )}
            {fileActions?.order?.reformatServices?.length > 0 && (
              <ReformatButton fileId={fileData.id} services={fileActions.order.reformatServices} />
            )}
          </>
        }
      >
        <InnerServicePanel />
      </CollapsiblePanel>
    );
  }

  function InnerServicePanel() {
    return (
      <OrderedServices
        enabledFeatures={enabledFeatures}
        fileData={file}
        isInternalUser={isInternalUser}
        isManagedAccount={isManagedAccount}
        serviceGroups={fileData.serviceGroups}
        refreshToken={refreshToken}
        setErrorsAlert={setErrorsAlert}
        setSuccess={setSuccess}
        servicesOpen={servicesOpen}
        setServicesOpen={setServicesOpen}
        setShowModal={setShowModal}
        showGetItNow={showGetItNow}
        showProgressBar={showProgressBar}
        onUpdate={updateFile}
        userTimeZone={userTimeZone}
      />
    );
  }

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      <div aria-labelledby="showPanelDialogTitle" className={css(styles.panel)} role="dialog">
        <Button
          variant="primary"
          id="close-panel"
          aria-label="close"
          onClick={() => {
            userLogger.logEvent('FileShow', 'Close panel', { Method: 'Button' });
            closePanel();
          }}
          className={css(styles.panelCloseIcon)}
        >
          <i className="fa fa-times" />
        </Button>

        <div className={css(styles.panelHeader)}>
          <ErrorBoundary component="FileShowPanelHeader">
            <PanelHeader fileData={file} userTimeZone={userTimeZone}>
              {file.archived ? (
                <UnarchiveButton onClick={unarchiveFile} />
              ) : (
                <ActionBar
                  enabledFeatures={enabledFeatures}
                  fileActions={fileActions}
                  fileActionFunctions={fileActionFunctions}
                  fileData={file}
                  fileId={fileId}
                  actionBarData={actionBarData}
                  projectId={file.projectId}
                  setErrorsAlert={setErrorsAlert}
                  setShowModal={setShowModal}
                  setSuccess={setSuccess}
                  transcriptId={transcriptId}
                />
              )}
            </PanelHeader>
          </ErrorBoundary>
        </div>

        <div className={css(styles.panelBody)}>
          <ErrorBoundary component="ShowPanel">
            <TabContainer activeTab={activeTab} onChangeTab={(tab) => changeTab(tab)} />
            {activeTab === 'overview' && (
              <React.Fragment>
                {success && successAlert(success)}
                {errorsAlert.length > 0 && errorAlert(errorsAlert)}
                <OverviewBody>
                  {enabledFeatures.includes('other') ? (
                    <CollapsibleServicePanel />
                  ) : (
                    <InnerServicePanel />
                  )}
                  {fileData.serviceGroups?.some(
                    (group) =>
                      group.name === 'Alignment' &&
                      group.services?.[0]?.hasSourceText === false &&
                      (group.status === 'in_progress' || group.status === 'pending')
                  ) ? (
                    <AddAlignment fileId={fileId} />
                  ) : (
                    <Transcript
                      captionStyling={fileHasCaptionStyling()}
                      contentPublishing={contentPublishing}
                      enabledFeatures={enabledFeatures}
                      fileId={fileId}
                      open={transcriptOpen}
                      projectId={fileData.projectId}
                      reprocessingAssets={fileData.reprocessingAssets}
                      setOpen={setTranscriptOpen}
                      setShowModal={setShowModal}
                      transcriptId={transcriptId}
                      updateTranscript={updateTranscript}
                    />
                  )}
                </OverviewBody>
              </React.Fragment>
            )}
            {activeTab === 'details' && (
              <React.Fragment>
                {success && successAlert(success)}
                {errorsAlert.length > 0 && errorAlert(errorsAlert)}
                <DetailsBody
                  enabledFeatures={enabledFeatures}
                  fileId={fileId}
                  fileInfoOpen={fileInfoOpen}
                  generalInfoOpen={generalInfoOpen}
                  onUpdate={updateFile}
                  refreshToken={refreshToken}
                  replaceSourceEnabled={fileActions?.replaceSourceEnabled}
                  setErrorsAlert={setErrorsAlert}
                  setFileInfoOpen={setFileInfoOpen}
                  setGeneralInfoOpen={setGeneralInfoOpen}
                  setShowModal={setShowModal}
                  setSuccess={setSuccess}
                />
              </React.Fragment>
            )}
            {fileData.archived && (
              <DarkOverlay>
                <div className={css(styles.archivedText)}>
                  <p>This media file is archived.</p>
                  <p>You must unarchive this file to gain full access to it.</p>
                </div>
              </DarkOverlay>
            )}
          </ErrorBoundary>
        </div>

        <ModalErrorBoundary component="ManageTagsModal">
          <ManageTagsModal
            fileId={fileId}
            show={showManageTagsModal}
            onClose={() => setShowManageTagsModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="CheatSheetModal">
          <CheatSheetModal
            fileId={fileId}
            show={showCheatSheetModal}
            serviceType={cheatSheetServiceType}
            onClose={() => setShowCheatSheetModal(false)}
            authToken={authToken}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="ConfirmModal">
          <ConfirmModal
            show={showConfirmModal}
            onCancel={confirmationActions.onCancel}
            onConfirm={confirmationActions.onConfirm}
          >
            {confirmMessage}
          </ConfirmModal>
        </ModalErrorBoundary>
        <ModalErrorBoundary component="ImportTranslationModal">
          <ImportTranslationModal
            fileId={fileId}
            show={showImportTranslationModal}
            fileActions={fileActions}
            onClose={() => setShowImportTranslationModal(false)}
            authToken={authToken}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="AmaraOrderModal">
          <AmaraOrderModal
            fileId={fileId}
            show={showAmaraOrderModal}
            onClose={() => setShowAmaraOrderModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="CancelServiceModal">
          <CancelServiceModal
            fileId={fileId}
            show={showCancelServiceModal}
            services={cancelServiceServices}
            onClose={closeCancelServiceModal}
            onUpdate={updateFile}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="PublishVideoModal">
          <PublishVideoModal
            fileId={fileId}
            projectId={fileData.projectId}
            show={showPublishVideoModal}
            onClose={() => setShowPublishVideoModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="PreviewModal">
          <PreviewModal
            fileId={fileId}
            hasVideoSource={fileData.videoSource}
            transcriptId={transcriptId}
            languageCode={transcriptLanguageCode}
            show={showPreviewModal}
            onClose={() => setShowPreviewModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="MoveToFolderModal">
          <MoveToFolderModal
            fileData={fileData}
            show={showMoveModal}
            onUpdate={updateFile}
            onClose={() => setShowMoveModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="LinkIntegrationModal">
          <LinkIntegrationModal
            show={showLinkIntegrationModal}
            fileId={fileId}
            videoId={linkIntegrationVideoId}
            vpis={linkIntegrationVpis}
            onUpdate={updateFile}
            onClose={() => setShowLinkIntegrationModal(false)}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="EditEncodedCaptionsModal">
          <EditEncodedCaptionsModal
            encodedCaptionId={encodedCaptionId}
            show={showEditEncodedCaptionsModal}
            onClose={() => setShowEditEncodedCaptionsModal(false)}
            onUpdate={updateFile}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="QuickLinkModal">
          <QuickLinkModal
            show={showQuickLinkModal}
            onClose={() => setShowQuickLinkModal(false)}
            fileId={fileId}
            projectId={fileData.projectId}
            format={format}
            transcriptList={transcriptList}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="ReplaceSourceModal">
          <ReplaceSourceModal
            show={showReplaceSourceModal}
            onClose={() => setShowReplaceSourceModal(false)}
            fileId={fileId}
            fileName={fileData.name}
            supportedFormats={supportedFormats}
            onUpdate={updateFile}
            canReplaceSourceWithLinks={canReplaceSourceWithLinks}
            currentFileIsProof={fileData.proof}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="DownloadADModal">
          <DownloadADModal
            show={downloadADModal}
            onClose={() => setShowDownloadADModal(false)}
            audioDescriptionId={audioDescriptionId}
            fileId={fileId}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="DeleteCaptionImportModal">
          <DeleteCaptionImportModal
            show={showDeleteCaptionImportModal}
            onClose={() => setShowDeleteCaptionImportModal(false)}
            service={deleteCaptionImportService}
            onUpdate={updateFile}
            fileId={fileId}
          />
        </ModalErrorBoundary>
        <ModalErrorBoundary component="GetItNowModal">
          <GetItNowModal
            show={showGetItNowModal}
            service={transcriptionService}
            onClose={() => setShowGetItNowModal(false)}
            onUpdate={updateFile}
          />
        </ModalErrorBoundary>
        {fetching && <BlankLoadingOverlay />}
      </div>
    </FeatureFlagContext.Provider>
  );
}

ShowPanel.propTypes = {
  actionBarData: PropTypes.shape({
    favoriteFormats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    pluginTemplates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  authToken: PropTypes.string,
  canReplaceSourceWithLinks: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  isManagedAccount: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  showGetItNow: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  supportedFormats: PropTypes.string,
  featureFlags: PropTypes.shape({
    livePdfUploadsEnabled: PropTypes.bool,
  }),
};

const styles = StyleSheet.create({
  panel: {
    position: 'relative',
    float: 'right',
    right: '0px',
    height: '100%',
    borderLeft: '1px solid #EBEBEB',
    width: '45rem',
    backgroundColor: 'white',
    zIndex: '100',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-8px 1px 10px -6px rgba(61,61,61,.3)',
  },
  nullPanel: {
    backgroundColor: '#202435',
  },
  panelHeader: {
    height: '95px',
  },
  panelBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    position: 'absolute',
    height: 'calc(100% - 95px)',
    top: '95px',
    width: '100%',
  },
  archivedText: {
    textAlign: 'center',
    color: '#DEEFF5',
    fontSize: '20px',
    height: '120px',
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    margin: 'auto',
  },
  panelCloseIcon: {
    color: 'white',
    display: 'inline',
    fontSize: '20px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: '100000',
    background: 'none',
    border: 'none',
  },
  errorCloseButton: {
    color: '333',
  },
  alertDanger: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '#f5c6cb',
  },
});

export default ShowPanel;
