export function integrationDisplayName(name, platform) {
  return name === '' ? `My ${platform} Account` : name;
}

export function pluralize(word, count) {
  return count === 1 ? `${count} ${word}` : `${count} ${word}s`;
}

export function titleize(status) {
  return status.replace('_', ' ').replace(/\b([a-z])/g, (m) => m.toUpperCase());
}

export const convertSnakeCaseToCamelCase = (string) => {
  return string.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
};
