import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import AlertMessage from '~/components/app/common/AlertMessage';
import WithIcon from '~/components/app/common/WithIcon';

const eventInstructionsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SPEAKER':
      return {
        ...state,
        speakerNames: state.speakerNames.map((speaker, index) => {
          return index === action.speakerIndex ? action.newSpeaker : speaker;
        }),
      };
    case 'REMOVE_SPEAKER':
      return {
        ...state,
        speakerNames: state.speakerNames.filter((_, index) => index !== action.speakerIndex),
      };
    case 'ADD_SPEAKER':
      return {
        ...state,
        speakerNames: state.speakerNames.concat(''),
      };
    case 'UPDATE_EVENT_NAME':
      return {
        ...state,
        name: action.name,
      };
    case 'UPDATE_EVENT_DESCRIPTION':
      return {
        ...state,
        description: action.description,
      };
    default:
      throw new Error(`Unknown Action: ${action.type}`);
  }
};

function EventInstructionsModal({
  onClose,
  show,
  onSave,
  intialInstructions,
  eventName,
  alertNoteMessage = null,
  readOnly = false,
}) {
  const initialEventInstructions = { ...intialInstructions, name: eventName };
  const [eventInstructions, eventInstructionsDispatcher] = useReducer(
    eventInstructionsReducer,
    initialEventInstructions
  );

  return (
    <Modal dialogClassName="modal-width-large" onHide={onClose} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Add Event Instructions: {eventInstructions.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertNoteMessage && (
          <AlertMessage message={alertNoteMessage} variant={readOnly ? 'warning' : 'primary'} />
        )}
        <Form>
          <Form.Group controlId="eventName">
            <Form.Label>
              <strong>Event Name</strong>
            </Form.Label>
            <Form.Control
              aria-label="Event Name"
              onChange={(e) =>
                eventInstructionsDispatcher({ type: 'UPDATE_EVENT_NAME', name: e.target.value })
              }
              placeholder="Event Name (internal use only)"
              type="text"
              value={eventInstructions.name}
              disabled={readOnly}
            />
          </Form.Group>
          <Form.Group controlId="eventDescription">
            <Form.Label className="mb-0">
              <strong>Event Description</strong>
            </Form.Label>
            <p className="mb-2">
              Additional context around the event, such as time, location, place, purpose of the
              event.
            </p>
            <Form.Control
              aria-label="Event Description"
              as="textarea"
              onChange={(e) =>
                eventInstructionsDispatcher({
                  type: 'UPDATE_EVENT_DESCRIPTION',
                  description: e.target.value,
                })
              }
              placeholder="Enter event description here"
              rows="8"
              value={eventInstructions.description}
              disabled={readOnly}
            />
          </Form.Group>
          <Form.Group controlId="speakerName">
            <Form.Label className="mb-0">
              <strong>Speaker Names</strong>
              <p className="mb-2">
                Add a list of names of people who may be speaking during the event to more
                accurately caption speaker labels.
              </p>
              {eventInstructions.speakerNames.map((speakerName, index) => {
                return (
                  <Row key={index} className="mb-2">
                    <Col sm={9}>
                      <Form.Control
                        aria-label="Speaker Name"
                        onChange={(e) =>
                          eventInstructionsDispatcher({
                            type: 'UPDATE_SPEAKER',
                            speakerIndex: index,
                            newSpeaker: e.target.value,
                          })
                        }
                        placeholder="Speaker Name"
                        type="text"
                        value={speakerName}
                        disabled={readOnly}
                      />
                    </Col>
                    <Col sm={3}>
                      <Button
                        aria-label="Delete Speaker"
                        onClick={() =>
                          eventInstructionsDispatcher({
                            type: 'REMOVE_SPEAKER',
                            speakerIndex: index,
                          })
                        }
                        size="sm"
                        variant="link outline-danger text-danger"
                        disabled={readOnly}
                      >
                        <WithIcon icon="fa fa-times-circle fa-lg">Delete Speaker</WithIcon>
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <Button
                aria-label="New Speaker"
                onClick={() => eventInstructionsDispatcher({ type: 'ADD_SPEAKER' })}
                size="sm"
                variant="link outline-primary"
                disabled={readOnly}
              >
                <WithIcon icon="fa fa-plus">New Speaker</WithIcon>
              </Button>
            </Form.Label>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button aria-label="Cancel" onClick={() => onClose()} variant="outline-dark">
          Cancel
        </Button>
        <Button
          aria-label="Save Changes"
          onClick={() => onSave(eventInstructions)}
          variant="primary"
          disabled={readOnly}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EventInstructionsModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  initialEventInstructions: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    speakerNames: PropTypes.arrayOf(PropTypes.string),
  }),
  alertNoteMessage: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default EventInstructionsModal;
