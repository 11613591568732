import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ends', 'excludedDates', 'monthOptions', 'repeat', 'startTime', 'withSchedule'];
  static values = {
    ends: String,
    excludedDates: Array,
    frequency: String,
    fullyDisabled: Boolean,
    startTime: String,
    withSchedule: Boolean,
  };

  addExcludedDate(event) {
    this.excludedDatesValue = Array.from(
      new Set([...(this.excludedDatesValue || []), event.target.value])
    )
      .sort()
      .filter((n) => n);
  }

  changeExcludedDates(event) {
    this.excludedDatesValue = event.target.value
      .split(',')
      .map((date) => date.trim())
      .sort();
  }

  changeFrequency(event) {
    this.frequencyValue = event.target.value;
  }

  changeEnds(event) {
    this.endsValue = event.target.value;
  }

  changeStartTime(event) {
    this.startTimeValue = event.target.value;
  }

  enableCorrespondingEndsField() {
    this.endsTargets.forEach((element) => {
      if (element.dataset.enableFor === this.endsValue) {
        element.disabled = this.fullyDisabledValue;
      } else {
        element.disabled = true;
      }
    });
  }

  endsValueChanged() {
    this.enableCorrespondingEndsField();
  }

  excludedDatesValueChanged() {
    this.updateExcludedDatesInput();
  }

  frequencyValueChanged() {
    this.showCorrespondingRepeatSection();
  }

  showCorrespondingRepeatSection() {
    this.repeatTargets.forEach((element) => {
      if (element.dataset.displayFor === this.frequencyValue) {
        element.hidden = false;
        element.querySelectorAll(':scope input, :scope select').forEach((input) => {
          input.disabled = this.fullyDisabledValue;
        });
      } else {
        element.hidden = true;
        element.querySelectorAll(':scope input, :scope select').forEach((input) => {
          input.disabled = true;
        });
      }
    });
  }

  startTimeValueChanged() {
    if (this.startTimeValue === '') return;
    this.updateMonthOptions();
  }

  toggleWithSchedule() {
    this.withScheduleValue = !this.withScheduleValue;
  }

  updateExcludedDatesInput() {
    this.excludedDatesTargets.forEach((element) => {
      element.value = this.excludedDatesValue.join(', ');
    });
  }

  updateMonthOptions() {
    const weekNumberOrdinals = ['1st', '2nd', '3rd', '4th', 'last'];
    const daysOfWeekAbbrs = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date(this.startTimeValue);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfWeekAbbr = daysOfWeekAbbrs[date.getDay()];
    const dayOfMonth = date.getDate();
    const weekNumber = Math.ceil(dayOfMonth / 7);
    const weekNumberValue = weekNumber === 5 ? -1 : weekNumber;
    const weekNumberText = weekNumberOrdinals[weekNumber - 1];
    const newOptions = [
      { value: JSON.stringify([dayOfMonth]), textContent: `Monthly on day ${dayOfMonth}` },
      {
        value: JSON.stringify([`${weekNumberValue}${dayOfWeekAbbr}`]),
        textContent: `Monthly on the ${weekNumberText} ${dayOfWeek}`,
      },
    ];

    this.monthOptionsTargets.forEach((element) => {
      element.querySelectorAll('option:nth-child(-n+2)').forEach((option, index) => {
        option.value = newOptions[index].value;
        option.textContent = newOptions[index].textContent;
      });
    });
  }

  updateScheduleDisplay() {
    this.withScheduleTargets.forEach((element) => {
      if (this.withScheduleValue.toString() === element.dataset.displayFor) {
        element.hidden = false;
        element.querySelectorAll(':scope input, :scope select').forEach((input) => {
          input.disabled = this.fullyDisabledValue;
        });
      } else {
        element.hidden = true;
        element.querySelectorAll(':scope input, :scope select').forEach((input) => {
          input.disabled = true;
        });
      }
    });

    if (this.withScheduleValue) {
      this.enableCorrespondingEndsField();
      this.showCorrespondingRepeatSection();
    }
  }

  withScheduleValueChanged() {
    this.updateScheduleDisplay();
  }
}
