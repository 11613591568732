import React from 'react';
import { Card } from 'react-bootstrap';

export function LacBestPractices() {
  return (
    <Card className="mt-4">
      <Card.Body>
        <Card.Title>Best Practices for Automatic Live Captioned Events</Card.Title>
        <hr />
        <p>
          3Play recommends you follow the tips below to ensure more accurate captions for your
          automatic live captioned events:
        </p>
        <ul>
          <li>
            Captions for live events work best when the event contains high-quality audio and one
            person is speaking at a time (no overlapping audio)
          </li>
          <li>
            Add a wordlist of commonly used terms and phrases to more accurately caption your events
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}
