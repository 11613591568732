import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { Card } from '@threeplayground/unstable';

import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { OffPlatformJobDetails } from '../../common/OffPlatformJobDetails';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';

type ParamType = {
  id: string;
};

export function OffPlatformVideoEmbeddingJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });
  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformVideoEmbeddingJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const { orderInstructions, deliveryInstructions, mediaFile, inputAudioAsset, videoAsset } = job;

  const getAssets = (): DownloadableAsset[] => {
    const assets = [
      {
        displayName: 'Source Video',
        url: mediaFile.source.presignedDownloadUrl,
      },
    ];

    const files = inputAudioAsset?.files;
    if (files && files[0]) {
      assets.push({
        displayName: 'Audio',
        url: files[0].presignedDownloadUrl,
      });
    }
    return assets;
  };

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Video Embedding Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails job={job} downloadableAssets={getAssets()} />
          <Card>
            <Card.Header className="font-weight-bold">Embedded Video</Card.Header>
            <Card.Body>
              <UploadedFileList files={videoAsset.files} />
              <FileUploader
                assetId={videoAsset.id}
                assetType={AssetClassNameEnum.VideoAsset}
                allowedFileFormats={videoAsset.allowedFileFormats}
                uploadedFiles={videoAsset.files}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
