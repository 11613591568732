import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

import { Typeahead } from 'react-bootstrap-typeahead';

import { threeplayApi } from '~/logic/ThreeplayApi';
import {
  eventListQuery,
  zoomUsersQuery,
  zoomUserExistsQuery,
} from '~/components/app/video_platform_integrations/data/queries';
import { reauthenticateZoomIntegrationPath } from '~/helpers/app/paths';

import IntegrationSelector from '../IntegrationSelector';
import EventListing from '~/components/app/live_auto_captioning/new_event/EventListing';
import SearchBoxWithButton from '~/components/app/common/SearchBoxWithButton';
import WithIcon from '~/components/app/common/WithIcon';
import { liveStaticEmbedKeyShape } from '../../common/shapes';

function ZoomUsersDropDown(props) {
  function updateUserToSearch(userEmail) {
    props.setFilterOptions({ ...props.filterOptions, zoomUser: userEmail });
    props.setUserEmail(userEmail);
  }

  return (
    <Typeahead
      id="zooom-users"
      className="px-0 w-25 form-control-sm"
      onChange={(obj) => updateUserToSearch(obj[0])}
      options={props.zoomUserDetails !== {} && props.zoomUserDetails.userList}
      placeholder="Zoom user"
      defaultInputValue={props.zoomUserDetails !== {} && props.zoomUserDetails.defaultUser}
      size="small"
      value={props.filterOptions.zoomUser}
    />
  );
}

function ZoomUserSearch(props) {
  function updateUserToSearch(userEmail) {
    props.setZoomUserSearch((prevState) => ({ ...prevState, userEmail: userEmail }));
    props.setUserEmail(userEmail);
  }

  return (
    <div className="col-sm-3 p-0">
      <SearchBoxWithButton
        clearSearchTerm={() => updateUserToSearch('')}
        placeholder="Enter Zoom User Email..."
        search={() => props.searchUser()}
        searchOnEnter
        searchTerm={props.zoomUserSearch.userEmail}
        size="sm"
        updateSearchTerm={(userEmail) => updateUserToSearch(userEmail)}
      />
      {props.zoomUserSearch.errorMessage && (
        <Alert className="mt-2" variant="danger">
          {props.zoomUserSearch.errorMessage}
        </Alert>
      )}
    </div>
  );
}

function AuthenticationErrorMessage(props) {
  return (
    <>
      Sorry, we&apos;re having trouble connecting to your Zoom account which can restrict our
      ability to show you your meetings and webinars.
      <br />
      <Alert.Link href={props.reauthPath}>Reauthenticate</Alert.Link> your account or try again
      later if the problem persists.
    </>
  );
}

function GenericErrorMessage(props) {
  return (
    <>
      Sorry, we&apos;re having some trouble retrieving a list of {props.error} for your Zoom
      account, <b>{props.account}</b>. Try refreshing the page or try again later if the problem
      persists.
    </>
  );
}
function Zoom(props) {
  const [error, setError] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const prevFilterOptions = useRef(props.filterOptions);
  const prevPageParams = useRef(props.paginationParams);

  useEffect(() => {
    if (!props.filterOptions.hasOwnProperty('integration')) {
      return;
    }

    if (props.filterOptions.integration !== props.integrationId) {
      props.setIntegrationId(props.filterOptions.integration);
      setError(null);
      setFetchingData(true);
      getZoomUserInfo();
    }
  }, [props.filterOptions.integration]);

  useEffect(() => {
    if (!filterOptionsSet()) {
      return;
    }

    // Javascript objects need to be explicitly checked for values, when testing for equality
    const filterValuesEqual =
      JSON.stringify(prevFilterOptions.current) === JSON.stringify(props.filterOptions);
    if (filterValuesEqual) {
      return;
    }

    // For changes other than page no. change, reset to first page and clear selections
    props.setPaginationParams({ ...props.paginationParams, currentPage: 1 });
    props.setSelectedEvents([]);
    getEventlist();
    prevFilterOptions.current = props.filterOptions;
  }, [props.filterOptions]);

  useEffect(() => {
    if (!filterOptionsSet()) {
      return;
    }

    const paginationParamsValueEqual =
      JSON.stringify(prevPageParams.current) === JSON.stringify(props.paginationParams);
    if (paginationParamsValueEqual) {
      return;
    }

    getEventlist();
    prevPageParams.current = props.paginationParams;
  }, [props.paginationParams]);

  function filterOptionsSet() {
    return (
      props.filterOptions.eventType !== undefined &&
      props.filterOptions.integration !== undefined &&
      props.filterOptions.zoomUser !== undefined &&
      props.filterOptions.zoomUser !== ''
    );
  }

  function getZoomUserInfo() {
    threeplayApi
      .request(zoomUsersQuery, { videoPlatformIntegrationIds: props.filterOptions.integration })
      .then((response) => {
        if (
          response.errors ||
          !response?.data?.project?.linkedAccounts[0]?.integrations[0]?.zoomUserDetails
        ) {
          setError('users');
          props.setZoomUserDetails({});
          setFetchingData(false);
        } else {
          const userDetails =
            response.data.project.linkedAccounts[0].integrations[0].zoomUserDetails;
          setFetchingData(false);
          props.setFilterOptions((prevState) => ({
            ...prevState,
            zoomUser: userDetails.defaultUser,
          }));
          props.setZoomUserDetails(userDetails);
          props.setZoomUserSearch((prevState) => ({
            ...prevState,
            userEmail: userDetails.defaultUser,
          }));
          props.setUserEmail(userDetails.defaultUser);
        }
      });
  }

  function getEventlist() {
    setFetchingData(true);
    threeplayApi
      .request(eventListQuery, {
        eventType: props.filterOptions.eventType,
        paginationParams: props.paginationParams,
        userEmail: props.filterOptions.zoomUser,
        videoPlatformIntegrationIds: props.filterOptions.integration,
      })
      .then((response) => {
        if (response.errors) {
          setError('meetings and webinars');
          setFetchingData(false);
        } else {
          const data = response.data.project.linkedAccounts[0].integrations[0];
          setError(null);
          props.setEventList(data.liveEvents);
          setFetchingData(false);
          props.setPaginationDetails(data.paginationDetails);
        }
      });
  }

  function searchUser() {
    const emailRegEx = /[\w\.-]+@[\w\.-]+\.\w{2,4}/;
    if (!emailRegEx.test(props.zoomUserSearch.userEmail)) {
      props.setFilterOptions((prevState) => ({ ...prevState, zoomUser: '' }));
      props.setZoomUserSearch((prevState) => ({
        ...prevState,
        errorMessage: 'Please enter a valid email id',
      }));
      return;
    } else {
      props.setZoomUserSearch((prevState) => ({ ...prevState, errorMessage: null }));
    }

    threeplayApi
      .request(zoomUserExistsQuery, {
        userEmail: props.zoomUserSearch.userEmail,
        videoPlatformIntegrationIds: props.filterOptions.integration,
      })
      .then((response) => {
        if (response.errors) {
          props.setFilterOptions((prevState) => ({ ...prevState, zoomUser: '' }));
          props.setZoomUserSearch((prevState) => ({
            ...prevState,
            errorMessage: 'Email must be a registered Zoom user',
          }));
        } else {
          props.setFilterOptions((prevState) => ({
            ...prevState,
            zoomUser: props.zoomUserSearch.userEmail,
          }));
          props.setZoomUserSearch((prevState) => ({ ...prevState, errorMessage: null }));
        }
      });
  }

  function selectedAccount() {
    return props.integrations.find(
      (integration) => integration.id === props.filterOptions.integration
    )?.name;
  }

  if (error !== null) {
    return (
      <>
        <p className="my-1">
          <strong>Which Zoom account do you want to schedule your captions for?</strong>
        </p>
        <IntegrationSelector
          filterOptions={props.filterOptions}
          integrations={props.integrations}
          platform="Zoom"
          selectedIntegration={props.integrationId}
          setFilterOptions={props.setFilterOptions}
        />
        <Alert className="w-25 mt-2" variant="danger">
          <Alert.Heading>
            <WithIcon icon="fa fa-exclamation-triangle">Connection Failure</WithIcon>
          </Alert.Heading>
          {error === 'users' ? (
            <AuthenticationErrorMessage
              reauthPath={reauthenticateZoomIntegrationPath(props.filterOptions.integration)}
            />
          ) : (
            <GenericErrorMessage account={selectedAccount()} error={error} />
          )}
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <p className="my-1">
          <strong>Which Zoom account do you want to schedule your captions for?</strong>
        </p>
        <IntegrationSelector
          filterOptions={props.filterOptions}
          integrations={props.integrations}
          platform="Zoom"
          selectedIntegration={props.integrationId}
          setFilterOptions={props.setFilterOptions}
        />
        <p className="my-1 pt-2">
          <strong>Show Zoom events scheduled by:</strong>
        </p>
        {props.zoomUserDetails !== {} && props.zoomUserDetails.type === 'list' && (
          <ZoomUsersDropDown
            fetchingData={fetchingData}
            filterOptions={props.filterOptions}
            setFilterOptions={props.setFilterOptions}
            setUserEmail={props.setUserEmail}
            zoomUserDetails={props.zoomUserDetails}
          />
        )}
        {props.zoomUserDetails !== {} && props.zoomUserDetails.type === 'search' && (
          <ZoomUserSearch
            searchUser={searchUser}
            setUserEmail={props.setUserEmail}
            setZoomUserSearch={props.setZoomUserSearch}
            zoomUserSearch={props.zoomUserSearch}
          />
        )}

        <p className="my-1 pt-2">
          <strong>What type of event are you trying to caption?</strong>
        </p>
        <Form.Check
          id="meeting"
          checked={props.filterOptions?.eventType === 'meeting'}
          label="Meeting"
          name="meetingType"
          onChange={(e) =>
            props.setFilterOptions({ ...props.filterOptions, eventType: e.target.id })
          }
          type="radio"
          inline
        />
        <Form.Check
          id="webinar"
          checked={props.filterOptions?.eventType === 'webinar'}
          className="ml-3"
          label="Webinar"
          name="meetingType"
          onChange={(e) =>
            props.setFilterOptions({ ...props.filterOptions, eventType: e.target.id })
          }
          type="radio"
          inline
        />
        {filterOptionsSet() && (
          <EventListing
            eventList={props.eventList}
            eventType={props.filterOptions.eventType}
            fetchingData={fetchingData}
            findEvent={props.findEvent}
            liveStaticEmbedKeys={props.liveStaticEmbedKeys}
            openEventSettingsModal={(event) => props.openEventSettingsModal(event)}
            openWordListModalForEvent={(event) => props.openWordListModalForEvent(event)}
            paginationDetails={props.paginationDetails}
            paginationParams={props.paginationParams}
            platform="Zoom"
            projectLiveSettings={props.projectLiveSettings}
            selectedEvents={props.selectedEvents}
            setPaginationParams={(currentPage) =>
              props.setPaginationParams({ ...props.paginationParams, currentPage: currentPage })
            }
            setEventList={props.setEventList}
            setSelectedEvents={props.setSelectedEvents}
            updateSelectedEvents={props.updateSelectedEvents}
            userTimeZone={props.userTimeZone}
            validateStreamUrl={props.validateStreamUrl}
          />
        )}
      </>
    );
  }
}

AuthenticationErrorMessage.propTypes = {
  reauthPath: PropTypes.string,
};

GenericErrorMessage.propTypes = {
  account: PropTypes.string,
  error: PropTypes.string,
};

Zoom.propTypes = {
  findEvents: PropTypes.func,
  integrations: PropTypes.arrayOf(PropTypes.object),
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(PropTypes.object),
  setIntegrationId: PropTypes.func,
  setSelectedEvents: PropTypes.func,
  setUserEmail: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateStreamUrl: PropTypes.func,
};

ZoomUsersDropDown.propTypes = {
  filterOptions: PropTypes.object,
  setFilterOptions: PropTypes.func,
  setUserEmail: PropTypes.func,
  zoomUserDetails: PropTypes.object,
};

ZoomUserSearch.propTypes = {
  searchUser: PropTypes.func,
  setUserEmail: PropTypes.func,
  setZoomUserSearch: PropTypes.func,
  zoomUserSearch: PropTypes.object,
};

export default Zoom;
