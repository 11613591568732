import { z } from 'zod';

export const outputInfoInput = z
  .object({
    outputStreamTargetUrl: z
      .string()
      .min(1)
      .max(255)
      .url()
      .regex(/^rtmp:\/\/\S+$/)
      .describe('Output stream target url'),
    outputStreamTargetKey: z
      .string()
      .regex(/^\S+$/)
      .min(1)
      .max(255)
      .describe('Output stream target key'),
    useStaticEmbedKey: z
      .boolean()
      .describe('Whether the event should use a static embed url')
      .optional(),
  })
  .describe('Event output information');
