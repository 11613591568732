export const liveEventsPaths = {
  createVirtualEncoding: {
    getLink: () => '/live_events/new/virtual_encoding',
    route: '/live_events/new/virtual_encoding',
  },
  orderSuccess: {
    getLink: () => '/live_events/success',
    route: '/live_events/success',
  },
};

export const codeNameToRouteAdapter = {
  virtual_encoding: liveEventsPaths.createVirtualEncoding,
};
