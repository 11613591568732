import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';

import IntegrationSelector from '../IntegrationSelector';
import EventListing from '~/components/app/live_auto_captioning/new_event/EventListing';

import { eventListQuery } from '~/components/app/video_platform_integrations/data/queries';
import { threeplayApi } from '~/logic/ThreeplayApi';

import WithIcon from '~/components/app/common/WithIcon';
import { flipperFeaturesShape, liveStaticEmbedKeyShape } from '../../common/shapes';

function CommonPlatformComponent(props) {
  const [error, setError] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const fetchDataRef = useRef(false);
  const prevFilterOptions = useRef(props.filterOptions);
  const prevPageParams = useRef(props.paginationParams);

  useEffect(() => {
    if (!props.filterOptions.hasOwnProperty('integration')) {
      return;
    }
    if (incorrectFilterParam()) {
      return;
    }

    const filterValuesEqual =
      JSON.stringify(prevFilterOptions.current) === JSON.stringify(props.filterOptions);
    if (filterValuesEqual) {
      return;
    }

    props.setSelectedEvents([]);
    props.setIntegrationId(props.filterOptions.integration);
    props.setPaginationParams({
      pageAfter: null,
      pageBefore: null,
      pageToken: null,
      currentPage: 1,
      entriesPerPage: 5,
    });
    prevFilterOptions.current = props.filterOptions;
    getEventList();
  }, [props.filterOptions]);

  useEffect(() => {
    if (!filterOptionsSet()) {
      return;
    }
    if (incorrectFilterParam()) {
      return;
    }

    const paginationParamsValueEqual =
      JSON.stringify(prevPageParams.current) === JSON.stringify(props.paginationParams);
    if (paginationParamsValueEqual) {
      return;
    }

    if (!fetchDataRef.current) {
      getEventList();
      prevPageParams.current = props.paginationParams;
    }
  }, [props.paginationParams]);

  function filterOptionsSet() {
    return !!props.filterOptions.integration;
  }

  function incorrectFilterParam() {
    return props.filterOptions?.hasOwnProperty('zoomUser');
  }

  function getEventList() {
    fetchDataRef.current = true;
    setFetchingData(true);
    threeplayApi
      .request(eventListQuery, {
        paginationParams: props.paginationParams,
        videoPlatformIntegrationIds: props.filterOptions.integration,
      })
      .then((response) => {
        if (response.errors) {
          setError(true);
          fetchDataRef.current = false;
          setFetchingData(false);
        } else {
          const data = response.data.project.linkedAccounts[0].integrations[0];
          props.setEventList(data.liveEvents);
          props.setPaginationDetails(data.paginationDetails);
          fetchDataRef.current = false;
          setFetchingData(false);
        }
      });
  }

  function selectedAccount() {
    return props.integrations.find(
      (integration) => integration.id === props.filterOptions.integration
    ).name;
  }

  if (error !== null) {
    return (
      <Alert className="w-25" variant="danger">
        <Alert.Heading>
          <WithIcon icon="fa fa-exclamation-triangle">Connection Failure</WithIcon>
        </Alert.Heading>
        Sorry, we’re having some trouble retrieving a list of for your {props.platform} account,{' '}
        <b>{selectedAccount()}</b>. Try refreshing the page or try again later if the problem
        persists.
      </Alert>
    );
  } else {
    return (
      <>
        <p className="my-1">
          <strong>Which {props.platform} do you want to schedule your captions for?</strong>
        </p>
        <IntegrationSelector
          filterOptions={props.filterOptions}
          integrations={props.integrations}
          platform={props.platform}
          selectedIntegration={props.integrationId}
          setFilterOptions={props.setFilterOptions}
        />
        <EventListing
          defaultTranscoders={props.defaultTranscoders}
          eventList={props.eventList}
          eventType="event"
          fetchingData={fetchingData}
          findEvent={props.findEvent}
          disableAlreadyOrderedEvents={props.platform !== 'Caption Conductor'}
          liveStaticEmbedKeys={props.liveStaticEmbedKeys}
          nameColumnKey={props.platform === 'Caption Conductor' ? 'externalId' : 'name'}
          nameColumnText={props.platform === 'Caption Conductor' ? 'Evertz Job Name' : 'Name'}
          openEventSettingsModal={(event) => props.openEventSettingsModal(event)}
          openWordListModalForEvent={(event) => props.openWordListModalForEvent(event)}
          paginationDetails={props.paginationDetails}
          paginationParams={props.paginationParams}
          platform={props.platform}
          projectLiveSettings={props.projectLiveSettings}
          selectedEvents={props.selectedEvents}
          setEventList={props.setEventList}
          setPageAfter={(token) =>
            props.setPaginationParams({ ...props.paginationParams, pageAfter: token })
          }
          setPageBefore={(token) =>
            props.setPaginationParams({ ...props.paginationParams, pageBefore: token })
          }
          setPaginationParams={(currentPage) =>
            props.setPaginationParams({ ...props.paginationParams, currentPage: currentPage })
          }
          setPaginationToken={(token) =>
            props.setPaginationParams({ ...props.paginationParams, pageToken: token })
          }
          setSelectedEvents={props.setSelectedEvents}
          updateSelectedEvents={props.updateSelectedEvents}
          userTimeZone={props.userTimeZone}
          validateStreamUrl={props.validateStreamUrl}
          liveCaptionConductorSpanishEnabled={
            props.flipperFeatures?.liveCaptionConductorSpanishEnabled
          }
        />
      </>
    );
  }
}

CommonPlatformComponent.propTypes = {
  integrations: PropTypes.arrayOf(PropTypes.object),
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  platform: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(PropTypes.object),
  setSelectedEvents: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateStreamUrl: PropTypes.func,
  flipperFeatures: flipperFeaturesShape,
};

export default CommonPlatformComponent;
