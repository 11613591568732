import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import DatetimeModule from '3p-react-datetime';

// Datetime is not a proper esmodule
const Datetime = DatetimeModule.default ? DatetimeModule.default : DatetimeModule;

function DateTimeComponent(props) {
  const [cursor, setCursor] = useState(null);
  const timePickerRef = useRef(null);

  useEffect(() => {
    timePickerRef.current.selectionStart = cursor;
    timePickerRef.current.selectionEnd = cursor;
  }, [props.value]);

  function timePickerComponent(props, openCalendar, closeCalendar, isOpen, validDate) {
    return (
      <Form.Group className="mb-0">
        <InputGroup>
          <FormControl {...props} isInvalid={!validDate()} size="sm" />
          <InputGroup.Append className={css(styles.timePicker)}>
            <InputGroup.Text>
              <i
                className="fa fa-calendar"
                onClick={() => (isOpen ? closeCalendar() : openCalendar())}
              ></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {!validDate() && (
          <Form.Control.Feedback className="d-block" type="invalid">
            Start time must be at least 5 minutes in the future
          </Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }

  return (
    <Datetime
      closeOnSelect
      isValidDate={(current) => props.isValidDate(current)}
      onClose={() => props.setCalenderOpen(false)}
      onChange={(time) => props.handleTimeChange(time)}
      onOpen={() => props.setCalenderOpen(true)}
      renderInput={(property, openCalendar, closeCalendar) =>
        timePickerComponent(
          property,
          openCalendar,
          closeCalendar,
          props.calendarOpen,
          props.validDate
        )
      }
      value={props.value}
      inputProps={{
        onChange: (e) => setCursor(e.target.selectionStart),
        ref: timePickerRef,
      }}
    />
  );
}

const styles = StyleSheet.create({
  timePicker: {
    ':hover': {
      cursor: 'pointer',
    },
  },
});

DateTimeComponent.propTypes = {
  calendarOpen: PropTypes.bool,
  isValidDate: PropTypes.func,
  handleTimeChange: PropTypes.func,
  setCalenderOpen: PropTypes.func,
  validDate: PropTypes.func,
  value: PropTypes.string,
};

export default DateTimeComponent;
