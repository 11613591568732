import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import AutomaticLiveCaptions from './AutomaticLiveCaptions';
import ProfessionalLiveCaptions from './ProfessionalLiveCaptions';

import { eventShape } from '../common/shapes';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

function OrderStepTwo(props) {
  const { eventsDispatch } = props;
  const autoLiveEvents = props.selectedEvents.filter(
    (event) => event.professionalCaptioning === false
  );
  const professionalLiveEvents = props.selectedEvents.filter(
    (event) => event.professionalCaptioning === true
  );

  function orderButtonText() {
    if (props.orders.ordering) {
      return (
        <span>
          Placing Order <i className="fa fa-spinner fa-spin"></i>
        </span>
      );
    } else {
      return 'Schedule Live Captions';
    }
  }

  function getEventDetailsTitleText(event) {
    return props.platform === 'Caption Conductor'
      ? `Event on Evertz Job ${event.externalId}`
      : event.name;
  }

  return (
    <ErrorBoundary component="ScheduleNewLiveAutoCaptioningStepTwo">
      <Container className="py-2" fluid>
        <Row>
          <Col sm={12}>
            <strong>
              We need a little more information about some of your events to caption them
              accurately.
            </strong>
            {autoLiveEvents.length > 0 && (
              <AutomaticLiveCaptions
                eventNameIsEditable={props.platform === 'Caption Conductor'}
                events={autoLiveEvents}
                getEventDetailsTitleText={getEventDetailsTitleText}
                hasExternalEnconder={props.platform === 'Caption Conductor'}
                openEventSettingsModal={props.openEventSettingsModal}
                openWordListModalForEvent={props.openWordListModalForEvent}
                updateEventName={props.updateEventName}
              />
            )}
            {professionalLiveEvents.length > 0 && (
              <ProfessionalLiveCaptions
                eventsDispatch={eventsDispatch}
                eventNameIsEditable={props.platform === 'Caption Conductor'}
                events={professionalLiveEvents}
                getEventDetailsTitleText={getEventDetailsTitleText}
                hasExternalEnconder={props.platform === 'Caption Conductor'}
                openEventInstructionsModal={props.openEventInstructionsModal}
                openEventSettingsModal={props.openEventSettingsModal}
                openWordListModalForEvent={props.openWordListModalForEvent}
                updateEventName={props.updateEventName}
                updateProfessionalEventSettings={props.updateProfessionalEventSettings}
                userTimeZone={props.userTimeZone}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Button
            className={`d-flex mt-2 ml-3 mr-2`}
            onClick={() => props.setOrderStep(1)}
            variant="outline-primary"
          >
            Previous Step
          </Button>
          <TooltipIfErrors errors={props.stepTwoUISubmissionErrors} location="top">
            <Button
              className={`d-flex mt-2 ${css(styles.submitButton)}`}
              onClick={() => props.scheduleLiveEvents()}
              disabled={props.stepTwoUISubmissionErrors.length > 0}
              variant="primary"
            >
              {orderButtonText()}
            </Button>
          </TooltipIfErrors>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

OrderStepTwo.propTypes = {
  openEventInstructionsModal: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  orders: PropTypes.object,
  platform: PropTypes.string,
  scheduleLiveEvents: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(eventShape),
  setOrderStep: PropTypes.func,
  stepTwoUISubmissionErrors: PropTypes.arrayOf(PropTypes.string),
  updateEventName: PropTypes.func,
  updateProfessionalEventSettings: PropTypes.func,
  userTimeZone: PropTypes.string,
  eventsDispatch: PropTypes.func,
};

const styles = StyleSheet.create({
  submitButton: {
    ':disabled': {
      pointerEvents: 'none',
    },
  },
});

export default OrderStepTwo;
