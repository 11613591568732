import moment from 'moment-timezone';

export const validDate = (
  eventStartTime,
  userTimeZone,
  minutesAfterCurrentTimeForValidEvent = 5
) => {
  if (eventStartTime === null) {
    return true;
  }

  // True param here adds the timezone to the passed in date time, for the moment object
  // The backend is already accounting for timezone, when formatting dates
  const momentStartTime = moment(eventStartTime).tz(userTimeZone, true);
  const momentNow = moment().tz(userTimeZone);

  return (
    momentStartTime.isValid() &&
    momentStartTime.isSameOrAfter(momentNow.add(minutesAfterCurrentTimeForValidEvent, 'minutes'))
  );
};
