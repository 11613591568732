import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Card } from '@threeplayground/unstable';

import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails } from '../../common/OffPlatformJobDetails';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { AssetInstructions } from '../../common/AssetInstructions';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';

type ParamType = {
  id: string;
};

export interface AudioMix {
  fullMix: boolean;
  orderOption: {
    displayName: string;
  };
}

export function OffPlatformAudioMixingJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformAudioMixingJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const {
    orderInstructions,
    deliveryInstructions,
    service,
    mediaFile,
    inputAudioAsset,
    mixedAudioAsset,
  } = job;

  const { sourceSupplementalAudioAsset } = mediaFile;

  if (service.__typename !== 'AudioMixingServiceGraphql') return <JobLoadError />;
  const { audioMix } = service;

  const getAssets = (): DownloadableAsset[] => {
    const assets = [
      {
        displayName: 'Source Video',
        url: mediaFile.source.presignedDownloadUrl,
      },
    ];

    const files = inputAudioAsset?.files;
    if (!!files && !!files[0]) {
      assets.push({
        displayName: 'Input Audio Asset',
        url: files[0].presignedDownloadUrl,
      });
    }

    const supplementalFiles = sourceSupplementalAudioAsset?.files;
    if (!!supplementalFiles && !!supplementalFiles[0]) {
      assets.push({
        displayName: 'Supplemental Audio',
        url: supplementalFiles[0].presignedDownloadUrl,
      });
    }
    return assets;
  };

  const mixingJobDetails = (audioMix: AudioMix) => {
    const fullMix = audioMix.fullMix;
    const customDetails = [];

    customDetails.push({
      header: 'Full Audio Mix?',
      value: fullMix.toString(),
    });

    const audioMixOrderOption = audioMix.orderOption.displayName;

    customDetails.push({
      header: 'Audio Mix Order Option',
      value: audioMixOrderOption,
    });
    return customDetails;
  };

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Audio Mixing Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={mixingJobDetails(audioMix)}
          />
          <Card>
            <Card.Header className="font-weight-bold">Mixed Audio Asset</Card.Header>
            <Card.Body>
              <UploadedFileList files={mixedAudioAsset.files} />
              <FileUploader
                assetId={mixedAudioAsset.id}
                assetType={AssetClassNameEnum.MixedAudioAsset}
                allowedFileFormats={mixedAudioAsset.allowedFileFormats}
                uploadedFiles={mixedAudioAsset.files}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
          <AssetInstructions instructions={inputAudioAsset.mediaShuttleInstructions} />
          {sourceSupplementalAudioAsset && (
            <AssetInstructions
              instructions={sourceSupplementalAudioAsset.mediaShuttleInstructions}
              title={sourceSupplementalAudioAsset.displayName}
            />
          )}
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
