function staticHost() {
  return window.location.origin.replace(/\baccount\b/, 'static');
}

export const graphql_endpoint = '/data';

export const mediaFileEndpoint = '/files';
export function mediaFilePath(fileId) {
  return `${mediaFileEndpoint}?fid=${fileId}`;
}

export function reformatPath(fileId, serviceType) {
  return `${mediaFileEndpoint}/${fileId}/reformat/${serviceType}`;
}

export function revisePath(fileId, serviceType) {
  return `${mediaFileEndpoint}/${fileId}/revise/${serviceType}`;
}

const newDownloadEndpoint = '/downloads/new';
export function newDownloadPath(fileId) {
  return `${newDownloadEndpoint}?media_file_ids=${fileId}`;
}

const switchToProjectEndpoint = '/project/switch_to_project';
export function switchToProjectPath(projectId) {
  return `${switchToProjectEndpoint}?switch_to_project_id=${projectId}`;
}

// app_media_file_output_format_url(media_file, format.id, {:format => format.http_format, :dl => 1, :transcript_id => transcript.id})
const captionsFormatDownloadTemplate = '/files/:media_file_id/output_formats/:format_id.:format';
function captionFormatDownloadPath(format, mediaFileId, transcriptId) {
  const endpoint = captionsFormatDownloadTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':format_id', format.id)
    .replace(':format', format.httpFormat);
  const paramstring = `transcript_id=${transcriptId}&dl=1`;
  return `${endpoint}?${paramstring}`;
}
// app_media_file_transcript_url(media_file, transcript, {:format => format.http_format, :dl => 1, :id => format.id,})
const transcriptFormatDownloadTemplate = '/files/:media_file_id/transcript.:format';
function transcriptFormatDownloadPath(format, mediaFileId, transcriptId) {
  const endpoint = transcriptFormatDownloadTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':format', format.httpFormat);
  const paramstring = `transcript_id=${transcriptId}&dl=1&id=${format.id}`;
  return `${endpoint}?${paramstring}`;
}

// app_media_file_output_format_url(media_file, format, {:format => format.http_format, :dl => 1})
const adFormatDownloadTemplate = '/files/:media_file_id/output_formats/:format_id.:format';
function adFormatDownloadPath(format, mediaFileId) {
  const endpoint = adFormatDownloadTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':format_id', format.id)
    .replace(':format', format.httpFormat);
  const paramstring = `&dl=1`;
  return `${endpoint}?${paramstring}`;
}

export function outputFormatPath(format, { mediaFileId, formatId, transcriptId }) {
  if (format.inputType === 'Caption') {
    return captionFormatDownloadPath(format, mediaFileId, transcriptId);
  } else if (format.inputType === 'Transcript') {
    return transcriptFormatDownloadPath(format, mediaFileId, transcriptId);
  } else if (format.inputType === 'Description') {
    return adFormatDownloadPath(format, mediaFileId, formatId);
  }
}

export const favoriteFormatsPath = '/project/favorite_formats';

// Cheat Sheets
export const defaultCheatSheetPath = '/cheat_sheets';
export const adDefaultCheatSheetPath = '/project/audio_description_settings';
const newCheatSheetTemplate = '/folders/:media_file_id/cheat_sheet';
export function newCheatSheetEndpoint(mediaFileId) {
  const endpoint = newCheatSheetTemplate.replace(':media_file_id', mediaFileId);
  return endpoint;
}

// Publish actions
const previewTrackPath = '/files/:media_file_id/captions.vtt?no_blanks=true';
// Audio only files need a offset so that the player controls don't obscure the captions
const offsetPreviewTrackPath = '/files/:media_file_id/captions.vtt?no_blanks=true&valign_offset=30';
export function previewTrackEndPoint({ fileId, transcriptId, shouldOffset }) {
  const path = shouldOffset ? offsetPreviewTrackPath : previewTrackPath;
  const endpoint = path.replace(':media_file_id', fileId);
  return `${endpoint}&transcript_id=${transcriptId}`;
}

export function quickLinkUrl(mediaFileId, transcriptId, formatId, projectId, httpFormat) {
  const url = new URL(staticHost());
  url.pathname = `/p/files/${mediaFileId}/threeplay_transcripts/${transcriptId}`;
  const params = { format_id: formatId, project_id: projectId, format: httpFormat };
  url.search = new URLSearchParams(params);
  return url.toString();
}

export function quickLinkADUrl(projectId, mediaFileId, formatId, formatExtension) {
  const url = new URL(staticHost());
  url.pathname = `/p/projects/${projectId}/files/${mediaFileId}/output_formats/${formatId}.${formatExtension}`;
  return url.toString();
}

const publishPluginTemplate = '/plugin_embed_codes/new';
export function publishPluginPath(mediaFileId) {
  const paramstring = `mf=${mediaFileId}`;
  return `${publishPluginTemplate}?${paramstring}`;
}
const publishPlayerTemplate = '/access_player_builder/:media_file_id';
export function accessPlayerBuilderPath(mediaFileId) {
  return publishPlayerTemplate.replace(':media_file_id', mediaFileId);
}
const seoPluginTemplate = '/files/:media_file_id/seo_embed';
export function seoPluginPath(mediaFileId) {
  const endpoint = seoPluginTemplate.replace(':media_file_id', mediaFileId);
  return endpoint;
}
const ftpDeliveryTemplate = '/ftp_deliveries/:ftp_id/deliver_file';
export function ftpDeliveryPath(mediaFileId, ftpId) {
  const endpoint = ftpDeliveryTemplate.replace(':ftp_id', ftpId);
  const paramstring = `media_file_id=${mediaFileId}`;
  return `${endpoint}?${paramstring}`;
}
const pluginTemplate = '/plugin_embed_codes/new';
export function pluginPath(mediaFileId, pt_id) {
  const paramstring = `mf=${mediaFileId}&pf=${pt_id}`;
  return `${pluginTemplate}?${paramstring}`;
}
// Orders
const upgradeTranscriptionTemplate = '/services/upgrade_to_transcription_form';
export function upgradeTranscriptionPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${upgradeTranscriptionTemplate}?${paramstring}`;
}
const upgradeLiveTranscriptionTemplate = '/order_form/new';
export function upgradeLiveTranscriptionPath(mediaFileId, mediaFileName) {
  const paramstring = `media_file_id=${mediaFileId}&media_file_name=${mediaFileName}`;
  return `${upgradeLiveTranscriptionTemplate}?${paramstring}`;
}
const aiDubbingTemplate = '/ai_dubbings/new';
export function aiDubbingPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${aiDubbingTemplate}?${paramstring}`;
}
const audioDescriptionTemplate = '/audio_descriptions/new';
export function audioDescriptionPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${audioDescriptionTemplate}?${paramstring}`;
}
const broadcastScriptsTemplate = '/broadcast_scripts/new';
export function broadcastScriptsPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${broadcastScriptsTemplate}?${paramstring}`;
}
const captionPlacementTemplate = '/caption_placements/new';
export function captionPlacementPath(mediaFileId) {
  const paramstring = `media_file_id=${mediaFileId}`;
  return `${captionPlacementTemplate}?${paramstring}`;
}
const captionEncodingTemplate = '/encoded_captions/new';
export function captionEncodingPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${captionEncodingTemplate}?${paramstring}`;
}
const translationTemplate = '/translations/new';
export function translationPath(mediaFileId) {
  const paramstring = `media_file_ids=${mediaFileId}`;
  return `${translationTemplate}?${paramstring}`;
}
const viccTemplate = '/files/:media_file_id/vcc_relations/new';
export function viccPath(mediaFileId) {
  const endpoint = viccTemplate.replace(':media_file_id', mediaFileId);
  return endpoint;
}
const transcriptReviewTemplate = '/transcript_reviews/new';
export function transcriptReviewPath(mediaFileId) {
  const paramstring = `media_file_id=${mediaFileId}`;
  return `${transcriptReviewTemplate}?${paramstring}`;
}

export function voiceArtistAudioDescriptionPath(mediaFileId) {
  return `/voice_artist_audio_descriptions/new?media_file_ids=${mediaFileId}`;
}

export function audioMixingPath(mediaFileId) {
  return `/audio_mixings/${mediaFileId}`;
}

export function dubbingPath(mediaFileId) {
  return `/dubbings/new?media_file_ids=${mediaFileId}`;
}

const reviewAlignmentTemplate = '/files/:media_file_id/alignment_review';
export function reviewAlignmentPath(mediaFileId) {
  const endpoint = reviewAlignmentTemplate.replace(':media_file_id', mediaFileId);
  return endpoint;
}

const sendToAmaraTemplate = '/amara_credentials/send_files';
export function sendToAmaraPath(mediaFileId) {
  const paramstring = `ids=${mediaFileId}`;
  return `${sendToAmaraTemplate}?${paramstring}`;
}
const moduleAddonTemplate = '/modules/:ma_id';
export function moduleAddonPath(ma_id) {
  const endpoint = moduleAddonTemplate.replace(':ma_id', ma_id);
  return endpoint;
}
const browserStreamingTemplate = 'http://interactive.3playmedia.com/portal/streaming/player.html';
export function browserStreamingPath(mediaFileId, projectId) {
  const paramstring = `project_id=${projectId}&file_id=${mediaFileId}`;
  return `${browserStreamingTemplate}?${paramstring}`;
}
const iframeEmbedTemplate =
  '<iframe src="//static.3playmedia.com/p/projects/:project_id/files/:media_file_id/player" frameborder=0 height="600" width="640"></iframe>';
export function iframeEmbedPath(mediaFileId, projectId) {
  const endpoint = iframeEmbedTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':project_id', projectId);
  return endpoint;
}

const adImportServiceTemplate = '/audio_descriptions/:ad_id';
export function adImportServicePath(audioDescriptionId) {
  const endpoint = adImportServiceTemplate.replace(':ad_id', audioDescriptionId);
  return endpoint;
}

const translationReviewTemplate = '/files/:media_file_id/translations/:translation_id/review';
export function translationReviewPath(mediaFileId, translationId) {
  const endpoint = translationReviewTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':translation_id', translationId);
  return endpoint;
}

const captionEncodingDownloadTemplate = '/encoded_captions/:encoding_id/download';
export function captionEncodingDownloadPath(encodingId) {
  const endpoint = captionEncodingDownloadTemplate.replace(':encoding_id', encodingId);
  return endpoint;
}

const adPreviewTemplate = '/audio_descriptions/:audio_description_id';
export function adPreviewPath(audioDescriptionId) {
  const endpoint = adPreviewTemplate.replace(':audio_description_id', audioDescriptionId);
  return endpoint;
}

const adUpgradeTemplate = '/audio_descriptions/upgrade_form';
export function adUpgradePath(mediaFileId, serviceId) {
  const paramstring = `media_file_id=${mediaFileId}&service_id=${serviceId}`;
  return `${adUpgradeTemplate}?${paramstring}`;
}

const adAssetDownloadTemplate = '/audio_descriptions/:ad_id/download_asset';
export function adAssetDownloadPath(audioDescriptionId, downloadFormat) {
  const assetEndpoint = adAssetDownloadTemplate.replace(':ad_id', audioDescriptionId);
  return `${assetEndpoint}?download_format=${downloadFormat}`;
}

const adTextAssetDownloadTemplate =
  '/files/:media_file_id/output_formats/:output_format_name.:output_format_id';
export function adTextAssetDownloadPath(
  mediaFileId,
  outputFormatName,
  outputFormatId,
  audioDescriptionId
) {
  const assetEndpoint = adTextAssetDownloadTemplate
    .replace(':media_file_id', mediaFileId)
    .replace(':output_format_name', outputFormatName)
    .replace(':output_format_id', outputFormatId);
  return `${assetEndpoint}?audio_description_id=${audioDescriptionId}&dl=true`;
}

const vpiViewTemplate = '/video_platform_integrations/:vpi_id';
export function vpiViewPath(vpiId) {
  return vpiViewTemplate.replace(':vpi_id', vpiId);
}

const vpiEditTemplate = '/video_platform_integrations/:vpi_id/edit';
export function vpiEditPath(vpiId) {
  return vpiEditTemplate.replace(':vpi_id', vpiId);
}

const vpiNewTemplate = '/video_platform_integrations/new';
export function vpiNewPath(platformName) {
  return `${vpiNewTemplate}?platform=${platformName}`;
}

export const ftpPath = '/project/ftp';

export const translationProfilesPath = '/translation_profiles';

const fileShowTemplate = '/files';
export function fileShowEndpoint(fileId) {
  return `${fileShowTemplate}?fid=${fileId}`;
}

// Live Auto Captioning

const lacFileShowTemplate = '/live_auto_captioning/:file_id';
export function lacFileShowEndpoint(fileId) {
  return lacFileShowTemplate.replace(':file_id', fileId);
}

export const lacDashboardPath = '/live_auto_captioning';
export const lacScheduleNewEventPath = '/live_auto_captioning/schedule_new';

const upgradeTranscriptTemplate = '/services/upgrade_to_transcription_form';
export function upgradeTranscriptEndpoint(fileId) {
  return `${upgradeTranscriptTemplate}?media_file_ids=${fileId}`;
}

const reauthenticateZoomIntegrationTemplate = '/video_platform_integrations/:id/edit?reauth=true';
export function reauthenticateZoomIntegrationPath(id) {
  return reauthenticateZoomIntegrationTemplate.replace(':id', id);
}

// Wordlist

export const wordlistSettingsPath = '/wordlist_assignment/asr_wordlist_settings';

// Settings
export const profileSettingsPath = '/profile/my_info';

// Payment
export const addCreditCardPath = '/payment_accounts/add_credit_card';
export const secureAddCreditCardPath = '/payment_accounts/create_from_nonce';

export const addBillingInfoPath = '/billing_information/add_billing_info';

export const toggleCaptionImportSoftDeletePath = '/caption_imports/toggle_soft_delete';
