import { z } from 'zod';

export const inputInfoInput = z
  .object({
    streamStartTime: z.number().int().min(0).describe('Stream start time'),
    streamWaitTime: z.number().int().min(0).describe('Stream wait time'),
    maxStreamTime: z.number().int().min(0).describe('Max stream time'),
    streamReconnectionWaitTime: z.number().int().min(0).describe('Stream reconnection wait time'),
    saveEventStream: z.boolean().describe('Whether the event stream should be saved'),
  })
  .refine((val) => val.streamWaitTime >= val.streamStartTime, {
    // This should theoretically never happen since the stream start time is only set for LPC events
    // and the stream wait time is only set for LAC events.
    message:
      'The stream wait time needs to be greater or equal to the amount of time the stream starts before the event.',
  })
  .describe('Event input information');
