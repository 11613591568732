import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Card } from '@threeplayground/unstable';
import { Alert } from '@threeplayground/index';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';

import { offPlatformDubbingJobsPaths } from '../offPlatformDubbingJobsPaths';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';

import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { OutputFormatCodeEnum } from '../../common/constants';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';

type ParamType = {
  id: string;
};

export interface DubbingOrderOption {
  sourceLanguage: { fullName: string };
  targetLanguage: { fullName: string };
}

export function OffPlatformDubbingJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformDubbingJobGraphql') return <JobLoadError />;

  const job = data.job;
  const {
    orderInstructions,
    deliveryInstructions,
    service,
    mediaFile,
    dubAudioAsset,
    inputTranscript,
  } = job;

  const supplementalFiles = mediaFile.sourceSupplementalAudioAsset?.files;
  const supplementalAudioAssetFile = supplementalFiles && supplementalFiles[0];

  const getAssets = (): DownloadableAsset[] => {
    const generateUrl = () =>
      offPlatformDubbingJobsPaths.downloadInputTranscript.getLink(
        job.id,
        OutputFormatCodeEnum.VttNoBlankFrames
      );
    const sourceScripts = formatTranscriptForDownload({
      transcript: inputTranscript,
      generateUrl: generateUrl,
    });

    const assets = [
      {
        displayName: 'Source Video',
        url: mediaFile.source.presignedDownloadUrl,
      },
      ...sourceScripts,
    ];

    if (supplementalAudioAssetFile) {
      assets.push({
        displayName: 'Supplemental Audio',
        url: supplementalAudioAssetFile.presignedDownloadUrl,
      });
    }

    if (service.__typename === 'DubbingRevisionServiceGraphql') {
      const inputAudioFiles = service.dubbingRevision.inputAudioAsset?.files;
      if (!!inputAudioFiles && !!inputAudioFiles[0]) {
        assets.push({
          displayName: 'Dubbing Audio',
          url: inputAudioFiles[0].presignedDownloadUrl,
        });
      }
    }

    return assets;
  };

  const dubbingJobDetails = (): JobDetailsRow[] => {
    const customDetails = [];

    if (service.__typename === 'DubbingRevisionServiceGraphql' && service.dubbingRevision) {
      customDetails.push({
        header: 'Language',
        value: service.dubbingRevision.language.fullName,
      });
    }

    if (service.__typename === 'DubbingServiceGraphql' && service.dub) {
      const dubbingOrderOption = service.dub.dubbingOrderOption;

      customDetails.push({
        header: 'Source Language',
        value: dubbingOrderOption.sourceLanguage.fullName,
      });

      customDetails.push({
        header: 'Target Language',
        value: dubbingOrderOption.targetLanguage.fullName,
      });
    }

    return customDetails;
  };

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Dubbing Job'}>
      {!supplementalAudioAssetFile && (
        <Row>
          {' '}
          <Alert variant={'warning'}>
            This job is waiting for the user to upload a supplemental audio asset. It cannot be
            finalized until the supplemental audio asset is uploaded.
          </Alert>
        </Row>
      )}

      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={dubbingJobDetails()}
          />
          <Card>
            <Card.Header className="font-weight-bold">Dub Audio Asset</Card.Header>
            <Card.Body>
              <UploadedFileList files={dubAudioAsset.files} />
              <FileUploader
                assetId={dubAudioAsset.id}
                assetType={AssetClassNameEnum.DubAudioAsset}
                allowedFileFormats={dubAudioAsset.allowedFileFormats}
                uploadedFiles={dubAudioAsset.files}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          {service.__typename == 'DubbingRevisionServiceGraphql' && (
            <JobInstructions
              cheatSheets={service.revisionOrderInstructions}
              title={'Revision Instructions'}
            />
          )}
          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
