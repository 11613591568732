import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Datetime from '3p-react-datetime';
import ReCAPTCHA from 'react-google-recaptcha';

import { addCreditCardPath } from '~/helpers/app/paths';
import { MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX } from '~/helpers/constants';

function expiryPickerComponent(props, openCalendar, closeCalendar, isOpen, validDate) {
  return (
    <Form.Group className="mb-0">
      <InputGroup>
        <FormControl
          {...props}
          name="expiry"
          tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 4}
          isInvalid={!validDate()}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <i
              className="fa fa-calendar"
              onClick={() => (isOpen ? closeCalendar() : openCalendar())}
            ></i>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
}

function CreditCardForm(props) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [cardData, setCardData] = useState({
    firstname: '',
    lastname: '',
    cardNumber: '',
    expiry: '',
    month: '',
    year: '',
    verification_value: '',
  });
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [error, setError] = useState('');

  function onCardChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    setCardData(() => ({
      ...cardData,
      [name]: val,
    }));
  }

  function onExpiryChange(time) {
    try {
      const formattedTime = time.format('MM/YYYY');
      const month = time.month() + 1;
      const year = time.year();
      setCardData(() => ({
        ...cardData,
        ['expiry']: formattedTime,
        ['month']: month,
        ['year']: year,
      }));
    } catch {
      setCardData(() => ({
        ...cardData,
        ['expiry']: time,
      }));
    }
  }

  const recaptchaRef = React.createRef();

  function formData() {
    const data = new FormData();
    const cardDetails = {
      number: cardData.cardNumber,
      month: cardData.month,
      year: cardData.year,
      verification_value: cardData.verification_value,
      expiry: cardData.expiry,
      first_name: cardData.firstname,
      last_name: cardData.lastname,
    };
    for (const subKey in cardDetails) {
      data.append(`credit_card[${subKey}]`, cardDetails[subKey]);
    }
    data.append('authenticity_token', props.authenticityToken);
    data.append('g-recaptcha-response', recaptchaRef.current.getValue());
    return data;
  }

  function updateView() {
    props.fetchManagePlanData();
    props.closeAddCardSection();
  }

  function validDate() {
    return true;
  }

  useEffect(() => {
    setEnableSubmit(
      cardData.firstname &&
        cardData.lastname &&
        cardData.cardNumber &&
        cardData.expiry &&
        cardData.month &&
        cardData.year &&
        cardData.verification_value &&
        captchaCompleted
    );
  }, [cardData, captchaCompleted]);

  function capturePassed() {
    setCaptchaCompleted(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const data = formData();
    recaptchaRef.current.reset();
    setCaptchaCompleted(false);
    fetch(addCreditCardPath, {
      method: 'PUT',
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          updateView();
        } else {
          setError(data.error);
        }
      });
  }

  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="formGroupFirstName">
              <Form.Label className="required-input">First Name</Form.Label>
              <Form.Control
                type="text"
                tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 1}
                name="firstname"
                className="form-control"
                placeholder="First name"
                onChange={onCardChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupLastName">
              <Form.Label className="required-input">Last Name</Form.Label>
              <Form.Control
                type="text"
                tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 2}
                name="lastname"
                className="form-control"
                placeholder="Last name"
                onChange={onCardChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGroupCardNumber">
          <Form.Label className="required-input">Card Number</Form.Label>
          <Form.Control
            type="text"
            tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 3}
            name="cardNumber"
            className="form-control"
            placeholder="xxxx-xxxx-xxxx-xxxx"
            onChange={onCardChange}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formGroupExpiry">
              <Form.Label className="required-input">Expiration Date</Form.Label>
              <Datetime
                closeOnSelect
                dateFormat="MM/YY"
                onClose={() => setCalendarOpen(false)}
                onOpen={() => setCalendarOpen(true)}
                onChange={(time) => onExpiryChange(time)}
                timeFormat={false}
                renderInput={(property, openCalendar, closeCalendar) =>
                  expiryPickerComponent(
                    property,
                    openCalendar,
                    closeCalendar,
                    calendarOpen,
                    validDate
                  )
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupSecurity">
              <Form.Label className="required-input">CVV</Form.Label>
              <Form.Control
                type="text"
                tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 5}
                name="verification_value"
                className="form-control"
                onChange={(e) => onCardChange(e)}
                placeholder="Security Code"
              />
            </Form.Group>
          </Col>
          <ReCAPTCHA sitekey={props.siteKey} onChange={capturePassed} ref={recaptchaRef} />
        </Row>
        <ButtonGroup>
          <Button
            size="sm"
            tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 6}
            variant="outline-primary"
            onClick={() => props.closeAddCardSection()}
          >
            Cancel
          </Button>
          <Button
            className="ml-2"
            disabled={!enableSubmit}
            size="sm"
            tabIndex={MANAGE_PLAN_PAYMENT_ADD_CARD_TAB_INDEX + 7}
            type="submit"
            variant="primary"
          >
            Add Card
          </Button>
        </ButtonGroup>
        {error && <Row className="m-2 text-danger">{error}</Row>}
        <Row className="m-2 d-block text-secondary">
          You represent and warrant that you will not use a credit card unless you have all
          necessary legal authorization to do so. We are not responsible for any unauthorized
          amounts billed to your credit card by a third party. Credit card payment processing
          services are provided by a third party, Braintree, a PayPal Service
          (&quot;Braintree&quot;). By submitting your credit card information you hereby consent to
          processing of your credit card by Braintree and agree to be bound by the Braintree terms
          of service, privacy policy and other&nbsp;
          <a href="https://www.braintreepayments.com/legal" target="_blank" rel="noreferrer">
            applicable policies
          </a>
          . Standard credit card or other third party processing fees apply.
        </Row>
      </Form>
    </Container>
  );
}

CreditCardForm.propTypes = {
  authenticityToken: PropTypes.string,
  closeAddCardSection: PropTypes.func,
  fetchManagePlanData: PropTypes.func,
  siteKey: PropTypes.string,
};

export default CreditCardForm;
