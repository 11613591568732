import React from 'react';
import { Container, Row, Col, Card, InputGroup, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { LiveEventGraphql } from '~/api/appApi';
import { Input } from '~/packages/threeplayground';

const isRecordStringUnknown = (x: unknown): x is Record<string, unknown> =>
  typeof x === 'object' && x !== null && Object.keys(x).every((key) => typeof key === 'string');

export function OrderSuccess() {
  const location = useLocation();

  let orderData: LiveEventGraphql | undefined;

  if (isRecordStringUnknown(location.state) && 'orderData' in location.state) {
    orderData = location.state.orderData as LiveEventGraphql;
  }

  if (!orderData) {
    history.replaceState({}, '', '/live_auto_captioning/schedule_new');
    window.location.reload();
    return null;
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Captions were successfully scheduled!</h1>
          <p>
            Every order you place helps makes the world a little more accessible. Thank you for
            using 3Play Media for all your web accessibility needs.
          </p>
          <div className="mt-4">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">{orderData.baseInfo.name}</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="mb-4">
                  <div className="mb-2">
                    <strong>Service type:</strong> {orderData.baseInfo.serviceType}
                  </div>
                  <div className="mb-2">
                    <strong>Event start time:</strong>{' '}
                    {new Date(orderData.baseInfo.eventStartTime).toLocaleString()}
                  </div>
                </div>
                {orderData.inputInfo?.inputStreamUrl && (
                  <Input
                    label="Input stream URL"
                    disabled
                    value={orderData.inputInfo?.inputStreamUrl}
                    append={() => (
                      <InputGroup.Append>
                        <Button
                          type="button"
                          onClick={() =>
                            void navigator.clipboard.writeText(
                              orderData?.inputInfo?.inputStreamUrl || ''
                            )
                          }
                        >
                          <i aria-hidden="true" className="fa fa-copy"></i> Copy
                        </Button>
                      </InputGroup.Append>
                    )}
                  />
                )}
                {orderData.inputInfo?.inputRtmpKey && (
                  <Input
                    label="Input stream key"
                    disabled
                    value={orderData.inputInfo?.inputRtmpKey}
                    append={() => (
                      <InputGroup.Append>
                        <Button
                          type="button"
                          onClick={() =>
                            void navigator.clipboard.writeText(
                              orderData?.inputInfo?.inputRtmpKey || ''
                            )
                          }
                        >
                          <i aria-hidden="true" className="fa fa-copy"></i> Copy
                        </Button>
                      </InputGroup.Append>
                    )}
                  />
                )}
                {orderData.outputInfo?.embedCodeIframe && (
                  <Input
                    label="Embed code (iFrame)s"
                    disabled
                    value={orderData.outputInfo?.embedCodeIframe}
                    append={() => (
                      <InputGroup.Append>
                        <Button
                          type="button"
                          onClick={() =>
                            void navigator.clipboard.writeText(
                              orderData?.outputInfo?.embedCodeIframe || ''
                            )
                          }
                        >
                          <i aria-hidden="true" className="fa fa-copy"></i> Copy
                        </Button>
                      </InputGroup.Append>
                    )}
                  />
                )}
                {orderData.outputInfo?.embedCodeJavascript && (
                  <Input
                    label="Embed Code (JavaScript)"
                    disabled
                    value={orderData.outputInfo?.embedCodeJavascript}
                    append={() => (
                      <InputGroup.Append>
                        <Button
                          type="button"
                          onClick={() =>
                            void navigator.clipboard.writeText(
                              orderData?.outputInfo?.embedCodeJavascript || ''
                            )
                          }
                        >
                          <i aria-hidden="true" className="fa fa-copy"></i> Copy
                        </Button>
                      </InputGroup.Append>
                    )}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="mt-4">
            <Button
              className="mr-3"
              href="/live_auto_captioning"
              type="button"
              variant="outline-primary"
            >
              Back to Dashboard
            </Button>
            <Button href="/live_auto_captioning/schedule_new" type="button">
              Schedule Another Event
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
