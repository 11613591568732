import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import AlertMessage from '~/components/app/common/AlertMessage';
import EventInstructionsModal from '../common/EventInstructionsModal';

import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';
import WithIcon from '~/components/app/common/WithIcon';

import { threeplayApi } from '~/logic/ThreeplayApi';

const updateEventInstructionMutation = `
mutation updateEventInstructionMutation($eventId: ID!, $eventInstructions: LiveEventInstructionsInput!) {
  updateEventInstructionMutation(eventId: $eventId, eventInstructions: $eventInstructions) {
    error,
    success
  }
}`;

const EventInstructions = ({ event, initialEventInstructions }) => {
  const { id, editable, editableNote, name } = event;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [show, setShow] = useState(false);
  const [eventInstructions, setEventInstructions] = useState(initialEventInstructions);

  const closeModal = () => setShow(false);

  const onSave = (newEventInstructions) => {
    setEventInstructions(newEventInstructions);
    updateEventInstructions(newEventInstructions);
  };

  const updateEventInstructions = async (newEventInstructions) => {
    const response = await threeplayApi.request(updateEventInstructionMutation, {
      eventId: id,
      eventInstructions: newEventInstructions,
    });

    if (response.errors) {
      setError(response.errors[0].message);
      setTimeout(() => setError(null), 5000);
    } else if (response.data.updateEventInstructionMutation.error) {
      setError(response.data.updateEventInstructionMutation.error);
      setTimeout(() => setError(null), 5000);
    } else {
      setSuccess(response.data.updateEventInstructionMutation.success);
      setTimeout(() => setSuccess(null), 5000);
    }
    closeModal();
  };

  return (
    <>
      <Button onClick={() => setShow(true)} size="sm" variant="link outline-primary">
        <WithIcon icon="fa fa-plus">Add Event Instructions and Speaker Labels</WithIcon>
      </Button>
      {show && (
        <ModalErrorBoundary component="EventInstructionsModal">
          <EventInstructionsModal
            onClose={closeModal}
            show={show}
            onSave={onSave}
            intialInstructions={eventInstructions}
            readOnly={!editable}
            alertNoteMessage={editableNote}
            eventName={name}
          />
        </ModalErrorBoundary>
      )}
      {error && <AlertMessage message={error} variant="danger" />}
      {success && <AlertMessage message={success} variant="success" />}
    </>
  );
};

EventInstructions.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    editable: PropTypes.bool,
    editableNote: PropTypes.string,
  }),
  initialEventInstructions: PropTypes.object,
};

export default EventInstructions;
