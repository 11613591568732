import React, { useContext } from 'react';
import { UseFormReturn, Path } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import { Input } from '@threeplayground/index';

import { FeatureFlagContext } from '../../FeatureFlagProvider';
import { OutputInfoInput } from '../../_types';

interface NestedOutputInfoInput {
  outputInfo: OutputInfoInput;
}

interface OutputInfoProps<TFieldValues extends NestedOutputInfoInput> {
  methods: UseFormReturn<TFieldValues>;
}

/**
 * The form partial for the output information
 *
 * Note: This has an overload that returns null when the outputInfo is not present
 * for a given order type to avoid typing issues in the generic OrderForm component.
 */
export function OutputInfo<TFieldValues extends NestedOutputInfoInput>(
  props: OutputInfoProps<TFieldValues>
): JSX.Element;
export function OutputInfo(props: Record<string, unknown>): null;
export function OutputInfo<TFieldValues extends NestedOutputInfoInput>({
  methods,
}: Partial<OutputInfoProps<TFieldValues>>): JSX.Element | null {
  const featureFlags = useContext(FeatureFlagContext);

  if (!methods) return null;

  const {
    register,
    formState: { errors },
  } = methods;
  return (
    <div className="mt-5 mb-5">
      <h3>Output Configuration</h3>
      <Input
        label="Output stream target url*"
        error={errors?.outputInfo?.outputStreamTargetUrl}
        placeholder="rtmp://"
        {...{
          ...register('outputInfo.outputStreamTargetUrl' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Input
        label="Output stream target key*"
        error={errors?.outputInfo?.outputStreamTargetKey}
        {...{
          ...register('outputInfo.outputStreamTargetKey' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      {featureFlags.liveStaticEmbedKeyEnabled && (
        <Form.Check
          id="outputInfo.useStaticEmbedKey"
          label="Use reserved Embed / External Webpage URL for caption delivery"
          type="checkbox"
          {...register('outputInfo.useStaticEmbedKey' as Path<TFieldValues>)}
        />
      )}
    </div>
  );
}
