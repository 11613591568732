import React, { useEffect } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import moment from 'moment-timezone';

import { ThemeProvider } from '@threeplayground/index';
import { ThreeplayApiV2 } from '~/logic/unstable/ThreeplayApiV2';
import { ThreeplayAPIProvider } from '~/logic/unstable/ThreeplayApiProvider';

import { liveEventsPaths } from './liveEventsPaths';
import { RootProps } from './_types';
import VirtualEncodingOrderForm from './new/virtual_encoding/VirtualEncodingOrderForm';
import { FeatureFlagProvider } from './FeatureFlagProvider';
import { BestPracticesProvider } from './BestPracticesProvider';
import { OrderSuccess } from './success/OrderSuccess';

const apiClient = new ThreeplayApiV2('/data');

function LiveEventsApp(props: RootProps) {
  useEffect(() => {
    moment.tz.setDefault(props.userTimeZone || 'America/New_York');
  }, [props.userTimeZone]);

  return (
    <ThemeProvider>
      <ThreeplayAPIProvider client={apiClient}>
        <FeatureFlagProvider featureFlags={props.featureFlags}>
          {/* Eventually move this to the new page */}
          <BestPracticesProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path={liveEventsPaths.createVirtualEncoding.route}
                  element={<VirtualEncodingOrderForm {...props} />}
                />
                <Route path={liveEventsPaths.orderSuccess.route} element={<OrderSuccess />} />
              </Routes>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </BestPracticesProvider>
        </FeatureFlagProvider>
      </ThreeplayAPIProvider>
    </ThemeProvider>
  );
}

export default LiveEventsApp;
