export function durationString(totalSeconds) {
  const seconds = totalSeconds % 60;
  const totalMin = Math.floor(totalSeconds / 60);
  const minutes = totalMin % 60;
  const hours = Math.floor(totalMin / 60);
  const secString = seconds < 10 ? `0${seconds}` : String(seconds);
  const minString = minutes < 10 ? `0${minutes}` : String(minutes);
  const hourString = hours < 10 ? `0${hours}` : String(hours);

  return `${hourString}:${minString}:${secString}`;
}

export const numberToCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  // Needed for displaying MachineTranslation prices to 3 digits
  maximumFractionDigits: 3,
});

export function displayPrice(amount, unit = 'min', showSign = true) {
  if (!amount) {
    return '';
  }
  return (
    (amount >= 0 && showSign === true ? '+' : '') + numberToCurrency.format(amount) + '/' + unit
  );
}

export function computeVendorPricePerMinute(amount) {
  const AVG_WORDS_PER_MINUTE = 150;
  return AVG_WORDS_PER_MINUTE * amount;
}

export function displayVendorPricePerMinute(amount, unit, showSign = false) {
  return `~${numberToCurrency.format(computeVendorPricePerMinute(amount), unit, showSign)}/${unit}`;
}

export function getDurationFromHoursAndMinutes(hours, minutes) {
  return Number(hours * 60) + Number(minutes);
}

export function getHoursAndMinutesFromDuration(duration) {
  const eventHours = Math.floor(duration / 60);
  const eventMinutes = duration % 60;
  return { eventHours, eventMinutes };
}
