// liveEventsReducer
//
// Manage dependent data operations on an array of events

// TODO: get rid of time zone here; represent state in UTC and convert on display

import { DATE_TIME_FORMAT_WITHOUT_TIMEZONE } from '../common/constants';

import {
  getAvailableStreamStartTimeOptions,
  getDefaultCaptionStartTime,
} from '../common/startTimeHelper';

const liveEventsReducer = (state, action) => {
  // static state data
  const { timeZone, defaultLacTranscoder, defaultLpcTranscoder } = state;
  const newState = { ...state };

  function getEvent() {
    if (action.id == undefined) {
      throw new Error(`No event id was provided for '${action.type}' dispatch`);
    }

    const index = newState.events.findIndex((event) => event.id === action.id);
    if (index === -1) {
      throw new Error(`Event id '${action.id}' not found in event array`);
    }

    const event = newState.events[index];
    return { index, event };
  }

  function updateEvent(updates) {
    const { index, event } = getEvent();

    const newEvent = { ...event, ...updates };
    newState.events = replace(newState.events, index, newEvent);
  }

  function getStreamStartTime(time) {
    const availableStreamStartTimes = getAvailableStreamStartTimeOptions(time, timeZone);
    return getDefaultCaptionStartTime(availableStreamStartTimes);
  }

  function setDefaultTranscoders() {
    const { defaultLacTranscoder, defaultLpcTranscoder } = action;
    newState.defaultLacTranscoder = defaultLacTranscoder;
    newState.defaultLpcTranscoder = defaultLpcTranscoder;
  }

  function setLac() {
    const { event } = getEvent();
    const streamDetails = getStreamDetails(defaultLacTranscoder);
    const newSettings = { ...event.settings };
    newSettings.transcoder = defaultLacTranscoder;
    updateEvent({
      professionalCaptioning: false,
      streamStartTime: 0,
      streamDetails,
      settings: newSettings,
    });
  }

  function setLpc() {
    const { event } = getEvent();
    const streamDetails = getStreamDetails(defaultLpcTranscoder);
    const newSettings = { ...event.settings };
    newSettings.transcoder = defaultLpcTranscoder;
    updateEvent({ professionalCaptioning: true, streamDetails, settings: newSettings });
  }

  function setRestreamUrl() {
    updateEvent({ restreamUrl: action.url });
  }

  function setStreamUrl() {
    updateEvent({ streamDetails: { url: action.url, key: action.key } });
  }

  function setEventStartTime() {
    // TODO: can we avoid using getEvent twice?
    const { event } = getEvent();
    updateEvent({ eventStartTime: formatTime(action.time) });
    if (event.professionalCaptioning) {
      updateEvent({ streamStartTime: getStreamStartTime(event.time) });
    }
  }

  function setAdditionalInstructions() {
    updateEvent({ additionalInstructions: action.additionalInstructions });
  }

  function setMeetingLocation() {
    updateEvent({ meetingLocation: action.meetingLocation });
  }

  function setCaptionerJoinTime() {
    updateEvent({ captionerJoinTime: action.time });
  }
  function setStreamStartTime() {
    updateEvent({ streamStartTime: action.time });
  }

  function setName() {
    updateEvent({ name: action.name });
  }

  function setRealtimeWowza() {
    updateEvent({ realtimeWowza: action.value });
  }

  function setLiveEventContactInformation() {
    updateEvent({ eventContactInformation: action.value });
  }

  switch (action.type) {
    // TODO: pass args to breakout functions
    // TODO: use more consistent input args: `value`
    case 'setDefaultTranscoders':
      setDefaultTranscoders();
      break;
    case 'setEvents':
      newState.events = action.events;
      break;
    case 'setLac':
      setLac();
      break;
    case 'setLpc':
      setLpc();
      break;
    // TODO: Try and consolidate one liners into something like updateEvent({ [action.key]: action.value })
    // Should reduce the number of these switch statements, and also should work out of the box for most new actions
    case 'restreamUrl':
      setRestreamUrl();
      break;
    case 'streamUrl':
      setStreamUrl();
      break;
    case 'eventStartTime':
      setEventStartTime();
      break;
    case 'streamStartTime':
      setStreamStartTime();
      break;
    case 'name':
      setName();
      break;
    case 'realTimeWowza':
      setRealtimeWowza();
      break;
    case 'captionerJoinTime':
      setCaptionerJoinTime();
      break;
    case 'meetingLocation':
      setMeetingLocation();
      break;
    case 'additionalInstructions':
      setAdditionalInstructions();
      break;
    case 'setLiveEventContactInformation':
      setLiveEventContactInformation();
      break;
    default:
      throw new Error(`Unknown action type: '${action.type}'`);
  }

  return newState;
};

function replace(array, index, newElement) {
  const preroll = array.slice(0, index);
  const postroll = array.slice(index + 1);
  return [...preroll, newElement, ...postroll];
}

function formatTime(time) {
  // TODO: check for validity instead of try/catch
  try {
    return time.format(DATE_TIME_FORMAT_WITHOUT_TIMEZONE);
  } catch {
    return time;
  }
}

function getStreamDetails(transcoder) {
  if (!transcoder) {
    return { url: '', key: '' };
  }

  const parts = transcoder.url?.split('/') || [];
  const url = parts.slice(0, parts.length - 1).join('/');
  const key = parts[parts.length - 1];
  return { url, key };
}

export default liveEventsReducer;
