import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import EndSessionImage from './images/EndSession.svg';
import { endEvent, endSession } from './EndSession';
import { swateiSettingsPath } from '../common/paths';
import { SwateiContext } from './context/Swatei.Context';

// TODO: Handle openSaveAudioModal in a better way, than passing to endSession
function MenuBar({ jobId, statusIndicator, openSaveAudioModal }) {
  const { isPracticeMode, useEndEventButton } = useContext(SwateiContext);

  const backToSwateiSettings = () => {
    window.location = swateiSettingsPath;
  };

  const EndSessionButton = useMemo(() => {
    return (
      <Nav.Link
        id="end-session"
        href="#end-session"
        onSelect={() => endSession(jobId, openSaveAudioModal)}
      >
        <img
          alt="end session icon"
          src={EndSessionImage}
          className="d-inline-block pr-2 align-text-top"
        />
        <span>End Session</span>
      </Nav.Link>
    );
  }, [jobId, openSaveAudioModal]);

  const StreamlessEventEndButton = useMemo(() => {
    return (
      <Nav.Link
        id="end-session"
        href="#end-session"
        onSelect={() => endEvent(jobId, openSaveAudioModal)}
      >
        <img
          alt="end session icon"
          src={EndSessionImage}
          className="d-inline-block pr-2 align-text-top"
        />
        <span>End Event</span>
      </Nav.Link>
    );
  }, [jobId, openSaveAudioModal]);

  return (
    <Navbar bg="dark" variant="dark" className="justify-content-end">
      <Nav className="mr-auto">
        <Navbar.Text>{statusIndicator}</Navbar.Text>
      </Nav>
      <Nav>
        {isPracticeMode ? (
          <Nav.Link onClick={backToSwateiSettings}>Back To SWATEI Settings</Nav.Link>
        ) : (
          <Nav.Item>{useEndEventButton ? StreamlessEventEndButton : EndSessionButton}</Nav.Item>
        )}
      </Nav>
    </Navbar>
  );
}

MenuBar.propTypes = {
  jobId: PropTypes.number,
  statusIndicator: PropTypes.node,
  openSaveAudioModal: PropTypes.func,
};

export default MenuBar;
