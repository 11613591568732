import { first, last } from 'lodash';
import { CaptionCreator } from '../CaptionCreator/CaptionCreator';
import { CaptionFormatConfig, SetCaptionInput, StableWordData } from '../_types';
import { CaptionFormatter } from './CaptionFormatter';

/** The default flush interval in ms */
const DEFAULT_FLUSH_INTERVAL = 2500;

/** The default character target */
const DEFAULT_CHARACTER_TARGET = 32;

interface CaptionFormatBufferParams {
  /** The caption creator */
  captionCreator: CaptionCreator;

  /** A callback to set the value of the CaptioningInterface's input */
  setCaptionInput: SetCaptionInput;

  /**
   * The caption format buffer configuration
   *
   * @default
   * { characterTarget: 32, flushInterval: 2000 }
   */
  config?: CaptionFormatConfig;
}

/**
 * Formats and buffers captions per the supplied configuration
 */
export class CaptionFormatBuffer {
  /** The class instance for creating captions */
  captionCreator: CaptionCreator;

  /** The class instance for formatting caption text */
  captionFormatter: CaptionFormatter;

  /** A callback to set the value of the CaptioningInterface's input */
  setCaptionInput: SetCaptionInput;

  /** The target number of characters for each caption */
  characterTarget: number;

  /** The time in MS to flush the caption format buffer if no new words are added */
  flushInterval: number;

  /** The last submitted caption text */
  prevSubmittedText: string;

  /** The current formatted text in the buffer */
  currentFormattedText: string;

  /** The current unformatted raw word data in the buffer */
  currentWordData: StableWordData[];

  /** The current flush interval timeout */
  flushIntervalTimeout: ReturnType<typeof setTimeout> | undefined;

  constructor({ captionCreator, setCaptionInput, config }: CaptionFormatBufferParams) {
    this.flushInterval = config?.flushInterval || DEFAULT_FLUSH_INTERVAL;
    this.characterTarget = config?.characterTarget || DEFAULT_CHARACTER_TARGET;
    this.captionCreator = captionCreator;
    this.captionFormatter = new CaptionFormatter();
    this.setCaptionInput = setCaptionInput;
    this.prevSubmittedText = '';
    this.currentFormattedText = '';
    this.currentWordData = [];
  }

  /** Add an array of words with their metadata to the buffer */
  add(wordData: StableWordData[]) {
    this.currentWordData = [...this.currentWordData, ...wordData];
    this.currentFormattedText = this.captionFormatter.format(
      this.currentWordData.map(({ word }) => word).join(' '),
      this.getLastWordSubmitted()
    );
    this.handleFormattedText();
  }

  /** Add input (generally typed) text to the buffer */
  addInputText(inputText: string) {
    /** Current ISO datetime */
    const currentDatetime = Date.now();

    /** All of the words in the input. Some of these might be from spoken words. */
    const inputWords = inputText.trim().split(' ');

    // If there are no words in the input (in other words, the captioner deleted everything),
    // then we do nothing
    if (inputWords.length === 0) return;

    // We are assuming the captioner has formatted the content in the input box
    this.currentFormattedText = inputText;

    /** The words plus metadata for the data already in the buffer */
    const wordsWithDataFromBuffer = this.getFormattedWordData();

    // Update the metadata for the last word
    const updatedWordData = wordsWithDataFromBuffer.map((w, index) => {
      if (index === wordsWithDataFromBuffer.length - 1) {
        return {
          ...w,
          captioner_typing_starts_at:
            this.currentWordData.length === wordsWithDataFromBuffer.length
              ? last(this.currentWordData)?.captioner_typing_starts_at
              : currentDatetime,
          captioner_typing_ends_at: currentDatetime,
        };
      }
      return w;
    });

    this.currentWordData = updatedWordData;

    this.handleFormattedText();
  }

  /** A function that handles the formatted text and submits the buffer as necessary */
  private handleFormattedText() {
    if (this.flushIntervalTimeout) clearTimeout(this.flushIntervalTimeout);
    this.setCaptionInput(this.currentFormattedText);
    if (this.shouldSubmit()) {
      this.submitBuffer();
    }
    if (this.currentFormattedText.length > 0) {
      this.flushIntervalTimeout = setTimeout(() => {
        this.submitBuffer();
      }, this.flushInterval);
    }
  }

  /** Whether the appropriate text in the buffer should be submitted */
  private shouldSubmit() {
    return (
      this.currentFormattedText.length >= this.characterTarget ||
      this.includesSpeakerLabel(this.currentFormattedText) ||
      this.includesNewLine(this.currentFormattedText)
    );
  }

  /** Submits the appropriate text in the buffer and modifies the buffer state */
  private submitBuffer() {
    const formattedWordData = this.getFormattedWordData();
    const arraySplittingIndex = this.getArraySplittingIndex();
    const formattedWordDataToSend = formattedWordData.slice(0, arraySplittingIndex);
    const formattedWordDataRemaining = formattedWordData.slice(arraySplittingIndex);
    const captionTextToSend = formattedWordDataToSend.map(({ word }) => word).join(' ');
    const remainingText = formattedWordDataRemaining.map(({ word }) => word).join(' ');
    const firstSpokenWordWithData = first(
      formattedWordDataToSend.filter((data) => !!data.captioner_audio_starts_at)
    );
    const lastSpokenWordWithData = last(
      formattedWordDataToSend.filter((data) => !!data.captioner_audio_ends_at)
    );
    const firstTypedWordWithData = first(
      formattedWordDataToSend.filter((data) => !!data.captioner_typing_starts_at)
    );
    const lastTypedWordWithData = last(
      formattedWordDataToSend.filter((data) => !!data.captioner_typing_ends_at)
    );
    const captionOutputData = {
      captioner_audio_starts_at: firstSpokenWordWithData?.captioner_audio_starts_at
        ? new Date(firstSpokenWordWithData.captioner_audio_starts_at).toISOString()
        : undefined,
      captioner_audio_ends_at: lastSpokenWordWithData?.captioner_audio_ends_at
        ? new Date(lastSpokenWordWithData.captioner_audio_ends_at).toISOString()
        : undefined,
      first_recognizer_response_at: lastSpokenWordWithData?.first_recognizer_response_at
        ? new Date(lastSpokenWordWithData.first_recognizer_response_at).toISOString()
        : undefined,
      sent_recognizer_response_at: lastSpokenWordWithData?.sent_recognizer_response_at
        ? new Date(lastSpokenWordWithData.sent_recognizer_response_at).toISOString()
        : undefined,
      captioner_typing_starts_at: firstTypedWordWithData?.captioner_typing_starts_at
        ? new Date(firstTypedWordWithData.captioner_typing_starts_at).toISOString()
        : undefined,
      captioner_typing_ends_at: lastTypedWordWithData?.captioner_typing_ends_at
        ? new Date(lastTypedWordWithData.captioner_typing_ends_at).toISOString()
        : undefined,
      text_from_recognizer: captionTextToSend,
      result_type: lastSpokenWordWithData?.result_type,
    };
    if (captionTextToSend.length > 0) {
      this.captionCreator.submitCaption(captionTextToSend, captionOutputData);
    }
    this.prevSubmittedText = captionTextToSend || '';
    this.currentFormattedText = remainingText || '';
    this.currentWordData = formattedWordDataRemaining;
    this.setCaptionInput(this.currentFormattedText);
  }

  /** Get the last word submitted to the caption creator */
  private getLastWordSubmitted() {
    return last(this.prevSubmittedText.split(' ')) || '';
  }

  /**
   * This maps the formatted text to an array of words with metadata.
   *
   * Please note: Due to the nature of the regex formatters, we lose the original
   * information that came from the ResultsStabilityBuffer. This attempts to maintain
   * as much of the original as possible.
   */
  private getFormattedWordData() {
    const originalWordData = this.currentWordData;
    const formattedTextWords = this.currentFormattedText.split(' ');

    const originalWordDataWithoutCommands: StableWordData[] = [];
    originalWordData.forEach((wordData, index) => {
      // Skip single word punctuation commands
      if (this.isCommand(wordData.word)) return;

      // Skip two word punctuation commands combining with previous word
      if (index > 0) {
        const wordPlusPrevious = `${originalWordData[index - 1]?.word || ''} ${wordData.word}`;
        if (this.isCommand(wordPlusPrevious)) return;
      }

      // Skip two word punctuation commands combining the next word
      if (index + 1 < originalWordData.length) {
        const wordPlusNext = `${wordData.word} ${originalWordData[index + 1]?.word || ''}`;
        if (this.isCommand(wordPlusNext)) return;
      }

      originalWordDataWithoutCommands.push(wordData);
    });

    let appliedIndex = 0;
    return formattedTextWords.map((formattedWord) => {
      const remainingWordDataToCheck = originalWordDataWithoutCommands.slice(appliedIndex);
      const foundIndex = remainingWordDataToCheck.findIndex(
        ({ word }) =>
          word.replace(/\W/g, '').toLowerCase() === formattedWord.replace(/\W/g, '').toLowerCase()
      );
      if (foundIndex !== -1) appliedIndex = foundIndex;
      return { ...remainingWordDataToCheck[foundIndex], word: formattedWord };
    });
  }

  private getArraySplittingIndex() {
    let stringSplittingIndex =
      this.currentFormattedText.length < this.characterTarget
        ? this.currentFormattedText.length
        : this.currentFormattedText.slice(0, this.characterTarget).lastIndexOf(' ');
    const textBeforeCharTarget = this.currentFormattedText.slice(0, stringSplittingIndex);

    // Split at two word command
    if (this.endsWithTwoWordCommand(textBeforeCharTarget)) {
      const lastWordInText = last(textBeforeCharTarget.split(' ')) || '';
      stringSplittingIndex = textBeforeCharTarget.lastIndexOf(lastWordInText);
    }

    // Split at speaker labels or new lines
    if (
      this.includesSpeakerLabel(textBeforeCharTarget) ||
      this.includesNewLine(textBeforeCharTarget)
    ) {
      const speakerLabelIndex = textBeforeCharTarget.indexOf(' [');
      const newLineIndex = textBeforeCharTarget.indexOf('\n');
      stringSplittingIndex = speakerLabelIndex > newLineIndex ? speakerLabelIndex : newLineIndex;
    }

    const captionTextToSend = this.currentFormattedText.slice(0, stringSplittingIndex);
    return captionTextToSend.split(' ').length;
  }

  /** Whether or not the text passed ends with a two word command (ie: bam bam) */
  private endsWithTwoWordCommand(text: string) {
    const twoWordCommands = this.getTwoWordCommands();
    const lastWordInText = last(text.toLowerCase().trim().split(' '));
    return !!lastWordInText && twoWordCommands.includes(lastWordInText);
  }

  private isCommand(text: string) {
    const normalizedText = text.toLowerCase();
    return this.getNormalizedCommands().includes(normalizedText);
  }

  /** Fetches all of the commands */
  private getNormalizedCommands() {
    return this.captionFormatter.formatters
      .map((formatter) => formatter.command)
      .filter((command): command is string => Boolean(command))
      .map((command) => command.toLowerCase());
  }

  /** Fetches all of the two word commands  */
  private getTwoWordCommands() {
    return this.captionFormatter.formatters
      .filter((formatter) => formatter.command?.match(/\s+/))
      .map((formatter) => formatter.command)
      .filter((command): command is string => Boolean(command));
  }

  /** Whether or not the text includes a speaker label */
  private includesSpeakerLabel(text: string) {
    return text.includes(' [');
  }

  /** Whether or not the text includes a new line */
  private includesNewLine(text: string) {
    return text.includes('\n');
  }
}
