import PropTypes from 'prop-types';

export const flipperFeaturesShape = PropTypes.shape({
  realtimeWowza: PropTypes.bool,
  liveSaveStreamEnabled: PropTypes.bool,
  liveStaticEmbedKeyEnabled: PropTypes.bool,
  liveCaptionConductorSpanishEnabled: PropTypes.bool,
});

export const liveStaticEmbedKeyShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const liveTranscoderShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  default: PropTypes.bool,
  externalId: PropTypes.string,
  url: PropTypes.string,
  validForLac: PropTypes.bool,
  validForLpc: PropTypes.bool,
});

export const professionalCaptioningSettingsShape = PropTypes.shape({
  duration: PropTypes.number,
  eventType: PropTypes.number,
  fallbackToAutomaticCaptions: PropTypes.bool,
});

export const settingsShape = PropTypes.shape({
  batchId: PropTypes.number,
  captioningDelay: PropTypes.number,
  streamWaitTime: PropTypes.number,
  maxStreamTime: PropTypes.number,
  saveEventStream: PropTypes.bool,
  profanityFilter: PropTypes.number,
});

export const eventShape = PropTypes.shape({
  streamStartTime: PropTypes.number,
  hasLiveStream: PropTypes.bool,
  eventTimePresent: PropTypes.bool,
  externalId: PropTypes.string,
  id: PropTypes.string,
  jobType: PropTypes.string,
  name: PropTypes.string,
  previouslyOrdered: PropTypes.bool,
  professionalCaptioning: PropTypes.bool,
  professionalCaptioningSettings: professionalCaptioningSettingsShape,
  settings: settingsShape,
  eventStartTime: PropTypes.string,
  videoId: PropTypes.string,
  wordlist: PropTypes.string,
});

export const eventSettingsShape = PropTypes.shape({
  event: eventShape,
  modal: PropTypes.bool,
});

export const paginationDetailsShape = PropTypes.shape({
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
  showFirstPageButton: PropTypes.bool,
  totalPages: PropTypes.number,
  totalVideos: PropTypes.number,
  type: PropTypes.string,
});

export const projectLiveSettingsShape = PropTypes.shape({
  batchId: PropTypes.number,
  captioningDelay: PropTypes.number,
  emailReminderTime: PropTypes.number,
  enableEmailReminder: PropTypes.bool,
  maxStreamTime: PropTypes.number,
  profanityFilter: PropTypes.number,
  saveEventStream: PropTypes.bool,
  streamWaitTime: PropTypes.number,
});

export const scheduledEventsShape = PropTypes.shape({
  streamStartTime: PropTypes.string,
  embedCodeIframe: PropTypes.string,
  embedCodeJs: PropTypes.string,
  name: PropTypes.string,
  eventStartTime: PropTypes.string,
  streamDetails: PropTypes.shape({
    rtmpKey: PropTypes.string,
    streamUrl: PropTypes.string,
  }),
});

export const wordListShape = PropTypes.shape({
  event: eventShape,
  modal: PropTypes.bool,
});
