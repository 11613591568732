import React, { useContext } from 'react';
import { UseFormReturn, Path } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import { Textarea } from '@threeplayground/index';

import { BestPracticesContext } from '../../BestPracticesProvider';
import { CaptionConfigInput } from '../../_types';

interface NestedCaptionConfigInput {
  captionConfig: CaptionConfigInput;
}

const PROFANITY_FILTER_OPTIONS = [
  {
    value: '0',
    label: 'None',
    description: 'No offensive or explicit words will be filtered.',
  },
  {
    value: '1',
    label: 'Normal',
    description:
      'Most explicit and offensive words will be filtered out. Recommended as the default setting.',
  },
  {
    value: '3',
    label: 'Strict',
    description: 'All explicit and offensive words and phrases will be filtered out and replaced.',
  },
];

/**
 * The form partial for the caption configuration
 *
 * Note: This is currently present in all live order types, so it always returns
 * a JSX.Element. If this changes, then this will need an overload that returns
 * null when the captionConfig is not present for a given order type to avoid
 * typing issues in the generic OrderForm component.
 */
export function CaptionConfig<TFieldValues extends NestedCaptionConfigInput>({
  methods,
}: {
  methods: UseFormReturn<TFieldValues>;
}) {
  const {
    register,
    formState: { errors },
  } = methods;

  const { updateBestPractices } = useContext(BestPracticesContext);

  return (
    <div className="mt-5 mb-5">
      <h3>Caption Configuration</h3>
      <fieldset>
        <Form.Group>
          <Form.Label className="mb-0">Profanity filter*</Form.Label>
          <Form.Text className="mt-0 mb-2" id="profanity-filter-description">
            Set a sensitivity level for filtering out offensive words. By default,{' '}
            <strong>Normal</strong> will filter out words that most people agree are offensive.
            Contact us for more detail.
          </Form.Text>
          {PROFANITY_FILTER_OPTIONS.map(({ description, label, value }) => (
            <Form.Check className="mb-2" key={value} type="radio" id={`profanity-filter-${value}`}>
              <Form.Check.Input
                type="radio"
                aria-describedby={`profanity-filter-${value}-describedby`}
                value={value}
                {...register('captionConfig.profanityFilter' as Path<TFieldValues>)}
              />
              <Form.Check.Label>{label}</Form.Check.Label>
              <Form.Text id={`profanity-filter-${value}-describedby`}>{description}</Form.Text>
            </Form.Check>
          ))}
        </Form.Group>
      </fieldset>
      <Textarea
        onFocus={() => {
          updateBestPractices({ add: ['wordList'] });
        }}
        label="Word list"
        descriptionText="Help our captioners more accurately caption your live event by providing a wordlist of commonly used terms and phrases. Click in the textarea to see best practices show up in the sidebar."
        error={errors?.captionConfig?.wordList}
        {...{
          ...register('captionConfig.wordList' as Path<TFieldValues>, {
            onBlur: () => updateBestPractices({ remove: ['wordList'] }),
          }),
          min: undefined,
          max: undefined,
        }}
      />
    </div>
  );
}
