const createSupportIssueMutation = `
  mutation CreateSupportIssue($eventId: ID!, $issue: String!) {
    createSupportIssueForLiveEvents(eventId: $eventId, issue: $issue) {
      error
    }
  }
`;

const endInProgressEventMutation = `
mutation EndInProgressLiveEvent($eventId: ID!) {
  endInProgressLiveEvent(eventId: $eventId) {
    error,
    success
  }
}`;

const endUpcomingEventMutation = `
mutation EndUpcomingLiveEvent($eventId: ID!, $eventCancellationOption: EventCancellationOption!) {
  endUpcomingLiveEvent(eventId: $eventId, eventCancellationOption: $eventCancellationOption) {
    error,
    success
  }
}`;

const scheduleLiveEventsMutation = `
mutation ScheduleLiveEventsMutation($events: [LiveEventDetailsInput!]!, $integrationId: ID, $userEmail: String) {
  scheduleLiveEvents(events: $events, integrationId: $integrationId, userEmail: $userEmail) {
    liveEventDetails {
      id,
      streamStartTime,
      embedCodeIframe,
      embedCodeJs,
      name,
      eventStartTime,
      halEvent,
      streamDetails {
        rtmpKey,
        streamUrl
      }
      externalWebpageWithCaptionsUrl
    }
    liveEventErrors,
    error,
    success,
    userEmail
  }
}`;

const updateLiveEventSettingsMutation = `
mutation UpdateLiveEventSettingsMutation($liveEventId: ID!, $settings: LiveEventSettingsInput!) {
  updateLiveEventSettings(liveEventId: $liveEventId, settings: $settings) {
    error,
    success
  }
}`;

const updateProjectLiveSettingsMutation = `
mutation UpdateProjectLiveSettingsMutation($settings: LiveEventSettingsInput!) {
  updateProjectLiveSettings(settings: $settings) {
    error,
    success
  }
}`;

const updateZoomCaptionUrlForEventMutation = `
mutation UpdateZoomCaptionUrlForEventMutation($eventId: ID!, $zoomCaptionUrl: String!) {
  updateZoomCaptionUrlForEvent(eventId: $eventId,zoomCaptionUrl: $zoomCaptionUrl) {
    error,
    success
  }
}`;

const updateZoomCaptionUrlMutation = `
mutation UpdateZoomCaptionUrlMutation($zoomCaptionUrl: String!) {
  updateZoomCaptionUrl(zoomCaptionUrl: $zoomCaptionUrl) {
    error,
    success
  }
}`;

export {
  createSupportIssueMutation,
  endInProgressEventMutation,
  endUpcomingEventMutation,
  scheduleLiveEventsMutation,
  updateLiveEventSettingsMutation,
  updateProjectLiveSettingsMutation,
  updateZoomCaptionUrlForEventMutation,
  updateZoomCaptionUrlMutation,
};
