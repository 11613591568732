import { Formatter } from '../_types';

interface CapitalizeNamesFormatterConfig {
  /** A lookup table from the server with casing information for names */
  casedNamesMap: Record<string, string>;
}

export class CapitalizeNamesFormatter implements Formatter {
  casedNamesMap: CapitalizeNamesFormatterConfig['casedNamesMap'];

  /**
   * A formatter that uses the fetched cased_names_list to capitalize names
   * in the text.
   *
   * @example new CapitalizeNamesFormatter({ casedNamesMap: { "3play": "3Play" } }).format("3play presents")
   * // returns "3Play presents"
   */
  constructor({ casedNamesMap }: CapitalizeNamesFormatterConfig) {
    this.casedNamesMap = casedNamesMap;
  }

  format(text: string) {
    return text.replace(/\b(\w+)\b/g, (match, p1: string) => this.casedNamesMap[p1] || p1);
  }
}
