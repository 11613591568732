import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Card } from '@threeplayground/unstable';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';

import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';

type ParamType = {
  id: string;
};

export function OffPlatformVendorTranscriptionJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformVendorTranscriptionChooseForMeJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const {
    orderInstructions,
    deliveryInstructions,
    service,
    mediaFile,
    vendorTranscriptionCaption,
  } = job;

  if (service.__typename !== 'VendorTranscriptionChooseForMeServiceGraphql')
    return <JobLoadError />;

  const { sourceLanguage } = service.vendorTranscriptionChooseForMe.serviceOption;

  const getAssets: DownloadableAsset[] = [
    {
      displayName: 'Source Video',
      url: mediaFile?.standardSource?.presignedDownloadUrl,
    },
  ];

  const jobDetails: JobDetailsRow[] = [
    { header: 'Source Language', value: sourceLanguage.fullName },
  ];

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Vendor Transcription Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets}
            customJobDetails={jobDetails}
          />
          <Card>
            <Card.Header className="font-weight-bold">Vendor Transcription Caption</Card.Header>
            <Card.Body>
              <UploadedFileList files={vendorTranscriptionCaption.files} />
              <FileUploader
                assetId={vendorTranscriptionCaption.id}
                assetType={AssetClassNameEnum.VendorTranscriptionCaption}
                allowedFileFormats={vendorTranscriptionCaption.allowedFileFormats}
                uploadedFiles={vendorTranscriptionCaption.files}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          <div className="mt-4">
            <DeliveryInstructions
              cheatSheets={deliveryInstructions}
              shouldDeliver={mediaFile.proofToFinalFinalized}
            />
          </div>
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
