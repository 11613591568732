import React, { ReactNode } from 'react';
import { Card, Table } from 'react-bootstrap';
import { Pricing } from '../../_types';
import { BestPractices } from './BestPractices';

interface OrderSummaryProps {
  eventDuration: number;
  eventName: string;
  eventStartTime: Date;
  pricing: Pricing;
  serviceType: string;
  submitButton: () => ReactNode;
}

export function OrderSummary({
  eventDuration,
  eventName,
  eventStartTime,
  pricing,
  serviceType,
  submitButton,
}: OrderSummaryProps) {
  // TODO: We should calculate this on the backend and request it here
  const estimatedCost = (() => {
    switch (serviceType) {
      case 'Professional':
        return (
          eventDuration * pricing.lpcCustomerProfessionalPricerate +
          20 * pricing.lpcCustomerPrepTimePricerate
        ).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      case 'Automatic':
        return `${pricing.liveAutoCaptioningPricerate.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}/min`;
      default:
        return 'N/A';
    }
  })();

  return (
    <div className="mt-4 pt-4" style={{ position: 'sticky', top: 0 }}>
      <h3>Order Summary</h3>
      <Card bg="light">
        <Card.Body>
          <Card.Title>{eventName}</Card.Title>
          <hr />
          <div className="mb-4">
            <div className="mb-2">
              <strong>Service type:</strong> {serviceType}
            </div>
            <div className="mb-2">
              <strong>Event start time:</strong> {new Date(eventStartTime).toLocaleString()}
            </div>
          </div>
          <Table>
            <tbody>
              <tr>
                <td className="pl-0">
                  <strong>Estimated Cost</strong>
                </td>
                <td className="text-right pr-0">
                  <strong>{estimatedCost}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
          {submitButton()}
        </Card.Body>
      </Card>
      <BestPractices />
    </div>
  );
}
