import moment from 'moment-timezone';

import { DATE_FORMAT, DATE_TIME_FORMAT_WITHOUT_TIMEZONE } from '../common/constants';

import { getRtmpStreamInfo } from '../common/helpers';
import {
  getAvailableStreamStartTimeOptions,
  getDefaultCaptionStartTime,
} from '../common/startTimeHelper';

export default function defaultEventTemplate({
  lpcEnabled,
  timeZone,
  batchId,
  captioningDelay,
  defaultLacTranscoder,
  defaultLpcTranscoder,
  liveStaticEmbedKeyId,
  maxStreamTime,
  profanityFilter,
  saveEventStream,
  streamReconnectionWaitTime,
  streamWaitTime,
  professionalCaptioning,
  fallbackToAutomaticCaptions,
  hasMultipleLiveStaticEmbedKeys,
}) {
  const defaultTranscoder = professionalCaptioning ? defaultLpcTranscoder : defaultLacTranscoder;
  const event = {};

  event.name = `Live Stream at ${getDefaultEventStartTime(DATE_FORMAT)}`;
  event.streamDetails = getRtmpStreamInfo(defaultTranscoder && defaultTranscoder.url);
  event.wordList = null;
  event.settings = {
    batchId: batchId,
    captioningDelay: captioningDelay,
    maxStreamTime: maxStreamTime,
    liveStaticEmbedKeyId: hasMultipleLiveStaticEmbedKeys ? liveStaticEmbedKeyId : undefined,
    profanityFilter: profanityFilter,
    saveEventStream: saveEventStream,
    streamReconnectionWaitTime: streamReconnectionWaitTime,
    streamWaitTime: streamWaitTime,
    transcoder: defaultTranscoder,
    useStaticEmbedKey: hasMultipleLiveStaticEmbedKeys ? undefined : Boolean(liveStaticEmbedKeyId),
  };
  event.professionalCaptioning = professionalCaptioning;

  if (lpcEnabled) {
    event.professionalCaptioningSettings = {
      duration: 0,
      eventType: 0,
      fallbackToAutomaticCaptions: fallbackToAutomaticCaptions,
    };

    event.instructions = {
      description: '',
      speakerNames: [''],
    };
  }

  event.realtimeWowza = true;
  event.hasLiveStream = true;
  event.eventStartTime = getDefaultEventStartTime(DATE_TIME_FORMAT_WITHOUT_TIMEZONE, timeZone);
  const availableStreamStartTimes = getAvailableStreamStartTimeOptions(
    event.eventStartTime,
    timeZone,
    streamWaitTime
  );
  event.streamStartTime = getDefaultCaptionStartTime(availableStreamStartTimes);

  return event;
}

function getDefaultEventStartTime(format, timeZone = 'America/New_York') {
  return moment().tz(timeZone).add(5, 'minutes').format(format);
}
