import React from 'react';
import { Card } from 'react-bootstrap';

export function WordListBestPractices() {
  return (
    <Card className="mt-4">
      <Card.Body>
        <Card.Title>Best Practices for Word Lists</Card.Title>
        <hr />
        <p>3Play recommends you follow the tips below when adding word lists:</p>
        <ul>
          <li>Do not add words with 3 or fewer letters - they will be removed from the list</li>
          <li>Do not add words with special characters, except for apostrophes and periods</li>
          <li>When adding a name or short phrase, keep all words together on a single line</li>
          <li>Word lists should be under 75 items in length</li>
        </ul>
      </Card.Body>
    </Card>
  );
}
