import moment from 'moment';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import { Logger } from '~/logic/Logger/Logger';
import { userLogger } from '~/logic/UserLogger';

const AUDIO_FORMAT = 'audio/webm';
const MIME_TYPE = `${AUDIO_FORMAT};codecs="opus"`;

export default class SaveAudioFile {
  constructor(
    options = {
      mimeType: MIME_TYPE,
      audioBitsPerSecond: 128000,
    }
  ) {
    this.options = options;
    this.mediaRecorder = null;
    this.chunks = [];
    this.swateiJobId = null;
    this.stream = null;
    // Partial AUDIO recording vars
    this.mediaRecorderPartial = null;
    this.partialAudioChunks = [];
    this.partialAudioUploadIntervalMS = 60000;
    this.continueRecordingPartial = true;
  }

  logSwateiAmplitudeEvent(eventName, swateiJobId, additionalInfo = {}) {
    userLogger.logEvent('SWATEI', eventName, {
      'Live Job Id': swateiJobId,
      Time: moment().format(DATE_TIME_FORMAT),
      ...additionalInfo,
    });
  }

  setupAndRecord(swateiJobId) {
    this.swateiJobId = swateiJobId;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => this.handleMediaRecorderSetup(stream, this))
        .catch((err) => {
          this.logSwateiAmplitudeEvent('getUserMedia error', swateiJobId, { error: err });
        });
    } else {
      this.logSwateiAmplitudeEvent('getUserMedia not supported', swateiJobId);
    }
  }

  handleMediaRecorderSetup(stream, contextRef) {
    this.stream = stream;
    this.mediaRecorder = new MediaRecorder(stream, this.options);
    this.mediaRecorder.start(2000);

    this.mediaRecorder.ondataavailable = function (e) {
      contextRef.chunks.push(e.data);
    };

    this.setUpPartialAudioRecording();
  }

  createFile(fileName, fullUpload = true) {
    const chunks = fullUpload ? this.chunks : this.partialAudioChunks;
    const blob = new Blob(chunks, { type: MIME_TYPE });
    const file = new File([blob], fileName);
    return file;
  }

  async saveSpokenAudio(
    swateiJobId,
    fullUpload = true,
    setUploadProgress = () => {},
    setUploadValidated = () => {},
    setUploadFailed = () => {}
  ) {
    const s3UploadDataBaseUrl = `/swatei/live_events/${swateiJobId}/aws_upload_path`;
    const awsUploadDataUrl = fullUpload
      ? s3UploadDataBaseUrl
      : `${s3UploadDataBaseUrl}?sub_folder=audiochunks`;

    const response = await fetch(awsUploadDataUrl, {
      headers: { Accept: 'application/json' },
      method: 'GET',
    });

    if ([401, 404].includes(response.status)) {
      Logger.getInstance().warn({
        message: '3play App aws_upload_path triggered redirect',
        info: { response },
      });

      const { redirect } = await response.json();
      window.skipConfirmLeavePage = true;
      window.location.replace(redirect);
      return;
    }
    const awsData = await response.json();

    const fileName = awsData.key.split(/[\\\/]/).pop();

    const file = this.createFile(fileName, fullUpload);
    const payload = this.buildFormData(awsData, file);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener(
      'progress',
      this.uploadProgressBar.bind(null, setUploadProgress),
      false
    );
    xhr.upload.addEventListener(
      'load',
      this.uploadCompleteHandler.bind(null, setUploadValidated),
      false
    );
    xhr.upload.addEventListener('abort', this.uploadCanceled.bind(null, setUploadProgress), false);
    xhr.open('POST', awsData.amazonUrl, true);
    this.logSwateiAmplitudeEvent('Audio File upload initiated', swateiJobId, {
      fileName: fileName,
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 0 || (xhr.status >= 200 && xhr.status <= 400)) {
          this.logSwateiAmplitudeEvent('Audio File upload success', swateiJobId);
          setUploadValidated(true);
          if (fullUpload) {
            this.continueRecordingPartial = false;
          } else {
            this.partialAudioChunks = [];
          }
          return;
        } else {
          setUploadFailed(true);
          this.logSwateiAmplitudeEvent('Audio File upload failure', swateiJobId);
        }
      }
    };
    this.logMediaInfo();
    xhr.send(payload);
  }

  setUpPartialAudioRecording() {
    this.mediaRecorderPartial = new MediaRecorder(this.stream, this.options);
    this.logMediaInfo();
    this.mediaRecorderPartial.ondataavailable = (e) => {
      this.partialAudioChunks.push(e.data);
      if (this.mediaRecorderPartial.state !== 'inactive') {
        this.mediaRecorderPartial.stop();
      }
    };
    this.mediaRecorderPartial.onstop = () => {
      this.savePartialAudio(this.swateiJobId);
      try {
        if (this.shouldContinueRecordingPartial()) {
          this.mediaRecorderPartial.start(this.partialAudioUploadIntervalMS);
        }
      } catch (e) {
        this.logSwateiAmplitudeEvent('Partial audio start failure', e);
      }
    };

    this.mediaRecorderPartial.onerror = (err) => {
      this.logSwateiAmplitudeEvent('Partial audio error', err);
    };

    try {
      this.mediaRecorderPartial.start(this.partialAudioUploadIntervalMS);
    } catch (e) {
      this.logSwateiAmplitudeEvent('Partial audio start failure', e);
    }
  }

  stopRecordingPartialAudio() {
    this.continueRecordingPartial = false;
  }

  shouldContinueRecordingPartial() {
    return this.continueRecordingPartial;
  }

  savePartialAudio(swateiJobId) {
    try {
      this.saveSpokenAudio(swateiJobId, false);
    } catch (err) {
      this.logSwateiAmplitudeEvent('Partial audio upload failure', swateiJobId);
    }
  }

  uploadProgressBar(setUploadProgress, evt) {
    if (evt.lengthComputable) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      setUploadProgress(percentComplete);
    }
  }

  uploadCompleteHandler(setUploadValidated) {
    setUploadValidated(true);
  }

  // Cancelled - Show error message
  uploadCanceled(setUploadProgress) {
    setUploadProgress(-1);
  }

  buildFormData(awsData, file) {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('Content-Type', AUDIO_FORMAT);
    formData.append('file', file);
    return formData;
  }

  logMediaInfo() {
    Logger.getInstance().info({
      message: `SWATEI-Media-Info-${this.swateiJobId}`,
      info: {
        audioBitsPerSecond: this.mediaRecorder?.audioBitsPerSecond,
        audioMimeType: this.mediaRecorder?.mimeType,
        sampleRate: this.audioContext?.sampleRate,
        navigator: navigator.userAgent,
      },
    });
  }
}
