import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { OutputFormatCodeEnum } from '../../common/constants';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails } from '../../common/OffPlatformJobDetails';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';
import { paths } from '../paths';

type ParamType = {
  id: string;
};

export const OffPlatformDescriptiveTranscriptJob = () => {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformDescriptiveTranscriptJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const { orderInstructions, mediaFile, deliveryInstructions, service } = job;

  if (!service || service.__typename !== 'DescriptiveTranscriptServiceGraphql')
    return <JobLoadError />;

  const { inputTranscript, descriptiveTranscript } = service;
  const { allowedFileFormats, files } = descriptiveTranscript;

  const generateUrl = (): string =>
    paths.downloadTranscript.getLink(inputTranscript.id, OutputFormatCodeEnum.PlainDoc);

  const getAssets = (): DownloadableAsset[] => {
    const transcript = formatTranscriptForDownload({
      transcript: inputTranscript,
      generateUrl,
    });

    return [
      { displayName: 'Source Video', url: mediaFile.standardSource?.presignedDownloadUrl || '' },
      ...transcript,
    ];
  };

  const showOrderInstructions = orderInstructions && orderInstructions.length > 0;

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Descriptive Transcript Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails job={job} downloadableAssets={getAssets()} />
          <UploadedFileList files={files} />
          <FileUploader
            assetId={descriptiveTranscript.id}
            assetType={AssetClassNameEnum.DescriptiveTranscript}
            allowedFileFormats={allowedFileFormats}
            uploadedFiles={files}
          />
        </Col>
        <Col lg={6} className="mt-4">
          {showOrderInstructions && (
            <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          )}

          <DeliveryInstructions
            cheatSheets={deliveryInstructions}
            shouldDeliver={mediaFile.proofToFinalFinalized}
          />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
};
