import { z } from 'zod';

export const contactInfoInput = z
  .object({
    name: z.string().min(1).max(255).describe("The contact's name"),
    email: z.string().min(1).max(255).email().describe("The contact's email"),
    phoneNumber: z
      .string()
      .regex(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/)
      .describe("The contact's phone number"),
  })
  .describe('Event contact information');
