import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { offPlatformCaptionQualityControlJobsPaths } from '../offPlatformCaptionQualityControlJobsPaths';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { formatTranscriptForDownload } from '../../common/downloadable_asset_cell/helpers';
import { JobLoadError } from '../../common/JobLoadError';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { OutputFormatCodeEnum } from '../../common/constants';
import { DeliveryInstructions } from '../../common/DeliveryInstructions';

type ParamType = {
  id: string;
};

const getTranscriptUrl = (id: string, outputFormatCode?: OutputFormatCodeEnum): string =>
  offPlatformCaptionQualityControlJobsPaths.downloadTranscript.getLink(id, outputFormatCode);

const outputFormats = [
  { displayName: 'WebVtt', key: OutputFormatCodeEnum.VttMusicMarkupNoBlanks },
  { displayName: 'SCC - Broadcast', key: OutputFormatCodeEnum.SccBroadcast },
  { displayName: 'SCC - Broadcast Top', key: OutputFormatCodeEnum.SccBroadcastTop },
  {
    displayName: 'SCC - Broadcast, No Paint On',
    key: OutputFormatCodeEnum.SccBroadcastNoPaintOn,
  },
  {
    displayName: 'SCC - Broadcast Top, No Paint On',
    key: OutputFormatCodeEnum.SccBroadcastTopNoPaintOn,
  },
  { displayName: 'SRT - No Blanks', key: OutputFormatCodeEnum.SrtMusicMarkupNoBlanks },
  {
    displayName: 'ITT - iTunes SMPTE Caption Format',
    key: OutputFormatCodeEnum.IttITunesSMPTECaptionFormat,
  },
];

export function OffPlatformCaptionQualityControlJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformCaptionQualityControlJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const {
    captionQcLevel,
    localizationInputTranscript,
    orderInstructions,
    deliveryInstructions,
    service,
    mediaFile,
    qualityControlCaption,
  } = job;

  const { allowedFileFormats, files, inputTranscript } = qualityControlCaption;
  const getAssets = () => {
    const generateUrl = (outputFormatCode?: OutputFormatCodeEnum): string =>
      getTranscriptUrl(inputTranscript.id, outputFormatCode);

    const transcripts = formatTranscriptForDownload({
      transcript: inputTranscript,
      generateUrl,
      outputFormats,
    });

    const assets = [
      {
        displayName: 'Source Video',
        url: mediaFile.stoeStream?.presignedDownloadUrl,
      },
      ...transcripts,
    ];

    if (localizationInputTranscript) {
      const generateTranscriptUrl = (outputFormatCode?: OutputFormatCodeEnum): string =>
        getTranscriptUrl(localizationInputTranscript.id, outputFormatCode);

      const localizationTranscripts = formatTranscriptForDownload({
        transcript: localizationInputTranscript,
        generateUrl: generateTranscriptUrl,
        outputFormats,
        displayName: 'Localization Parent Transcript',
      });

      assets.push(...localizationTranscripts);
    }

    if (
      service.__typename == 'SourceLanguageTemplateServiceGraphql' &&
      service.forcedNarrativeTranscript
    ) {
      const generateFNUrl = (outputFormatCode?: OutputFormatCodeEnum): string =>
        getTranscriptUrl(service.forcedNarrativeTranscript.id, outputFormatCode);

      const forcedNarrativeTranscript = formatTranscriptForDownload({
        transcript: service.forcedNarrativeTranscript,
        generateUrl: generateFNUrl,
        outputFormats,
        displayName: 'Forced Narrative Transcript',
      });

      assets.push(...forcedNarrativeTranscript);
    }

    return assets;
  };

  const customJobDetails: JobDetailsRow[] = [
    {
      header: 'Source Transcript Language',
      value: inputTranscript.language.fullName,
    },
    {
      header: 'QC Level',
      value: captionQcLevel,
    },
  ];

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Caption Quality Control Job'}>
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={customJobDetails}
          />
          <UploadedFileList files={files} />
          <FileUploader
            assetId={qualityControlCaption.id}
            assetType={AssetClassNameEnum.QualityControlCaption}
            allowedFileFormats={allowedFileFormats}
            uploadedFiles={files}
          />
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          {service.__typename == 'CaptionRevisionServiceGraphql' && (
            <JobInstructions
              cheatSheets={service.revisionOrderInstructions}
              title={'Revision Instructions'}
            />
          )}
          {job.showDeliveryInstructions && (
            <DeliveryInstructions
              cheatSheets={deliveryInstructions}
              shouldDeliver={mediaFile.proofToFinalFinalized}
            />
          )}
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
