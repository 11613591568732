import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

function LiveEventContactModal({
  show,
  onSave,
  setShow,
  onChange,
  contactInfo = {},
  buttonText = 'Add Contact',
}) {
  const [initialContact] = React.useState(contactInfo);

  const onClickHandler = () => {
    onSave();
    setShow(false);
  };

  const onCancel = () => {
    setShow(false);
    onChange(initialContact);
  };

  return (
    <Modal dialogClassName="modal-width-large" show={show} onExit={onCancel} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Event Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="contactName">
            <Form.Label>
              <strong>Contact Name</strong>
            </Form.Label>
            <InputGroup className="w-50">
              <Form.Control
                aria-describedby="contactName"
                aria-label="contactName"
                onChange={(e) => onChange({ ...contactInfo, name: e.target.value })}
                type="text"
                value={contactInfo.name}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="contactEmail">
            <Form.Label>
              <strong>Contact Email</strong>
            </Form.Label>
            <InputGroup className="w-50">
              <Form.Control
                aria-describedby="contactEmail"
                aria-label="contactEmail"
                onChange={(e) => onChange({ ...contactInfo, email: e.target.value })}
                type="text"
                value={contactInfo.email}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="contactPhoneNumber">
            <Form.Label>
              <strong>Contact Phone</strong>
            </Form.Label>
            <InputGroup className="w-50">
              <Form.Control
                aria-describedby="contactPhoneNumber"
                aria-label="contactPhoneNumber"
                onChange={(e) => onChange({ ...contactInfo, phoneNumber: e.target.value })}
                type="text"
                value={contactInfo.phoneNumber}
              />
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          aria-label="saveContactInformation"
          // eslint-disable-next-line no-console
          onClick={onClickHandler}
          variant="primary"
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

LiveEventContactModal.propTypes = {
  setShow: PropTypes.func,
  show: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  event: PropTypes.shape({ id: PropTypes.string || PropTypes.number }),
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  buttonText: PropTypes.string,
};

export default LiveEventContactModal;
