import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { getCsrfToken } from '~/helpers/authtokenHelper';

export function ReportNoAudioButton({ reportNoAudioPath, submitCaption }) {
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    await fetch(reportNoAudioPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    });
    submitCaption('[NO AUDIO]');
    setDisabled(false);
  };

  return (
    <Button disabled={disabled} onClick={handleClick} type="button" variant="warning">
      No Audio
    </Button>
  );
}

ReportNoAudioButton.propTypes = {
  reportNoAudioPath: PropTypes.string.isRequired,
  submitCaption: PropTypes.func.isRequired,
};
