import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Card from 'react-bootstrap/Card';

function EventListingEmptyState(props) {
  return (
    <Card body className={`${css(styles.cardBackground)} p-5 w-50 text-center`}>
      <i className="fa fa-calendar fa-4x"></i>
      <div className={`${css(styles.noteText)} pt-4`}>
        There are no {props.type}s scheduled by this user.
        <br />
        Make sure your {props.type}s are scheduled or active in {props.platform} to have them show
        up here.
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  cardBackground: {
    background: '#F9F9F9',
  },
  noteText: {
    fontWeight: '600',
  },
});

EventListingEmptyState.propTypes = {
  platform: PropTypes.string,
  type: PropTypes.string,
};

export default EventListingEmptyState;
