import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { isEmpty } from 'lodash';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { Typeahead } from 'react-bootstrap-typeahead';

import {
  eventShape,
  flipperFeaturesShape,
  liveTranscoderShape,
  liveStaticEmbedKeyShape,
} from '../common/shapes';
import { PROFANITY_FILTER_OPTIONS } from '../common/constants';
import { useLiveTranscoders } from '../common/useLiveTranscoders';

import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import TranscoderSelect from '../common/TranscoderSelect';

import {
  MAXIMUM_STREAM_WAIT_TIME,
  MINIMUM_STREAM_WAIT_TIME,
  MAXIMUM_CAPTIONING_DELAY,
  MINIMUM_CAPTIONING_DELAY,
} from '~/components/app/live_auto_captioning/common/constants';
import { FlipperFlagContext } from './OrderPage';

function EventSettingsModal(props) {
  const flipperFeatures = useContext(FlipperFlagContext);
  const { liveSaveStreamEnabled, liveStaticEmbedKeyEnabled } = flipperFeatures || {};

  const transcoderOptions = useLiveTranscoders(props.liveTranscoders, {
    lpc: props.event && props.event.professionalCaptioning,
  });

  const [settings, setSettings] = useState((props.event && props.event.settings) || {});
  const [validInputs, setValidInputs] = useState({
    maxStreamTime: true,
    streamWaitTime: true,
    streamReconnectionWaitTime: true,
  });

  const saveStreamEnabled = () => {
    const waitTimeIsValid = validInputs.streamReconnectionWaitTime;
    const hasSettings = !isEmpty(settings);
    // remove this line after we enable flipper
    const reconnectionDisabled = settings.streamReconnectionWaitTime === 0;

    return hasSettings && waitTimeIsValid && (liveSaveStreamEnabled || reconnectionDisabled);
  };

  useEffect(() => {
    if (props.show) {
      setSettings(props.event && props.event.settings);
    }
  }, [props.show]);

  useEffect(() => {
    if (saveStreamEnabled()) {
      setSettings({ ...settings, saveEventStream: false });
    }
  }, [settings.streamReconnectionWaitTime]);

  if (props.event === undefined) {
    return null;
  }

  const getSaveEventStreamTooltip = () => {
    return saveStreamEnabled()
      ? `If enabled, the live stream will be saved as the source for the file after the event.
      This is useful if service upgrades are anticipated, e.g. to Transcription.
      Leave this off otherwise as it incurs extra processing both during and after the event.
      This is only available for RTMP streams.`
      : `3Play currently doesn’t not offer the ability for streams to be saved if the stream reconnection wait time is set to a value greater than 0.`;
  };

  function verifyCaptioningDelay(value) {
    if (isNaN(value)) {
      setSettings({ ...settings, captioningDelay: MINIMUM_CAPTIONING_DELAY });
    }

    if (value < MINIMUM_CAPTIONING_DELAY) {
      setSettings({ ...settings, captioningDelay: MINIMUM_CAPTIONING_DELAY });
    } else if (value > MAXIMUM_CAPTIONING_DELAY) {
      setSettings({ ...settings, captioningDelay: MAXIMUM_CAPTIONING_DELAY });
    }
  }

  function validateAndUpdateState(type, value) {
    if (!isNaN(value)) {
      value = Number(value);
    }
    isValidValue(type, value);
    setSettings({ ...settings, [type]: value });
  }

  function isValidValue(type, value) {
    let isValid = false;
    switch (type) {
      case 'maxStreamTime': {
        isValid = value <= props.maxStreamTime;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'streamWaitTime': {
        isValid = value >= MINIMUM_STREAM_WAIT_TIME && value <= MAXIMUM_STREAM_WAIT_TIME;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'streamReconnectionWaitTime': {
        isValid = value >= 0 && value <= props.maxStreamReconnectionWaitTime;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
    }
  }

  function updateSelectedBatch(batch) {
    setSettings({ ...settings, batchId: Number(batch.id) });
  }

  function updateSelectedTranscoder(transcoder) {
    setSettings({ ...settings, transcoder });
  }

  const batchSelectionDisableMessage = () => {
    if (props.scheduledEvent) {
      return [
        `The folder associated with this event cannot be updated once the event has been scheduled.
        In order to associate this event with a different folder, please delete and reschedule this event.`,
      ];
    } else {
      return [];
    }
  };

  return (
    <Modal
      dialogClassName="modal-width-large"
      onHide={() => props.closeEventSettingsModal()}
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Advanced Settings: {props.event?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="captioningDelay">
            <Form.Label>
              <strong>Maximum Captioning Delay</strong>
              <ThreePlayTooltip
                tooltipText="This will set a delay between the stream we receive and the captions we output.
                Longer latency will result in a more accurate output.
                You can delay the playback stream to your viewers to keep the video and captions in sync.
                Warning: do not delay the stream provided to us."
              />
            </Form.Label>
            <InputGroup className="w-50">
              <Form.Control
                aria-describedby="captionDelay"
                aria-label="captionDelay"
                isInvalid={
                  MINIMUM_CAPTIONING_DELAY > settings.captioningDelay ||
                  settings.captioningDelay > MAXIMUM_CAPTIONING_DELAY
                }
                onBlur={(e) => verifyCaptioningDelay(e.target.value)}
                onChange={(e) =>
                  setSettings({ ...settings, captioningDelay: Number(e.target.value) })
                }
                placeholder="5000"
                type="text"
                value={settings.captioningDelay == undefined ? '' : settings.captioningDelay}
              />
              <InputGroup.Append>
                <InputGroup.Text id="captionDelay">ms</InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                The captioning delay has to be a number between {MINIMUM_CAPTIONING_DELAY} and{' '}
                {MAXIMUM_CAPTIONING_DELAY}.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="w-50 d-flex" controlId="captionDelayRange">
            <span className="align-self-center">Quicker</span>
            <Form.Control
              className="mx-2 p-0"
              aria-label="captionDelaySlider"
              onChange={(e) =>
                setSettings({ ...settings, captioningDelay: Number(e.target.value) })
              }
              min="2000"
              max="15000"
              step="500"
              type="range"
              value={settings.captioningDelay == undefined ? '' : settings.captioningDelay}
            />
            <span>More accurate</span>
          </Form.Group>
          <Row className="w-75">
            {props.event.professionalCaptioning === false && (
              <Col>
                <Form.Group>
                  <Form.Label>
                    <strong>Stream Wait Time</strong>
                    <ThreePlayTooltip
                      tooltipText="Set an amount of time for our system to wait after the Stream Wait Time if no audio is detected.
                    Costs will be calculated from the Stream Wait Time, and the service will end if no audio is detected by the conclusion of the Stream Wait Time."
                    />
                  </Form.Label>
                  <InputGroup className="w-50">
                    <Form.Control
                      aria-describedby="streamWaitTime"
                      aria-label="streamWaitTime"
                      isInvalid={!validInputs.streamWaitTime}
                      onChange={(e) =>
                        validateAndUpdateState('streamWaitTime', Number(e.target.value))
                      }
                      placeholder="30"
                      type="text"
                      value={settings.streamWaitTime == undefined ? '' : settings.streamWaitTime}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="streamWaitTime">min</InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      The stream wait time has to be a valid number between{' '}
                      {MINIMUM_STREAM_WAIT_TIME} and {MAXIMUM_STREAM_WAIT_TIME}.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group>
                <Form.Label>
                  <strong>Max Stream Time</strong>
                  <ThreePlayTooltip
                    tooltipText="Set a maximum time for your live captioning event.
                    Maximum time will be calculated from Event Start Time, and will not include any delays or breaks.
                    When the max time is reached, captioning service will stop even if the event is still active.
                    Events in your project can be no longer than your Event Max Time Config."
                  />
                </Form.Label>
                <InputGroup className="w-50">
                  <Form.Control
                    aria-describedby="maxStreamTime"
                    aria-label="maxStreamTime"
                    isInvalid={!validInputs.maxStreamTime}
                    onChange={(e) =>
                      validateAndUpdateState('maxStreamTime', Number(e.target.value))
                    }
                    placeholder={props.maxStreamTime}
                    type="text"
                    value={settings.maxStreamTime == undefined ? '' : settings.maxStreamTime}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="maxStreamTime">min</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    The max wait time has to be a valid number under {props.maxStreamTime}.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="streamReconnectionWaitTime">
            <Form.Label>
              <strong>Stream Reconnection Wait Time</strong>
              <ThreePlayTooltip tooltipText="If your stream is disconnected from 3Play, specify how long 3Play should wait before ending your event. After this duration, you will no longer be able to reconnect to this event, and will need to schedule a new event in 3Play to continue captions." />
            </Form.Label>
            <InputGroup className="w-50">
              <Form.Control
                aria-describedby="streamReconnectionWaitTime"
                aria-label="streamReconnectionWaitTime"
                isInvalid={
                  settings.streamReconnectionWaitTime < 0 ||
                  settings.streamReconnectionWaitTime > props.maxStreamReconnectionWaitTime
                }
                onChange={(e) =>
                  validateAndUpdateState('streamReconnectionWaitTime', Number(e.target.value))
                }
                placeholder="10"
                type="text"
                value={
                  settings.streamReconnectionWaitTime == undefined
                    ? ''
                    : settings.streamReconnectionWaitTime
                }
              />
              <InputGroup.Append>
                <InputGroup.Text id="captionDelay">min</InputGroup.Text>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                The stream reconnection wait time has to be a number between 0 and{' '}
                {props.maxStreamReconnectionWaitTime == undefined
                  ? ''
                  : props.maxStreamReconnectionWaitTime}
                .
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Check
              className="mb-2 d-inline"
              disabled={!saveStreamEnabled()}
              aria-label="saveEventStream"
              aria-describedby="saveEventStream"
              onChange={(e) => setSettings({ ...settings, saveEventStream: e.target.checked })}
              label="Save Event Stream"
              type="checkbox"
              checked={settings.saveEventStream == undefined ? '' : settings.saveEventStream}
            />
            <ThreePlayTooltip tooltipText={getSaveEventStreamTooltip()} />
          </Form.Group>
          {liveStaticEmbedKeyEnabled && (props.liveStaticEmbedKeys || []).length <= 1 && (
            <Form.Group controlId="use-static-embed-key-checkbox">
              <Form.Check
                className="mb-2 d-inline"
                label="Use Reserved Embed / External Webpage URL for caption delivery"
                onChange={(e) => setSettings({ ...settings, useStaticEmbedKey: e.target.checked })}
                type="checkbox"
                checked={settings.useStaticEmbedKey || ''}
              />
            </Form.Group>
          )}
          {/* Don't render before the current settings are properly set, so this
          has the proper default value */}
          {!isEmpty(settings) &&
            liveStaticEmbedKeyEnabled &&
            (props.liveStaticEmbedKeys || []).length > 1 && (
              <Form.Group controlId="static-embed-key-select">
                <Form.Label className="d-block">
                  <strong>Use Reserved Embed / External Webpage URL for caption delivery</strong>
                </Form.Label>
                <span className="w-50 d-inline-block">
                  <Form.Control
                    as="select"
                    defaultValue={settings.liveStaticEmbedKeyId}
                    onChange={(e) =>
                      setSettings({ ...settings, liveStaticEmbedKeyId: e.target.value })
                    }
                  >
                    <option value={''}>None</option>
                    {props.liveStaticEmbedKeys.map((staticEmbedKey) => (
                      <option key={staticEmbedKey.id} value={staticEmbedKey.id}>
                        {staticEmbedKey.name}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </Form.Group>
            )}
          <Form.Group>
            <Form.Label className="d-block">
              <strong>Would you like to use a reserved stream target?</strong>
            </Form.Label>
            <span className="w-50 d-inline-block">
              <TranscoderSelect
                transcoders={transcoderOptions}
                selectedId={settings.transcoder?.id}
                onChange={updateSelectedTranscoder}
              />
            </span>
          </Form.Group>
          <Form.Group>
            <Form.Label className="d-block">
              <strong>Where would you like us to store this file upon completion?</strong>
            </Form.Label>
            {'batchId' in settings && props.batches.length > 0 && (
              <TooltipIfErrors errors={batchSelectionDisableMessage()} location="top" noSpan>
                <span className="w-50 d-inline-block">
                  <Typeahead
                    id="batch-input"
                    inputProps={{ 'aria-label': 'batch-dropdown' }}
                    className={`form-control-sm px-0 ${
                      props.scheduledEvent ? css(styles.disabled) : ''
                    }`}
                    disabled={props.scheduledEvent}
                    labelKey="name"
                    options={props.batches}
                    onChange={(obj) => {
                      obj.length > 0 && updateSelectedBatch(obj[0]);
                    }}
                    placeholder="Search Folder"
                    defaultInputValue={
                      props.batches.find((batch) => Number(batch.id) === settings.batchId)?.name
                    }
                  />
                </span>
              </TooltipIfErrors>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>Profanity Filter</strong>
              <ThreePlayTooltip
                tooltipText="Set a sensitivity level for filtering out offensive words.
                By default, 'Normal' will filter out words that most people agree are offensive.
                Contact us for more detail."
              />
            </Form.Label>
          </Form.Group>
          {PROFANITY_FILTER_OPTIONS.map((option, index) => {
            return (
              <Form.Group key={index}>
                <Form.Check
                  aria-label="profanityFilter"
                  label={option.name}
                  onChange={() => setSettings({ ...settings, profanityFilter: option.level })}
                  name="profanityFilter"
                  type="radio"
                  checked={settings.profanityFilter === option.level}
                />
                <Form.Text muted>{option.description}</Form.Text>
              </Form.Group>
            );
          })}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => props.closeEventSettingsModal()}>
          Cancel
        </Button>
        <Button
          aria-label="saveEventSettings"
          disabled={Object.keys(validInputs).some((key) => {
            return !validInputs[key];
          })}
          onClick={() => props.updateEventSettings(props.event.id, settings)}
          variant="primary"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EventSettingsModal.propTypes = {
  batches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  closeEventSettingsModal: PropTypes.func,
  event: eventShape,
  liveTranscoders: PropTypes.arrayOf(liveTranscoderShape),
  maxStreamReconnectionWaitTime: PropTypes.number,
  maxStreamTime: PropTypes.number.isRequired,
  scheduledEvent: PropTypes.bool,
  show: PropTypes.bool,
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  updateEventSettings: PropTypes.func,
  // feature flag: remove after enablement
  flipperFeatures: flipperFeaturesShape,
};

const styles = StyleSheet.create({
  badgeFontSize: {
    fontSize: '90%',
  },
  disabled: {
    pointerEvents: 'none',
  },
});

export default EventSettingsModal;
