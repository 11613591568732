export function sortBatches(batches) {
  return batches
    .filter((batch) => !batch.archived)
    .sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
}

export function getRtmpStreamInfo(rtmpUrl) {
  if (!rtmpUrl) {
    return { url: '', key: '' };
  } else {
    const parts = rtmpUrl.split('/') || [];
    const url = parts.slice(0, parts.length - 1).join('/');
    const key = parts[parts.length - 1];
    return { url, key };
  }
}
