import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import {
  BACK_BUTTON_INDEX,
  FINISH_BUTTON_INDEX,
  NEXT_BUTTON_INDEX,
  MANAGE_PLAN_WIZARD_STEPS,
  MANAGE_PLAN_CONTACT_US_TAB_INDEX,
  ENTER_KEY_CODE,
  SPACE_KEY_CODE,
} from '~/helpers/constants';

import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
function ManagePlanStepContainer(props) {
  function navigateToStep(step) {
    if (step > props.currentStep) {
      return;
    } else {
      props.setCurrentStep(step);
    }
  }

  function checkStepKeyPress(event, step) {
    const code = event.keyCode || event.charCode;
    if (code === ENTER_KEY_CODE || code === SPACE_KEY_CODE) {
      navigateToStep(step);
    }
  }

  function prevStep() {
    props.setCurrentStep(props.currentStep - 1);
  }

  function nextStep() {
    props.setCurrentStep(props.currentStep + 1);
  }

  const containerStyle = {
    border: '1px solid rgb(235, 237, 240)',
    marginBottom: 24,
  };

  const stepTitles = MANAGE_PLAN_WIZARD_STEPS;

  return (
    <>
      <Steps style={containerStyle} type="navigation" current={props.currentStep}>
        {stepTitles.map((step, index) => {
          return (
            <Steps.Step
              aria-current={props.currentStep === index ? 'step' : ''}
              className={props.currentStep > index ? css(styles.pointer) : ''}
              key={index}
              tabIndex={index + 2}
              onClick={() => navigateToStep(index)}
              onKeyDown={(e) => checkStepKeyPress(e, index)}
              title={step}
            />
          );
        })}
      </Steps>

      <Container
        className={props.currentStepType === 'confirmation' ? css(styles.finalStepContainer) : ''}
      >
        {props.children}
      </Container>
      <Container className={css(styles.buttonContainer)}>
        <Row className="text-secondary m-2">
          If you anticipate a significant increase in needs for 3Play Media services, please&nbsp;
          <a tabIndex={MANAGE_PLAN_CONTACT_US_TAB_INDEX} href="mailto:customersales@3playmedia.com">
            contact us
          </a>{' '}
          to discuss advanced options.
        </Row>
        <div>
          {props.currentStepType != 'choosePlan' && (
            <Button
              tabIndex={BACK_BUTTON_INDEX}
              variant="outline-primary"
              className={css(styles.backButton)}
              onClick={prevStep}
            >
              Previous Step
            </Button>
          )}
          {props.currentStepType != 'confirmation' && (
            <div className={css(styles.floatRight)}>
              <TooltipIfErrors errors={props.stepErrors} location="top">
                <Button
                  tabIndex={NEXT_BUTTON_INDEX}
                  onClick={nextStep}
                  className={css(styles.nextButton)}
                  disabled={!props.enableNext}
                >
                  Next Step
                </Button>
              </TooltipIfErrors>
            </div>
          )}
          {props.currentStepType === 'confirmation' && (
            <div className={css(styles.floatRight)}>
              <TooltipIfErrors errors={props.stepErrors} location="top">
                <Button
                  tabIndex={FINISH_BUTTON_INDEX}
                  onClick={props.submit}
                  className={css(styles.nextButton)}
                  disabled={!props.enableSubscribe}
                >
                  Subscribe to Pro
                </Button>
              </TooltipIfErrors>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

ManagePlanStepContainer.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.number,
  currentStepType: PropTypes.string,
  enableNext: PropTypes.bool,
  enableSubscribe: PropTypes.bool,
  stepErrors: PropTypes.array,
  setCurrentStep: PropTypes.any,
  submit: PropTypes.func,
};

const styles = StyleSheet.create({
  finalStepContainer: {
    padding: '0px',
  },
  buttonContainer: {
    padding: '15px',
  },
  floatRight: {
    float: 'right',
  },
  nextButton: {
    float: 'right',
    borderWidth: '2px',
    fontWeight: 'bold',
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ':disabled': {
      pointerEvents: 'none',
    },
  },
  backButton: {
    fontWeight: 'bold',
    ':focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ':hover': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
});

export default ManagePlanStepContainer;
