/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import {
  UseFormReturn,
  Path,
  useFieldArray,
  ArrayPath,
  FieldArray,
  UnpackNestedValue,
} from 'react-hook-form';
import { Input, Select, Textarea, Button } from '@threeplayground/index';
import { Form, Col } from 'react-bootstrap';

import { LiveEventTypes, LpcInfoInput, Pricing } from '../../_types';

interface NestedLpcInfoInput {
  lpcInfo?: LpcInfoInput;
}

interface LpcInfoProps<TFieldValues extends NestedLpcInfoInput> {
  liveEventTypes: LiveEventTypes[];
  methods: UseFormReturn<TFieldValues>;
  pricing: Pricing;
}

/**
 * The form partial for the LPC information
 *
 * Note: This has an overload that returns null when the lpcInfo is not present
 * for a given order type to avoid typing issues in the generic OrderForm component.
 */
export function LpcInfo<TFieldValues extends NestedLpcInfoInput>(
  props: LpcInfoProps<TFieldValues>
): JSX.Element;
export function LpcInfo(props: Record<string, unknown>): null;
export function LpcInfo<TFieldValues extends NestedLpcInfoInput>({
  liveEventTypes,
  methods,
  pricing,
}: Partial<LpcInfoProps<TFieldValues>>): JSX.Element | null {
  if (!methods || !liveEventTypes || !pricing) return null;

  const {
    control,
    register,
    formState: { errors },
  } = methods;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lpcInfo.speakerNames' as ArrayPath<TFieldValues>,
  });

  return (
    <div className="mt-5 mb-5">
      <h3>Professional Event Information</h3>
      <Input
        type="number"
        label="Event duration*"
        append="min"
        error={errors?.lpcInfo?.duration}
        {...{
          ...register('lpcInfo.duration' as Path<TFieldValues>, { valueAsNumber: true }),
          min: undefined,
          max: undefined,
        }}
      />
      <Select
        label="Captioning overtime option*"
        descriptionText={`What should 3Play do if your event exceeds the estimated duration? If choosing to continue with captions, please note that the price of professional captions increases to ${pricing.lpcCustomerOvertimeProfessionalPricerate.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
          }
        )} per minute after the estimated duration is exceeded.`}
        options={
          [
            {
              id: 'Continue with professional captions',
              name: 'Continue with professional captions',
            },
            { id: 'Fallback to automatic captions', name: 'Fallback to automatic captions' },
          ] as const
        }
        defaultValue={'Continue with professional captions'}
        error={errors?.lpcInfo?.captioningOvertimeOption}
        {...{
          ...register('lpcInfo.captioningOvertimeOption' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Select
        label="Event type*"
        options={liveEventTypes.map(({ id, name }) => ({ id: id.toString(), name }))}
        error={errors?.lpcInfo?.eventType}
        {...{
          ...register('lpcInfo.eventType' as Path<TFieldValues>, { valueAsNumber: true }),
          min: undefined,
          max: undefined,
        }}
      />
      <Textarea
        label="Event description"
        descriptionText="Additional context around the event, such as time, location, place, purpose of the event."
        error={errors?.lpcInfo?.description}
        {...{
          ...register('lpcInfo.description' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Form.Group>
        <Form.Label className="mb-0">Speaker names</Form.Label>
        <Form.Text className="mt-0 mb-2" id="speaker-names-description">
          Add a list of names of people who may be speaking during the event to more accurately
          caption speaker labels.
        </Form.Text>
        <ul className="list-unstyled">
          {fields.map((item, index) => (
            <li key={item.id}>
              <Form.Row className="mb-3">
                <Col className="flex-grow-1 flex-shrink-1">
                  <Form.Label
                    htmlFor={`speaker-name-${index}`}
                    srOnly
                  >{`Speaker Name ${index}`}</Form.Label>
                  <Form.Control
                    id={`speaker-name-${index}`}
                    {...{
                      ...register(`lpcInfo.speakerNames.${index}.name` as Path<TFieldValues>),
                      min: undefined,
                      max: undefined,
                    }}
                  />
                </Col>
                <Col sm="auto">
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              </Form.Row>
            </li>
          ))}
        </ul>
      </Form.Group>
      <Button
        type="button"
        variant="secondary"
        onClick={() => {
          append({ name: '' } as unknown as Partial<
            UnpackNestedValue<FieldArray<TFieldValues, ArrayPath<TFieldValues>>>
          >);
        }}
      >
        Add Speaker Name
      </Button>
    </div>
  );
}
