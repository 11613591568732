import moment from 'moment-timezone';
import {
  CAPTION_START_TIME_OPTIONS,
  CAPTIONER_START_TIME_STREAMLESS_EVENT_OPTIONS,
  RECOMMENDED_CAPTION_START_TIME,
  RECOMMENDED_CAPTIONER_START_TIME_STREAMLESS_EVENT,
} from './constants';

export const getAvailableCaptionerStartTimeOptions = (eventStartTime, userTimezone) => {
  return getAvailableStartTimeOptions(
    eventStartTime,
    userTimezone,
    CAPTIONER_START_TIME_STREAMLESS_EVENT_OPTIONS
  );
};

export const getAvailableStreamStartTimeOptions = (
  eventStartTime,
  userTimezone,
  max_stream_wait_time = 30
) => {
  const start_time_options_keys = Object.entries(CAPTION_START_TIME_OPTIONS);
  const start_time_options = {};
  start_time_options_keys.forEach(([key, value]) => {
    if (parseInt(key) < max_stream_wait_time - 5) {
      start_time_options[key] = value;
    }
  });
  return getAvailableStartTimeOptions(eventStartTime, userTimezone, start_time_options);
};

const getAvailableStartTimeOptions = (eventStartTime, userTimezone, start_time_options) => {
  const availableCaptionStartTimes = {};
  Object.entries(start_time_options).map(([key, value]) => {
    const computedCaptionTime = moment(eventStartTime)
      .tz(userTimezone, true)
      .subtract(key, 'minutes');
    if (computedCaptionTime.isSameOrAfter(moment().tz(userTimezone))) {
      availableCaptionStartTimes[key] = value;
    }
  });
  return availableCaptionStartTimes;
};

export const getDefaultCaptionerStartTime = (availableCaptionStartTimes) => {
  return getDefaultStartTime(
    availableCaptionStartTimes,
    RECOMMENDED_CAPTIONER_START_TIME_STREAMLESS_EVENT
  );
};

export const getDefaultCaptionStartTime = (availableCaptionStartTimes) => {
  return getDefaultStartTime(availableCaptionStartTimes, RECOMMENDED_CAPTION_START_TIME);
};

const getDefaultStartTime = (availableCaptionStartTimes, RECOMMENDED_START_TIME) => {
  const availableTimes = Object.keys(availableCaptionStartTimes);
  return availableTimes.includes(RECOMMENDED_START_TIME)
    ? Number(RECOMMENDED_CAPTION_START_TIME)
    : Number(availableTimes[availableTimes.length - 1]);
};
