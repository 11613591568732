import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import AlertMessage from '~/components/app/common/AlertMessage';

import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';
import WithIcon from '~/components/app/common/WithIcon';

import { threeplayApi } from '~/logic/ThreeplayApi';
import LiveEventContactModal from '../common/LiveEventContactModal';

const updateEventContactMutation = `
mutation updateEventContactMutation($eventId: ID!, $liveEventContact: LiveEventContactInput!) {
  updateEventContactMutation(eventId: $eventId, liveEventContact: $liveEventContact) {
    error,
    success
  }
}`;

const EventContact = ({ event, initialContactInfo }) => {
  const { id } = event;
  console.log(initialContactInfo);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [show, setShow] = useState(false);
  const [eventContact, setEventContact] = useState(initialContactInfo);

  const closeModal = () => setShow(false);

  const updateEventContact = async () => {
    const response = await threeplayApi.request(updateEventContactMutation, {
      eventId: id,
      liveEventContact: eventContact,
    });

    if (response.errors) {
      setError(response.errors[0].message);
      setTimeout(() => setError(null), 5000);
    } else if (response.data.updateEventContactMutation.error) {
      setError(response.data.updateEventContactMutation.error);
      setTimeout(() => setError(null), 5000);
    } else {
      setSuccess(response.data.updateEventContactMutation.success);
      setTimeout(() => setSuccess(null), 5000);
    }
    closeModal();
  };

  return (
    <>
      <Button onClick={() => setShow(true)} size="sm" variant="link outline-primary">
        <WithIcon icon="fa fa-plus">Event Contact</WithIcon>
      </Button>
      {show && (
        <ModalErrorBoundary component="LiveEventContactModal">
          <LiveEventContactModal
            show={show}
            setShow={setShow}
            onSave={updateEventContact}
            onChange={(contactInfo) => {
              setEventContact(contactInfo);
            }}
            contactInfo={eventContact}
            buttonText={'Update'}
          />
        </ModalErrorBoundary>
      )}
      {error && <AlertMessage message={error} variant="danger" />}
      {success && <AlertMessage message={success} variant="success" />}
    </>
  );
};

EventContact.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    editable: PropTypes.bool,
    editableNote: PropTypes.string,
  }),
  initialContactInfo: PropTypes.object,
};

export default EventContact;
