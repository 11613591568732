import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import LiveEventContactModal from './../common/LiveEventContactModal';

import moment from 'moment-timezone';

import {
  HAL_EVENT_FLAT_FEE,
  HAL_EVENT_PER_MIN_RATE,
  HAL_EVENT_THRESHOLD,
} from '../common/constants';
import { eventShape } from '../common/shapes';
import {
  getDurationFromHoursAndMinutes,
  getHoursAndMinutesFromDuration,
  numberToCurrency,
} from '~/helpers/numbers';
import { liveEventTypesQuery } from '../data/queries';
import { threeplayApi } from '~/logic/ThreeplayApi';

import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';
import TipPanel from '~/components/app/common/TipPanel';
import WithIcon from '~/components/app/common/WithIcon';

function LiveEventDetails(props) {
  const [duration, setDuration] = useState({ hours: '', minutes: '' });
  const [eventTypes, setEventTypes] = useState([]);
  const [showEventContactModalState, setShowEventContactModalState] = useState(false);
  const [eventContactState, setEventContactState] = React.useState({
    name: '',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    const duration = props.event.professionalCaptioningSettings.duration;
    const { eventHours, eventMinutes } = getHoursAndMinutesFromDuration(duration);
    setDuration({ hours: eventHours, minutes: eventMinutes });
  }, [props.event.professionalCaptioningSettings]);

  useEffect(() => {
    getLiveEventTypes();
  }, []);

  function getLiveEventTypes() {
    threeplayApi.request(liveEventTypesQuery).then((res) => {
      const data = res.data || {};
      if (data.liveEventTypes) {
        setEventTypes(data.liveEventTypes);
      }
    });
  }

  function computeEventCost() {
    const cost = HAL_EVENT_PER_MIN_RATE * props.event.professionalCaptioningSettings.duration;
    return numberToCurrency.format(cost + HAL_EVENT_FLAT_FEE);
  }

  function computeCaptionerArrivalTime() {
    return moment(props.event.eventStartTime)
      .tz(props.userTimeZone, true)
      .subtract(20, 'minutes')
      .format('h:mm a');
  }

  function handleLiveEventContactSave() {
    props.eventsDispatch({
      type: 'setLiveEventContactInformation',
      value: eventContactState,
      events: [props.event],
      id: props.event.id,
    });
  }

  function validateAndUpdate(value, type) {
    // Reset value in case of non-numerical values
    if (isNaN(value)) {
      value = 0;
    }

    let { hours, minutes } = duration;
    switch (type) {
      case 'hours':
        hours = value;
        break;
      case 'minutes':
        minutes = value;
        break;
    }
    const eventDuration = getDurationFromHoursAndMinutes(hours, minutes);
    props.updateProfessionalEventSettings(props.event.id, 'duration', eventDuration);
  }

  function shouldDisableCaptionerOvertimeOptions() {
    return !!props.event.languageId && props.event.languageId !== 1;
  }

  return (
    <>
      <div>
        <h5 className="mb-0">
          <strong>{props.getEventDetailsTitleText(props.event)}</strong>
          <Button
            onClick={() => props.openEventSettingsModal(props.event)}
            size="sm"
            variant="link outline-primary"
          >
            <WithIcon icon="fa fa-cog">Edit Advanced Settings</WithIcon>
          </Button>
        </h5>
        <ul className="text-muted">
          <li>
            This event is scheduled for <strong>{props.event.eventStartTime}</strong>
          </li>
          <li>
            Captions will start at <strong>{props.event.eventStartTime}</strong>
          </li>
          <li>
            Our captioner will show up at <strong>{computeCaptionerArrivalTime()}</strong> (20
            minutes prior to event start time) to check in and confirm captions can be successfully
            delivered, contingent on 3Play being able to detect an active stream.
          </li>
        </ul>
      </div>
      <Form>
        {props.eventNameIsEditable && (
          <Row className="mt-2">
            <Col xl={4}>
              <Form.Group>
                <Form.Label>
                  <strong>Event Name</strong>
                </Form.Label>
                <Form.Control
                  aria-describedby="Event Name"
                  aria-label="eventName"
                  onChange={(e) => props.updateEventName(props.event.id, e.target.value)}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col xl={4}>
            <Form.Group>
              <Form.Label>
                <strong>Estimated Event Duration</strong>
              </Form.Label>
              <div className="d-flex flex-row">
                <InputGroup>
                  <Form.Control
                    aria-describedby="Event Duration Hours"
                    aria-label="eventDurationHours"
                    onChange={(e) => validateAndUpdate(e.target.value, 'hours')}
                    placeholder="0"
                    type="text"
                    value={duration.hours}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>hour{duration.hours === 1 ? '' : 's'}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <InputGroup className="ml-2">
                  <Form.Control
                    aria-describedby="Event Duration Minutes"
                    aria-label="eventDurationMinutes"
                    onChange={(e) => validateAndUpdate(e.target.value, 'minutes')}
                    placeholder="0"
                    type="text"
                    value={duration.minutes}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>minute{duration.minutes === 1 ? '' : 's'}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              {props.event.professionalCaptioningSettings.duration > 0 && (
                <p className="text-muted">
                  Your estimated total event cost is:{' '}
                  <strong className="text-dark">{computeEventCost()}</strong>.<br />
                  <strong>Note:</strong> This may include up to 20 minutes of captioner prep time.
                </p>
              )}
              {props.event.professionalCaptioningSettings.duration > HAL_EVENT_THRESHOLD && (
                <p className={`${css(styles.warningText)}`}>
                  <strong>Important Note:</strong>
                  Your event exceeds 3Play’s maximum captioning duration of {
                    HAL_EVENT_THRESHOLD
                  }{' '}
                  minutes. Please specify a captioning fallback option for the remainder of your
                  event.
                </p>
              )}
            </Form.Group>
          </Col>
          <Col xl={4}>
            <Form.Group>
              <Form.Label>
                <strong>Captioning Overtime Option</strong>
                <ThreePlayTooltip
                  tooltipText="What should 3Play do if your event exceeds the estimated duration?
                  If choosing to continue with captions, please note that the price of professional
                  captions increases to $3.00 per minute after the estimated duration is exceeded."
                />
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) =>
                  props.updateProfessionalEventSettings(
                    props.event.id,
                    'fallbackToAutomaticCaptions',
                    e.target.value === 'true'
                  )
                }
                disabled={shouldDisableCaptionerOvertimeOptions()}
                value={
                  shouldDisableCaptionerOvertimeOptions()
                    ? false
                    : props.event.professionalCaptioningSettings.fallbackToAutomaticCaptions
                }
              >
                <option value={false}>
                  Continue with captions (contingent on captioner availability)
                </option>
                <option value={true}>
                  {props.event.hasLiveStream
                    ? 'Fall back immediately to automatic captions'
                    : 'Stop captions at end of estimated event duration'}
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xl={4}>
            <Form.Group>
              <Form.Label>
                <strong>Event Type</strong>
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) =>
                  props.updateProfessionalEventSettings(
                    props.event.id,
                    'eventType',
                    Number(e.target.value)
                  )
                }
                value={props.event.professionalCaptioningSettings.eventType}
              >
                <option value={0}>---- Select event type ----</option>
                {eventTypes.map((et) => {
                  return (
                    <option key={et.id} value={et.id}>
                      {et.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row className="mt-2">
        <Col>
          <strong>Add Event Instructions (recommended)</strong>
          <ThreePlayTooltip tooltipText="Provide context around your event by adding general information around speakers, event purpose, and other information." />
          <br />
          <Button
            onClick={() => props.openEventInstructionsModal(props.event)}
            size="sm"
            variant="link outline-primary"
          >
            <WithIcon icon="fa fa-plus">Add Event Instructions</WithIcon>
          </Button>
        </Col>
        <Col>
          <strong>Add Wordlist (recommended)</strong>
          <ThreePlayTooltip
            tooltipText="Help our captioners more accurately caption your live event
              by providing a wordlist of commonly used terms and phrases."
          />
          <br />
          <Button
            onClick={() => props.openWordListModalForEvent(props.event)}
            size="sm"
            variant="link outline-primary"
          >
            {props.event.wordList === null ? (
              <WithIcon icon="fa fa-plus">Add Wordlist</WithIcon>
            ) : (
              <WithIcon icon="fa fa-file-text-o">Edit Wordlist</WithIcon>
            )}
          </Button>
        </Col>
        <Col>
          <strong>Add Event Contact</strong>
          <ThreePlayTooltip tooltipText="Add contact information for the person managing your live event in case our support team needs to reach out." />
          <br />
          <Button
            onClick={() => setShowEventContactModalState(!showEventContactModalState)}
            size="sm"
            variant="link outline-primary"
          >
            {!props.event.eventContactInformation ? (
              <WithIcon icon="fa fa-plus">Add Event Contact</WithIcon>
            ) : (
              <WithIcon icon="fa fa-file-text-o">Edit Contact Information</WithIcon>
            )}
          </Button>
          <LiveEventContactModal
            show={showEventContactModalState}
            setShow={setShowEventContactModalState}
            onSave={handleLiveEventContactSave}
            onChange={(contactInfo) => {
              setEventContactState(contactInfo);
            }}
            contactInfo={eventContactState}
          />
        </Col>
      </Row>
    </>
  );
}
function ProfessionalLiveCaptions(props) {
  return (
    <Row className="pt-4">
      <Col lg={{ span: 4, order: 2 }}>
        <TipPanel header="Best Practices for Professional Live Captioned Events">
          <strong>
            3Play recommends you follow the tips below to ensure more accurate captions for your
            professional live captioned events:
          </strong>
          <ul>
            <li>
              Captions for live events work best when the event contains high-quality audio and one
              person is speaking at a time (no overlapping audio)
            </li>
            <li>
              Add event instructions and a wordlist to help 3Play better understand the context
              around your event
            </li>
          </ul>
        </TipPanel>
      </Col>
      <Col lg={8}>
        <Card>
          <Card.Header>
            <strong>Professional Live Captions</strong>
          </Card.Header>
          <Alert className="mb-0" variant="warning">
            <strong>Note:</strong> Most events are successfully captioned by one of 3Play&apos;s
            captioners, but sometimes we are unable to provide a captioner for events due to
            capacity or time constraints.
          </Alert>
          <ListGroup className="list-group-flush">
            {
              // eslint-disable-next-line react/prop-types
              props.events.map((e) => {
                return (
                  <ListGroupItem key={e.id}>
                    <LiveEventDetails {...props} event={e} />
                  </ListGroupItem>
                );
              })
            }
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
}

ProfessionalLiveCaptions.prototype = {
  events: PropTypes.arrayOf(eventShape),
  eventNameIsEditable: PropTypes.bool,
  getEventDetailsTitleText: PropTypes.func,
  userTimeZone: PropTypes.string,
  updateEventName: PropTypes.func,
};

LiveEventDetails.propTypes = {
  event: eventShape,
  eventNameIsEditable: PropTypes.bool,
  getEventDetailsTitleText: PropTypes.func,
  openEventInstructionsModal: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  updateEventName: PropTypes.func,
  updateProfessionalEventSettings: PropTypes.func,
  userTimeZone: PropTypes.string,
  eventsDispatch: PropTypes.func,
};

const styles = StyleSheet.create({
  warningText: {
    color: '#8B6219',
  },
});

export default ProfessionalLiveCaptions;
