import camelCase from 'lodash/camelCase';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { Alert, Button, Card, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import Rtmp from './platforms/Rtmp';
import Streamless from './platforms/Streamless';
import Zoom from './platforms/Zoom';

import { FlipperFlagContext } from './OrderPage';
import { eventShape, liveStaticEmbedKeyShape, projectLiveSettingsShape } from '../common/shapes';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import logoPath from '~/helpers/logos';

import CommonPlatformComponent from './platforms/CommonPlatformComponent';
import WithIcon from '~/components/app/common/WithIcon';
import { LiveOrderTypeChiclet } from './LiveOrderTypeChiclet';
import { codeNameToRouteAdapter } from '../../live_events/liveEventsPaths';
import selectedStyles from '../../styles/selectedDeselectedStyles';

// Needs to be a forwardRef because the overlay trigger injects a ref.
// Linting disabled as a newer version of the linter has addressed this false positive
// eslint-disable-next-line react/display-name
const LivePlatformPopover = React.forwardRef(({ children, platform, ...popoverProps }, ref) => {
  return (
    <Popover {...popoverProps} ref={ref} id={`popover-${platform}`}>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
});

const RTMPPopover = (popoverProps) => {
  return (
    <LivePlatformPopover {...popoverProps} platform={'RTMP'}>
      <p>
        {
          "Send 3Play an RTMP stream from your video source, and we'll generate captions and deliver them to the"
        }
        <a
          href="https://support.3playmedia.com/hc/en-us/articles/360022891953-Live-Captions-Embed-Use-Customization-and-Latency-Controls"
          target="_blank"
          rel="noreferrer"
        >
          3Play Embed
        </a>
        {' & '}
        <a
          href="https://support.3playmedia.com/hc/en-us/articles/4412068790807-Live-Captioning-3Play-Second-Screen-Webpage-Display-for-Captions"
          target="_blank"
          rel="noreferrer"
        >
          Second Screen Webpage
        </a>
      </p>
      <a
        href="https://support.3playmedia.com/hc/en-us/articles/360048839533-Live-Captions-Schedule-Live-Captions-for-an-RTMP-Stream"
        target="_blank"
        rel="noreferrer"
      >
        Learn More about RTMP
      </a>
    </LivePlatformPopover>
  );
};

const StreamlessPopover = (popoverProps) => {
  return (
    <LivePlatformPopover {...popoverProps} platform={'streamless'}>
      <p>
        {
          "No need to manage a stream to 3Play. Provide access information to your event's audio (like a meeting link or dial in number) and our professional captioners will caption your event to a 3Play second screen or 3Play iframe."
        }
      </p>
      <a
        href="https://support.3playmedia.com/hc/en-us/articles/4419452456343-Live-Professional-Captioning-without-a-stream-to-3Play-BETA"
        target="_blank"
        rel="noreferrer"
      >
        Learn More About Streamless
      </a>
    </LivePlatformPopover>
  );
};

function LivePlatform({ popover, platform, platformText, selectedPlatform, setSelectedPlatform }) {
  const livePlatFormCard = (
    <Card
      aria-label={platform}
      bg="light"
      className={`${
        selectedPlatform === platform
          ? css(selectedStyles.selectedSimple)
          : css(selectedStyles.deselectedSimple)
      } ${css(styles.platformCard)} m-2 text-center overflow-hidden`}
      onClick={() => setSelectedPlatform(platform)}
    >
      <Card.Body className="d-flex align-items-center justify-content-center">
        <a href="#">
          {platformText && <p className={css(styles.platformText)}>{platformText}</p>}
          {!platformText && (
            <Card.Img className="img-fluid" src={logoPath(platform)} variant="top" />
          )}
        </a>
      </Card.Body>
    </Card>
  );

  // we delay hiding the popover because these contain links. users need enough to to click.
  return (
    <ErrorBoundary component="ScheduleNewLiveAutoCaptioningEvent">
      {popover ? (
        <OverlayTrigger overlay={popover} delay={{ hide: 1000 }}>
          {livePlatFormCard}
        </OverlayTrigger>
      ) : (
        livePlatFormCard
      )}
    </ErrorBoundary>
  );
}

function PlatformComponent(props) {
  switch (props.selectedPlatform) {
    case 'Brightcove':
    case 'Caption Conductor':
    case 'Cvent':
    case 'Facebook':
    case 'YouTube': {
      return (
        <CommonPlatformComponent
          {...props}
          integrations={props.integrations[camelCase(props.selectedPlatform)]}
          platform={props.selectedPlatform}
        />
      );
    }
    case 'RTMP': {
      return (
        <Rtmp
          defaultEvent={props.defaultEvent}
          eventsDispatch={props.eventsDispatch}
          openEventSettingsModal={props.openEventSettingsModal}
          openWordListModalForEvent={props.openWordListModalForEvent}
          projectLiveSettings={props.projectLiveSettings}
          selectedEvents={props.selectedEvents}
          setIntegrationId={props.setIntegrationId}
          userTimeZone={props.userTimeZone}
          validDate={props.validDate}
          validateStreamUrl={props.validateStreamUrl}
        />
      );
    }

    case 'Streamless': {
      return (
        <Streamless
          defaultEvent={props.defaultEvent}
          eventsDispatch={props.eventsDispatch}
          selectedEvents={props.selectedEvents}
          setIntegrationId={props.setIntegrationId}
          userTimeZone={props.userTimeZone}
        />
      );
    }
    case 'Zoom': {
      return <Zoom {...props} integrations={props.integrations.zoom} platform="Zoom" />;
    }
    default:
      return null;
  }
}

function OrderStepOne(props) {
  const { liveOrderTypes = [], newOrderPaths } = props;

  return (
    <ErrorBoundary component="ScheduleNewLiveAutoCaptioningStepOne">
      <Container className="py-2" fluid>
        <Row>
          <Col sm={12}>
            <h2>
              <strong>Which platform is your event scheduled on?</strong>
            </h2>
            <Row className="m-0">
              {props.platforms.map((platform) => {
                return (
                  <LivePlatform
                    key={platform}
                    platform={platform}
                    selectedEvents={props.selectedEvents}
                    selectedPlatform={props.selectedPlatform}
                    setSelectedEvents={props.setSelectedEvents}
                    setSelectedPlatform={props.setSelectedPlatform}
                  />
                );
              })}
              <LivePlatform
                platform="RTMP"
                popover={RTMPPopover}
                selectedEvents={props.selectedEvents}
                setSelectedEvents={props.setSelectedEvents}
                selectedPlatform={props.selectedPlatform}
                setSelectedPlatform={props.setSelectedPlatform}
              />

              <LivePlatform
                platform="Streamless"
                popover={StreamlessPopover}
                platformText="I want to provide audio another way (e.g. meeting link or phone number)"
                selectedEvents={props.selectedEvents}
                setSelectedEvents={props.setSelectedEvents}
                selectedPlatform={props.selectedPlatform}
                setSelectedPlatform={props.setSelectedPlatform}
              />

              {liveOrderTypes.map((orderType) => {
                return (
                  <LiveOrderTypeChiclet
                    key={orderType.id}
                    href={
                      newOrderPaths.virtual_encoding ||
                      codeNameToRouteAdapter[orderType.codeName].route
                    }
                    label={orderType.displayName}
                    beta={orderType.beta}
                  />
                );
              })}
            </Row>
            {props.error && (
              <Alert className="w-25" variant="danger">
                <Alert.Heading>
                  <WithIcon icon="fa fa-exclamation-triangle">Connection Failure</WithIcon>
                </Alert.Heading>
                Sorry, we’re having some trouble retrieving a list of events for your{' '}
                <b>{props.selectedPlatform}</b> account. Try refreshing the page or try again later
                if the problem persists.
              </Alert>
            )}
            {!props.error && (
              <>
                <PlatformComponent {...props} />
                <Button
                  className="d-flex mt-2"
                  disabled={props.disableOrderButton()}
                  onClick={() => props.setOrderStep(2)}
                  variant="primary"
                >
                  Next Step
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

const styles = StyleSheet.create({
  platformCard: { maxWidth: '250px', minHeight: '130px' },
  platformText: {
    color: 'rgba(0, 0, 0, 0.6)',
    ':hover': {
      textDecoration: 'none',
    },
  },
});

OrderStepOne.propTypes = {
  defaultEvent: PropTypes.object,
  disableOrderButton: PropTypes.func,
  error: PropTypes.bool,
  findEvent: PropTypes.func,
  eventsDispatch: PropTypes.func,
  liveOrderTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      beta: PropTypes.bool,
      codeName: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  newOrderPaths: PropTypes.object,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  orders: PropTypes.object,
  platforms: PropTypes.arrayOf(PropTypes.string),
  projectLiveSettings: projectLiveSettingsShape,
  scheduleLiveEvents: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(eventShape),
  selectedPlatform: PropTypes.string,
  setIntegrationId: PropTypes.func,
  setOrderStep: PropTypes.func,
  setSelectedEvents: PropTypes.func,
  setSelectedPlatform: PropTypes.func,
  setUserEmail: PropTypes.func,
  setWordList: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateStreamUrl: PropTypes.func,
  validDate: PropTypes.func,
  wordList: PropTypes.object,
};

LivePlatform.propTypes = {
  platform: PropTypes.string.isRequired,
  platformText: PropTypes.string,
  popover: PropTypes.node,
  selectedPlatform: PropTypes.string,
  setSelectedPlatform: PropTypes.func,
};

LivePlatformPopover.propTypes = {
  children: PropTypes.node,
  platform: PropTypes.string.isRequired,
};

PlatformComponent.propTypes = {
  defaultEvent: PropTypes.object,
  findEvent: PropTypes.func,
  getStreamDetailsForBatch: PropTypes.func,
  integrations: PropTypes.shape({
    brightcove: PropTypes.arrayOf(PropTypes.object),
    captionConductor: PropTypes.arrayOf(PropTypes.object),
    youTube: PropTypes.arrayOf(PropTypes.object),
    zoom: PropTypes.arrayOf(PropTypes.object),
  }),
  eventsDispatch: PropTypes.func,
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  projectLiveSettings: projectLiveSettingsShape,
  selectedPlatform: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(eventShape),
  setIntegrationId: PropTypes.func,
  setSelectedEvents: PropTypes.func,
  setUserEmail: PropTypes.func,
  updateSelectedEvents: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateStreamUrl: PropTypes.func,
  validDate: PropTypes.func,
};

export default OrderStepOne;
