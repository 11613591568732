const ensembleLibrariesQuery = `
  query EnsembleLibraries($videoPlatformIntegrationIds: [ID!]){
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          ensembleLibraries {
            ensembleId,
            name
          }
        }
      }
    }
  }
`;

const eventListQuery = `
  query LiveEventsList($videoPlatformIntegrationIds: [ID!], $eventType: String, $paginationParams: PaginationInput, $userEmail: String){
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
      integrations {
          liveEvents(eventType: $eventType, paginationParams: $paginationParams, userEmail: $userEmail) {
            id,
            eventTimePresent,
            externalId,
            name,
            jobType,
            previouslyOrdered,
            eventStartTime,
            videoId
          }
          paginationDetails {
            nextPage,
            prevPage,
            pageAfter,
            pageBefore,
            showFirstPageButton,
            totalPages,
            totalVideos,
            type
          }
        }
      }
    }
  }
`;

const integrationDetails = `
  query LinkedAccountsDetails($currentPage: Int!,
                       $pageAfter: String!,
                       $pageBefore: String!,
                       $pageToken: String!,
                       $pageTokenList: [String!],
                       $searchParams: SearchParamsInput!,
                       $searchTerm: String!,
                       $sortParams: SortInput!,
                       $videosPerPage: Int!,
                       $videoPlatformIntegrationIds: [ID!]){
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          id,
          displayName,
          platformName,
          playlistId,
          videos(currentPage: $currentPage,
                pageAfter: $pageAfter,
                pageBefore: $pageBefore,
                pageToken: $pageToken,
                pageTokenList: $pageTokenList,
                searchParams: $searchParams,
                searchTerm: $searchTerm,
                sortParams: $sortParams,
                videosPerPage:$ videosPerPage) {
            id,
            createdAt,
            duration,
            name,
            state,
            thumbnailUrl,
            tooltip
        },
        error,
        paginationDetails {
          nextPage,
          pageAfter,
          pageBefore,
          pageTokenList,
          prevPage,
          showFirstPageButton,
          type,
          totalPages
        }
        headerDetails {
          displayName,
          name,
          sortDirection,
          sortableField
        }
      }
    }
  }
}`;

const integrationVideoCount = `
  query LinkedAccountsIntegrationDetails($searchParams: SearchParamsInput!, $videoPlatformIntegrationIds: [ID!]) {
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          id,
          displayName,
          videoCount(searchParams: $searchParams),
          error,
        }
      }
    }
  }
`;

const integrationPlatformsQuery = `query Platforms($livePlatformsOnly: Boolean!){
  integrationPlatforms(livePlatformsOnly: $livePlatformsOnly){
    id,
    name,
    popularPlatform
  }
}`;

const linkedAccountsQuery = `query LinkedAccounts($livePlatformsOnly: Boolean!){
  project {
    enabledFeatures,
    linkedAccounts(livePlatformsOnly: $livePlatformsOnly){
      platformDetails{
        id,
        name,
        supportsSearching,
        supportsVideoListing
      }
      integrations {
        id,
        displayName,
        createdAt,
        vpiMonthlyReports {
          captionedCount,
          captionedDuration,
          month,
          updatedAt
          videoCount,
          videoDuration
        }
      }
    }
  }
}`;

const resendZoomApiTokenEmailQuery = `query ResendZoomApiTokenEmail($liveEventId: ID!, $videoPlatformIntegrationIds: [ID!]) {
  project {
    linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
      integrations {
        resendZoomApiEmail(liveEventId: $liveEventId) {
          error,
          success
        }
      }
    }
  }
}`;

const youTubeIntegrationsDetails = `
  query LinkedAccountsIntegrationDetails($playlistData: Boolean!, $videoPlatformIntegrationIds: [ID!]) {
    project {
      youtubeLinkedAccounts(playlistData: $playlistData, videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          id,
          displayName,
          videoCount,
          paginationDetails {
            totalVideos
          }
        }
      }
    }
  }
`;

const zoomUsersQuery = `
  query ZoomUsersQuery($videoPlatformIntegrationIds: [ID!]){
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          zoomUserDetails {
            defaultUser
            type
            userList
          }
        }
      }
    }
  }
`;

const zoomUserExistsQuery = `
  query ZoomUserExists($userEmail: String!, $videoPlatformIntegrationIds: [ID!]){
    project {
      linkedAccounts(videoPlatformIntegrationIds: $videoPlatformIntegrationIds) {
        integrations {
          zoomUserExists(userEmail: $userEmail)
        }
      }
    }
  }
`;

export {
  ensembleLibrariesQuery,
  eventListQuery,
  integrationDetails,
  integrationPlatformsQuery,
  integrationVideoCount,
  linkedAccountsQuery,
  resendZoomApiTokenEmailQuery,
  youTubeIntegrationsDetails,
  zoomUserExistsQuery,
  zoomUsersQuery,
};
