import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';

import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';

function SaveAudioModal(props) {
  const [uploadProgress, setUploadProgress] = useState(null);
  const [fileUploadValidated, setFileUploadValidated] = useState(false);
  const [fileUploadFailed, setFileUploadFailed] = useState(false);

  useEffect(() => {
    if (props.show) {
      props.audioFile.saveSpokenAudio(
        props.swateiJobId,
        true,
        setUploadProgress,
        setFileUploadValidated,
        setFileUploadFailed
      );
    }
  }, [props.show]);

  useEffect(() => {
    if (fileUploadValidated || fileUploadFailed) {
      setTimeout(() => {
        redirectHome();
      }, 1000);
    }
  }, [fileUploadValidated, fileUploadFailed]);

  function redirectHome() {
    window.location = window.origin;
  }

  function SaveProgressBar() {
    const uploadComplete = uploadProgress === 100;
    if (uploadProgress === -1) {
      return (
        <span className="text-danger">
          <i className="fa fa-exclamation-triangle pr-2"></i>
          There was an error during the upload process. You can close this window if it does not
          close automatically.
        </span>
      );
    } else if (uploadComplete) {
      if (fileUploadValidated === true) {
        return (
          <div>
            <ProgressBar variant="success" now={uploadProgress} striped animated />
            <span className="text-success">
              <i className="fa fa-check pr-2"></i>
              Save successful! You can close this window if it does not close automatically.
            </span>
          </div>
        );
      } else if (fileUploadValidated === false) {
        return (
          <span className={css(styles.textProcessing)}>
            <i className="fa fa-spinner fa-fw pr-2"></i>
            Data processing...
          </span>
        );
      }
    } else {
      return <ProgressBar variant="success" now={uploadProgress} striped animated />;
    }
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Uploading Job Data...</Modal.Title>
      </Modal.Header>
      <Modal.Body>{SaveProgressBar()}</Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  bold: {
    'font-weight': 'bold',
  },
  durationText: {
    width: '80%',
    color: '#8A6D3B',
    'font-size': '0.8rem',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  textProcessing: {
    color: '#976C00',
  },
  wordlistLink: {
    color: '#007EB5',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

SaveAudioModal.propTypes = {
  audioFile: PropTypes.object,
  show: PropTypes.bool.isRequired,
  swateiJobId: PropTypes.number.isRequired,
};

export default SaveAudioModal;
