import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Path, UseFormReturn } from 'react-hook-form';

import { Input, Select } from '@threeplayground/index';

import { FeatureFlagContext } from '../../FeatureFlagProvider';
import { DynamicSchema, InputInfoInput, Transcoder } from '../../_types';

interface NestedInputInfoInput {
  inputInfo: InputInfoInput;
}

const STREAM_START_TIME_OPTIONS = [
  { id: 0, name: 'At Event Start Time' },
  { id: 5, name: '5 Minutes Before' },
  { id: 10, name: '10 Minutes Before' },
  { id: 15, name: '15 Minutes Before' },
  { id: 20, name: '20 Minutes Before' },
];

interface InputInfoProps<TFieldValues extends NestedInputInfoInput> {
  dynamicSchema: DynamicSchema['inputInfo'];
  lpcEvent: boolean;
  methods: UseFormReturn<TFieldValues>;
  transcoders: Transcoder[];
}

/**
 * The form partial for the input information
 *
 * Note: This has an overload that returns null when the inputInfo is not present
 * for a given order type to avoid typing issues in the generic OrderForm component.
 */
export function InputInfo<TFieldValues extends NestedInputInfoInput>(
  props: InputInfoProps<TFieldValues>
): JSX.Element;
export function InputInfo(props: Record<string, unknown>): null;
export function InputInfo<TFieldValues extends NestedInputInfoInput>({
  dynamicSchema,
  lpcEvent,
  methods,
  transcoders,
}: Partial<InputInfoProps<TFieldValues>>): JSX.Element | null {
  const featureFlags = useContext(FeatureFlagContext);

  if (!methods || !transcoders) return null;

  const {
    register,
    formState: { errors },
  } = methods;
  return (
    <div className="mt-5 mb-5">
      <h3>Input Configuration</h3>
      {lpcEvent && (
        <Select
          label="Stream start time*"
          descriptionText="The amount of time before the event start time that 3Play will listen for an active stream. Our captioners will check in 20 minutes prior to the event start time to prep for the event and ensure captions show up at your specified event start time."
          options={STREAM_START_TIME_OPTIONS.map(({ id, name }) => ({ id: id.toString(), name }))}
          error={errors?.inputInfo?.streamStartTime}
          {...{
            ...register('inputInfo.streamStartTime' as Path<TFieldValues>, { valueAsNumber: true }),
            min: undefined,
            max: undefined,
          }}
        />
      )}
      {!lpcEvent && (
        <Input
          type="number"
          label="Stream wait time*"
          descriptionText="The amount of time 3Play will wait for audio after the stream start time. Costs will be calculated from the stream wait time and the service will end if no audio is detected by the conclusion of the stream wait time."
          append="min"
          error={errors?.inputInfo?.streamWaitTime}
          {...{
            ...register('inputInfo.streamWaitTime' as Path<TFieldValues>, { valueAsNumber: true }),
            min: undefined,
            max: undefined,
          }}
        />
      )}
      <Input
        type="number"
        label="Max stream time*"
        descriptionText="The maximum time for your live captioning event. Maximum time will be calculated from Event Start Time, and will not include any delays or breaks. When the max time is reached, captioning service will stop even if the event is still active. Events in your project can be no longer than your Event Max Time Config."
        append="min"
        error={errors?.inputInfo?.maxStreamTime}
        {...{
          ...register('inputInfo.maxStreamTime' as Path<TFieldValues>, { valueAsNumber: true }),
          min: undefined,
          max: undefined,
        }}
        max={dynamicSchema?.maxStreamTime?.maximum?.toString()}
      />
      <Input
        type="number"
        label="Stream reconnection wait time*"
        descriptionText="If your stream is disconnected from 3Play, specify how long 3Play should wait before ending your event. After this duration, you will no longer be able to reconnect to this event, and will need to schedule a new event in 3Play to continue captions."
        append="min"
        error={errors?.inputInfo?.streamReconnectionWaitTime}
        {...{
          ...register('inputInfo.streamReconnectionWaitTime' as Path<TFieldValues>, {
            valueAsNumber: true,
          }),
          min: undefined,
          max: undefined,
        }}
        max={dynamicSchema?.streamReconnectionWaitTime?.maximum?.toString()}
      />
      {featureFlags.liveSaveStreamEnabled && (
        <Form.Check
          id="inputInfo.saveEventStream"
          label="Save Event Stream"
          type="checkbox"
          {...register('inputInfo.saveEventStream' as Path<TFieldValues>)}
        />
      )}
    </div>
  );
}
