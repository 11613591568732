import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import ManagePlanWizard from './ManagePlanWizard';
import LoadingOverlay from '~/components/app/common/LoadingOverlay';
import { MANAGE_PLAN_MODAL_CLOSE_BUTTON_TAB_INDEX } from '~/helpers/constants';

function ManagePlanModal(props) {
  const [submitting, setSubmitting] = useState(false);

  function handleCreatePlanSuccess() {
    props.closeManagePlanModal();
  }

  function handleCreatePlanErrors() {
    //display error message
  }

  return (
    <Modal
      dialogClassName="modal-width-medium"
      onHide={props.closeManagePlanModal}
      role="dialog"
      backdrop="static"
      show={props.show}
    >
      {submitting && <LoadingOverlay message="Subscribing To Pro..." />}
      <Modal.Header>
        <Modal.Title>Manage Plan</Modal.Title>
        <button
          className="close"
          onClick={props.closeManagePlanModal}
          tabIndex={MANAGE_PLAN_MODAL_CLOSE_BUTTON_TAB_INDEX}
        >
          x
        </button>
      </Modal.Header>
      <ManagePlanWizard
        {...props}
        setSubmitting={setSubmitting}
        submitting={submitting}
        handleCreatePlanErrors={handleCreatePlanErrors}
        handleCreatePlanSuccess={handleCreatePlanSuccess}
      />
    </Modal>
  );
}

ManagePlanModal.propTypes = {
  authenticityToken: PropTypes.string,
  clientToken: PropTypes.string,
  closeManagePlanModal: PropTypes.func,
  defaultPrice: PropTypes.number,
  hostedForm: PropTypes.bool,
  introPrice: PropTypes.number,
  show: PropTypes.bool,
  siteKey: PropTypes.string,
  termsDocument: PropTypes.object,
};

export default ManagePlanModal;
