import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { virtualEncodingOrderInput } from '~/schemas/live/orders/virtualEncodingOrderInput';
import { RootProps } from '../../_types';
import OrderForm from '../shared/OrderForm';
import { Alert } from '@threeplayground/index';
import { TermsAcceptance } from '@threeplayground/unstable';

const VIRTUAL_ENCODING_ORDER_TYPE_CODE_NAME = 'virtual_encoding';

const Error = () => (
  <Alert variant="danger">Something went wrong. Reload the page and try again.</Alert>
);

export default function VirtualEncodingOrderForm(props: RootProps) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const virtualEncodingOrderType = props.liveOrderTypes.find(
    (orderType) => orderType.codeName === VIRTUAL_ENCODING_ORDER_TYPE_CODE_NAME
  );
  const handleError = () => {
    setError(true);
  };
  const handleSuccess = () => {
    setError(false);
    setSuccess(true);
    window.location.reload();
  };

  if (!virtualEncodingOrderType) {
    return <Error />;
  }

  // The data doesn't allow us to get here, but this case keeps Typescript happy
  if (virtualEncodingOrderType.mustAcceptTerms && !virtualEncodingOrderType.termVersionType) {
    return <Error />;
  }

  if (virtualEncodingOrderType.mustAcceptTerms && virtualEncodingOrderType.termVersionType) {
    return (
      <Modal.Dialog>
        <Modal.Body>
          <TermsAcceptance
            versionType={virtualEncodingOrderType.termVersionType}
            userType="Client"
            termName="Virtual Encoding Beta Terms"
            onLoadError={handleError}
            onSubmissionError={handleError}
            onSubmissionSuccess={handleSuccess}
          />
          <div className="mt-3">
            {error && <Error />}
            {success && <Alert variant="success">Terms successfully acknowledged!</Alert>}
          </div>
        </Modal.Body>
      </Modal.Dialog>
    );
  }

  return (
    <OrderForm
      {...props}
      orderType={VIRTUAL_ENCODING_ORDER_TYPE_CODE_NAME}
      schema={virtualEncodingOrderInput}
      serviceTypeOptions={['Professional', 'Automatic']}
    />
  );
}
