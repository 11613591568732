import React from 'react';

import InfoPopover from '~/components/app/common/InfoPopover';
import { translationProfilesPath } from '~/helpers/app/paths';

const ServiceTypeInfo = () => (
  <InfoPopover
    title="3Play’s Live Service Types"
    link_text="Learn More"
    link_url={translationProfilesPath}
  >
    <p>
      <b>1. Professional live captioning:</b> Get matched to a highly-trained captioner for your
      event to ensure more accurate captions.
    </p>
    <p>
      <b>2. Automatic live captions:</b> Rely on 3Play’s proprietary technology to automatically
      generate captions (accuracy not guaranteed).
    </p>
  </InfoPopover>
);

export default ServiceTypeInfo;
