import React from 'react';
import { Card } from 'react-bootstrap';
import { StyleSheet, css } from 'aphrodite';
import selectedStyles from '../../styles/selectedDeselectedStyles';

interface LiveOrderTypeChicletProps {
  href: string;
  label: string;
  beta: boolean;
}

export function LiveOrderTypeChiclet({ href, label, beta }: LiveOrderTypeChicletProps) {
  return (
    <a href={href}>
      <Card
        aria-label={label}
        bg="light"
        className={`${css(selectedStyles.deselectedSimple)} ${css(
          styles.platformCard
        )} m-2 text-center overflow-hidden`}
      >
        <Card.Body className="d-flex align-items-center justify-content-center">
          <h4 className={css(styles.platformText)}>{label}</h4>
          {beta && <span className="badge badge-danger">Beta</span>}
        </Card.Body>
      </Card>
    </a>
  );
}

const styles = StyleSheet.create({
  platformCard: { maxWidth: '250px', minHeight: '130px' },
  platformText: {
    color: 'rgba(0, 0, 0, 0.6)',
    ':hover': {
      textDecoration: 'none',
    },
  },
});
