import React, { createContext, ReactNode } from 'react';

const initialContext: Record<string, boolean> = {};

export const FeatureFlagContext = createContext(initialContext);

interface FeatureFlagProviderProps {
  children: ReactNode;
  featureFlags: Record<string, boolean>;
}

export function FeatureFlagProvider({ children, featureFlags }: FeatureFlagProviderProps) {
  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
}
