import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const StartTimeOptions = ({ availableStartTimes, eventId, startTime, setStartTime }) => {
  return (
    <Form.Control
      as="select"
      value={startTime}
      onChange={(e) => setStartTime(eventId, Number(e.target.value))}
      size="sm"
    >
      {Object.entries(availableStartTimes).map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        );
      })}
    </Form.Control>
  );
};

StartTimeOptions.propTypes = {
  availableStartTimes: PropTypes.object,
  eventId: PropTypes.string,
  startTime: PropTypes.number,
  setStartTime: PropTypes.func,
};

export default StartTimeOptions;
