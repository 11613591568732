import { z } from 'zod';
import { baseInfoInput } from './shared/baseInfoInput';
import { captionConfigInput } from './shared/captionConfigInput';
import { contactInfoInput } from './shared/contactInfoInput';
import { inputInfoInput } from './shared/inputInfoInput';
import { lpcInfoInput } from './shared/lpcInfoInput';
import { outputInfoInput } from './shared/outputInfoInput';

const isRecordStringUnknown = (x: unknown): x is Record<string, unknown> =>
  typeof x === 'object' && x !== null && Object.keys(x).every((key) => typeof key === 'string');

export const virtualEncodingOrderInput = z.preprocess(
  (data) => {
    if (!isRecordStringUnknown(data)) return data;

    let updatedData: Record<string, unknown> = data;

    // Remove the contact info if no values are provided
    if (
      'contactInfo' in updatedData &&
      isRecordStringUnknown(updatedData.contactInfo) &&
      Object.values(updatedData.contactInfo).every((val) => !val)
    ) {
      updatedData = { ...updatedData, contactInfo: undefined };
    }

    // Remove the lpcInfo if it is not a lpc order
    if (
      'baseInfo' in updatedData &&
      isRecordStringUnknown(updatedData.baseInfo) &&
      updatedData.baseInfo.serviceType !== 'Professional'
    ) {
      updatedData = { ...updatedData, lpcInfo: undefined };
    }

    return updatedData;
  },
  z
    .object({
      baseInfo: baseInfoInput,
      lpcInfo: lpcInfoInput.optional(),
      inputInfo: inputInfoInput,
      outputInfo: outputInfoInput,
      captionConfig: captionConfigInput,
      contactInfo: contactInfoInput.optional(),
    })
    .describe('Virtual encoding order input')
);
