import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useParams } from 'react-router-dom';
import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';

import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { JobLoadError } from '../../common/JobLoadError';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { OffPlatformJobDetails } from '../../common/OffPlatformJobDetails';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';

type ParamType = {
  id: string;
};

export function OffPlatformVideoDescriptionTextQualityControlJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, error } = useJobQuery({ where: { idEq: id } });

  if (error) return <JobLoadError />;
  if (isLoading) return null;

  const job = data?.job;

  if (!job || job.__typename !== 'OffPlatformVideoDescriptionTextQualityControlJobGraphql') {
    return <JobLoadError />;
  }

  const { mediaFile, orderInstructions, qualityControlledScript, service } = job;

  if (!service || service.__typename !== 'AudioDescriptionServiceGraphql') return <JobLoadError />;

  const getAssets = (): DownloadableAsset[] => {
    const sourceAsset = {
      displayName: 'Source Video',
      url: mediaFile.source.presignedDownloadUrl,
    };

    const files = qualityControlledScript.inputVideoDescriptionScript.files;
    if (files != null && files.length != 0 && files[0] != undefined) {
      return [
        sourceAsset,
        {
          displayName: 'Video Description Script',
          url: files[0].presignedDownloadUrl,
        },
      ];
    }
    return [sourceAsset];
  };

  return (
    <OffPlatformJobLayout
      jobId={id}
      jobTypeName={'Off Platform Video Description Text Quality Control Job'}
    >
      {service.includeVaadOnPlatformMix && (
        <Row>
          <Alert variant="warning">
            The file should be set to a 0 start time as these files will be mixed on-platform from
            the start of the video.
          </Alert>
        </Row>
      )}
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails job={job} downloadableAssets={getAssets()} />
          <UploadedFileList files={qualityControlledScript.files} />
          <FileUploader
            assetId={qualityControlledScript.id}
            assetType={AssetClassNameEnum.TextQualityControlScript}
            allowedFileFormats={qualityControlledScript.allowedFileFormats}
            uploadedFiles={qualityControlledScript.files}
          />
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
