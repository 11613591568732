import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import { Card } from '@threeplayground/unstable';

import { useJobQuery, AssetClassNameEnum } from '~/api/jobsApi';
import { DownloadableAsset } from '../../common/downloadable_asset_cell/OffPlatformDownloadableAssetCell';
import { FileUploader } from '../../common/file_uploads/FileUploader';
import { JobInstructions } from '../../common/JobInstructions';
import { JobLoadError } from '../../common/JobLoadError';
import { AssetInstructions } from '../../common/AssetInstructions';
import { MediaShuttleUploader } from '../../common/audio_assets/MediaShuttleUploader';
import { OffPlatformJobDetails, JobDetailsRow } from '../../common/OffPlatformJobDetails';
import { OffPlatformJobLayout } from '../../common/OffPlatformJobLayout';
import { UploadedFileList } from '../../common/file_uploads/UploadedFileList';

type ParamType = {
  id: string;
};

export function OffPlatformVoiceOverQualityControlJob() {
  const { id } = useParams<keyof ParamType>() as ParamType;
  const { data, isLoading, isError } = useJobQuery({ where: { idEq: id } });

  if (isError) return <JobLoadError />;
  if (isLoading) return null;

  if (!data.job || data.job.__typename !== 'OffPlatformVoiceOverQualityControlJobGraphql')
    return <JobLoadError />;

  const job = data.job;
  const {
    mediaFile,
    orderInstructions,
    qcedVoiceOverScript: script,
    qcedVoiceOverDescriptionAudio: qcAudio,
    service,
    upstreamJob,
    voiceOverDescriptionAudio: audio,
  } = job;

  if (
    !service ||
    (service.__typename !== 'VoiceOverAudioDescriptionServiceGraphql' &&
      service.__typename !== 'AudioDescriptionReformatServiceGraphql' &&
      service.__typename !== 'AudioDescriptionRevisionServiceGraphql')
  ) {
    return <JobLoadError />;
  }

  const includeOnPlatformMix = service.includeOnPlatformMix;

  if (upstreamJob && upstreamJob?.__typename !== 'OffPlatformVoiceOverJobGraphql')
    return <JobLoadError />;

  const getAssets = (): DownloadableAsset[] => {
    const sourceAsset = {
      displayName: 'Source Video',
      url: mediaFile.source.presignedDownloadUrl,
    };

    const files = script.inputVideoDescriptionScript?.files;
    if (files != null && files.length != 0 && files[0] != undefined) {
      return [
        sourceAsset,
        {
          displayName: 'Video Description Script',
          url: files[0].presignedDownloadUrl,
        },
      ];
    }
    return [sourceAsset];
  };

  const voiceOverQcJobDetails = (): JobDetailsRow[] =>
    upstreamJob?.user
      ? [
          {
            header: 'Voice Actor Name',
            value: upstreamJob.user.name,
          },
          {
            header: 'Voice Actor Email',
            value: upstreamJob.user.email,
          },
        ]
      : [];

  return (
    <OffPlatformJobLayout jobId={id} jobTypeName={'Off Platform Voice Over Quality Control Job'}>
      {includeOnPlatformMix && (
        <Row>
          <Alert variant="warning">
            The file should be set to a 0 start time as these files will be mixed on-platform from
            the start of the video.
          </Alert>
        </Row>
      )}
      <Row>
        <Col lg={6} className="mt-4">
          <OffPlatformJobDetails
            job={job}
            downloadableAssets={getAssets()}
            customJobDetails={voiceOverQcJobDetails()}
          />

          <Card>
            <Card.Header className="font-weight-bold">Script Files</Card.Header>
            <Card.Body>
              <UploadedFileList files={script.files} />
              <FileUploader
                assetId={script.id}
                assetType={AssetClassNameEnum.VoiceOverQualityControlScript}
                allowedFileFormats={script.allowedFileFormats}
                uploadedFiles={script.files}
              />
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header className="font-weight-bold">Audio Files</Card.Header>
            <Card.Body>
              <UploadedFileList files={qcAudio.files} />
              <FileUploader
                assetId={qcAudio.id}
                assetType={AssetClassNameEnum.VoiceOverQualityControlAudioAsset}
                allowedFileFormats={qcAudio.allowedFileFormats}
                uploadedFiles={qcAudio.files}
              />
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header className="font-weight-bold">Media Shuttle Instructions</Card.Header>
            <Card.Body>
              <MediaShuttleUploader
                assetFileId={qcAudio.id}
                instructions={qcAudio.mediaShuttleInstructions}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mt-4">
          <JobInstructions cheatSheets={orderInstructions} title={'Instructions'} />
          {service.__typename == 'AudioDescriptionRevisionServiceGraphql' && (
            <JobInstructions
              cheatSheets={service.revisionOrderInstructions}
              title={'Revision Instructions'}
            />
          )}
          <AssetInstructions instructions={audio.mediaShuttleInstructions} />
        </Col>
      </Row>
    </OffPlatformJobLayout>
  );
}
