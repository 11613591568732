import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';

import AlertMessage from '~/components/app/common/AlertMessage';
import WordlistModal from '../../common/wordlist/WordlistModal';
import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';
import WithIcon from '~/components/app/common/WithIcon';

import { threeplayApi } from '~/logic/ThreeplayApi';

const updateWordlistMutation = `
mutation UpdateWordlistMutation($eventId: ID!, $wordlist: [String!]) {
  updateWordlistMutation(eventId: $eventId, wordlist: $wordlist) {
    error,
    success
  }
}`;

const Wordlist = ({ event, initialWordlist }) => {
  const { id, name, editable, editableNote } = event;
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [show, setShow] = useState(false);
  const [wordlist, setWordlist] = useState(initialWordlist);

  const closeWordlistModal = () => setShow(false);

  const getFormattedWordlist = () => {
    return wordlist === '' ? null : wordlist.split('\n');
  };

  const updateAndCloseWordlistModal = async () => {
    const response = await threeplayApi.request(updateWordlistMutation, {
      eventId: id,
      wordlist: getFormattedWordlist(),
    });

    if (response.errors) {
      setError(response.errors[0].message);
      setTimeout(() => setError(null), 5000);
    } else if (response.data.updateWordlistMutation.error) {
      setError(response.data.updateWordlistMutation.error);
      setTimeout(() => setError(null), 5000);
    } else {
      setSuccess(response.data.updateWordlistMutation.success);
      setTimeout(() => setSuccess(null), 5000);
    }
    closeWordlistModal();
  };

  return (
    <>
      <Button onClick={() => setShow(true)} size="sm" variant="link outline-primary">
        {' '}
        {wordlist === null ? (
          <WithIcon icon="fa fa-plus">Add Wordlist</WithIcon>
        ) : (
          <WithIcon icon="fa fa-file-text-o">Edit Wordlist</WithIcon>
        )}
      </Button>
      <ModalErrorBoundary component="WordListModal">
        <WordlistModal
          onClose={closeWordlistModal}
          eventName={name}
          show={show}
          onUpdate={setWordlist}
          onSave={updateAndCloseWordlistModal}
          wordlist={wordlist}
          readOnly={!editable}
          alertNoteMessage={editableNote}
        />
      </ModalErrorBoundary>
      {error && <AlertMessage message={error} variant="danger" />}
      {success && <AlertMessage message={success} variant="success" />}
    </>
  );
};

Wordlist.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    editable: PropTypes.bool,
    editableNote: PropTypes.string,
  }),
  initialWordlist: PropTypes.string,
};

export default Wordlist;
