import moment from 'moment-timezone';
import { z } from 'zod';

export const baseInfoInput = z
  .object({
    name: z.string().min(1).max(255).describe('Event name'),
    eventStartTime: z.preprocess((arg) => {
      if (arg === '') return moment().toDate();
      if (typeof arg === 'string' || arg instanceof Date) return moment(arg).toDate();
      return moment().toDate();
    }, z.date().min(moment().add(5, 'minutes').toDate()).describe('Event Start Time')),
    serviceType: z.enum(['Professional', 'Automatic']).describe('The type of service'),
    orderType: z
      // This needs to be extended for each new live order type.
      .enum(['virtual_encoding'])
      .describe('The codename for the live order type'),
    batchId: z.number().int().describe('The associated batch').optional(),
  })
  .describe('Base event information');
