import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '~/components/app/live_auto_captioning/common/constants';

function BasicServiceInfo(props) {
  const service = props.service || {};
  const language = service.language || {};

  return (
    <>
      <Row>
        {props.showOrderedAt && (
          <Col xs={6}>
            <b>Ordered:</b>{' '}
            {moment(parseInt(service.orderedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </Col>
        )}
        {props.showDeadline && (
          <Col xs={6}>
            <b>Due Date:</b>{' '}
            {service.deadline === null
              ? 'Unknown'
              : moment(parseInt(service.deadline)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </Col>
        )}
        {props.showTurnaround && (
          <Col xs={6}>
            <b>Turnaround Level:</b> {service.turnaroundLevel}
          </Col>
        )}
        {props.showLanguage && (
          <Col xs={6}>
            <b>Language:</b> {language.name}
          </Col>
        )}
        {props.showComplete && service.status === 'complete' && (
          <Col xs={6}>
            <b>Completed:</b>{' '}
            {moment(parseInt(service.completedAt)).tz(props.userTimeZone).format(DATE_TIME_FORMAT)}
          </Col>
        )}
      </Row>
    </>
  );
}

BasicServiceInfo.propTypes = {
  service: PropTypes.shape({
    orderedAt: PropTypes.string,
    deadline: PropTypes.string,
    completedAt: PropTypes.string,
    turnaroundLevel: PropTypes.string,
    language: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  showOrderedAt: PropTypes.bool,
  showDeadline: PropTypes.bool,
  showComplete: PropTypes.bool,
  showTurnaround: PropTypes.bool,
  showLanguage: PropTypes.bool,
  userTimeZone: PropTypes.string,
};

export default BasicServiceInfo;
