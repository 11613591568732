import React, { useState, useContext } from 'react';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { PreviousButton, NextButton } from '../shared/ApplicationButtons';
import { FlipperFeatureContext } from '../../Apply';
import { hasFormErrors, isStringEmpty } from '../helpers';
import * as workflow from '../../applicationWorkflow';
import { applicantShape } from '../shapes';
import PleaseWait from '../PleaseWait';

const REFERRAL_SOURCES = [
  { id: 'google', label: 'Google Search' },
  { id: 'craigslist', label: 'Craigslist (include location below)' },
  { id: 'personal', label: 'Personal Referral (please specify)' },
  { id: 'indeed', label: 'Indeed' },
  { id: 'twitter', label: 'Twitter' },
  { id: 'posting', label: 'Other Position Posting' },
  { id: 'military', label: 'Military/Military Spouse/Veteran site' },
  { id: 'other', label: 'Other (please specify)' },
];

const validate = ({ wordsPerMinute, experience, languages }) => {
  const errors = {};

  if (!wordsPerMinute && wordsPerMinute !== 0) {
    errors.wordsPerMinute = 'Please enter a value.';
  } else if (wordsPerMinute > 250 || wordsPerMinute < 1) {
    errors.wordsPerMinute = 'Invalid value.';
  }

  if (isStringEmpty(experience)) {
    errors.experience = 'Please enter a value.';
  }

  if (isStringEmpty(languages)) {
    errors.languages = 'Please enter a value.';
  } else if (languages.length > 200) {
    errors.languages = 'Language list is too long (limit of 200 characters).';
  }

  return errors;
};

const getReferralTokenErrorMessage = (error) => {
  switch (error) {
    case 'token_not_found':
      return 'Please enter a valid referral code.';
    case 'inactive_token':
      return 'Referral code expired. Please enter a valid referral code.';
    case 'referral_limit_exhausted':
      return 'Referral limit reached. Please enter a valid referral code.';
  }
};

const getWordsPerMinute = ({ wordsPerMinute }) =>
  wordsPerMinute || wordsPerMinute === 0 ? wordsPerMinute : '';

const SkillzAndExperience = ({
  applicantInfo = {},
  previousStep = () => {},
  nextStep = () => {},
  submitSkillzAndExperience = workflow.submitSkillzAndExperience,
  authToken = {},
}) => {
  const [wordsPerMinute, setWordsPerMinute] = useState(getWordsPerMinute(applicantInfo));
  const [experience, setExperience] = useState(applicantInfo?.experience || '');
  const [languages, setLanguages] = useState(applicantInfo?.languages || '');
  const [referralSource, setReferralSource] = useState(applicantInfo?.referralSource || '');
  const [referralText, setReferralText] = useState(applicantInfo?.referralText || '');
  const [referralToken, setReferralToken] = useState(applicantInfo?.referralToken || '');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const flippers = useContext(FlipperFeatureContext);

  const serialize = () => ({
    wordsPerMinute: Number(wordsPerMinute),
    experience,
    languages,
    referralSource,
    referralText,
    referralToken,
  });

  const handleNextStep = () => {
    const formErrors = validate({ wordsPerMinute, experience, languages });
    setErrors(formErrors);

    if (hasFormErrors(formErrors)) {
      return;
    }

    const data = serialize();
    const applicant = { ...applicantInfo, ...data };

    setLoading(true);
    submitSkillzAndExperience(applicant, { authToken })
      .then((response) => {
        setLoading(false);

        if (response.errors) {
          setErrors({ submit: 'something went wrong' });
          return;
        }

        const { success, error, tests } = response?.data?.submitApplication
          ? response?.data?.submitApplication
          : {};

        if (!success || error) {
          const referralTokenError = getReferralTokenErrorMessage(error);
          referralTokenError
            ? setErrors({ referralToken: referralTokenError })
            : setErrors({ submit: 'something went wrong' });
          return;
        }

        nextStep({ applicantInfo: data, tests });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePreviousStep = () => {
    const data = serialize();
    previousStep({ applicantInfo: data });
  };

  return (
    <div className={'slide-up ' + css(styles.div)}>
      {!!errors.submit && errors.submit}
      {loading && <PleaseWait />}
      <h2>Next, let&apos;s move onto your skills and experience.</h2>

      <Form.Group controlId="wpm">
        <Form.Label className="mb-0">What&apos;s your typing speed?</Form.Label>
        <Form.Text className="text-muted mt-0 mb-1">
          If you don&apos;t know your typing speed, you can check it using this{' '}
          <a href="https://www.typingtest.com/test.html" target="_blank" rel="noreferrer">
            typing test
          </a>
        </Form.Text>
        <InputGroup>
          <Form.Control
            value={wordsPerMinute}
            type="number"
            onChange={(e) => setWordsPerMinute(e.target.value)}
            className={`${css(styles.small)}  ${errors.wordsPerMinute && 'is-invalid'}`}
          />
          <InputGroup.Append>
            <InputGroup.Text>words per minute</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {errors.wordsPerMinute && (
          <Form.Text className="text-danger">{errors.wordsPerMinute}</Form.Text>
        )}
      </Form.Group>

      <Form.Group controlId="experience">
        <Form.Label>
          Describe any past transcription experience that distinguishes you as a strong applicant.
        </Form.Label>
        <Form.Control
          as="textarea"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          rows={5}
          className={errors.experience && 'is-invalid'}
        />
        {errors.experience && <Form.Text className="text-danger">{errors.experience}</Form.Text>}
      </Form.Group>

      <Form.Group controlId="languages">
        <Form.Label>Which languages do you speak?</Form.Label>
        <Form.Control
          value={languages}
          onChange={(e) => setLanguages(e.target.value)}
          className={errors.languages && 'is-invalid'}
        />
        {errors.languages && <Form.Text className="text-danger">{errors.languages}</Form.Text>}
      </Form.Group>

      <Form.Group controlId="referral">
        <Form.Label>How did you hear about 3Play Media?</Form.Label>
        {REFERRAL_SOURCES.map(({ id, label }) => (
          <Form.Check
            key={id}
            name={'referral'}
            type={'radio'}
            checked={referralSource === id}
            id={`referral-${id}`}
            label={label}
            onChange={() => setReferralSource(id)}
          />
        ))}
        <Form.Control
          placeholder={'other / additional'}
          value={referralText}
          onChange={(e) => setReferralText(e.target.value)}
          className={css(styles.referralText)}
        />
      </Form.Group>

      {flippers?.contractorReferrals && (
        <Form.Group>
          <Form.Label>Do you have a referral code?</Form.Label>
          <Form.Control
            placeholder={'enter referral code'}
            value={referralToken}
            onChange={(e) => setReferralToken(e.target.value)}
            className={errors.referralToken && 'is-invalid'}
          />
          {errors.referralToken && (
            <Form.Text className="text-danger">{errors.referralToken}</Form.Text>
          )}
        </Form.Group>
      )}
      <>
        <PreviousButton onClick={handlePreviousStep} />
        <NextButton onClick={handleNextStep} disabled={loading} />
      </>
    </div>
  );
};

SkillzAndExperience.propTypes = {
  applicantInfo: applicantShape,
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  submitSkillzAndExperience: PropTypes.func,
  authToken: PropTypes.object,
};

const styles = StyleSheet.create({
  appendLabel: {
    fontWeight: 'bold',
    color: '#818A91',
  },
  small: {
    maxWidth: '5em',
  },
  referralText: {
    marginTop: '.5rem',
  },
  div: {
    maxWidth: '650px',
  },
});

export default SkillzAndExperience;
