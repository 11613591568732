import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import _ from 'lodash/core';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';

import { Typeahead } from 'react-bootstrap-typeahead';

import TranscoderSelect from './common/TranscoderSelect';

import { sortBatches } from './common/helpers';
import { threeplayApi } from '~/logic/ThreeplayApi';
import { projectLiveSettingsQuery, brightcoveEnabledQuery } from './data/queries';
import { batchesQuery, projectFlipperQuery } from '~/components/app/order_form/data/queries';
import { updateProjectLiveSettingsMutation } from './data/mutations';
import { useLiveTranscoders } from './common/useLiveTranscoders';
import {
  PROFANITY_FILTER_OPTIONS,
  HAL_EVENT_PER_MIN_RATE,
  LAC_EVENT_PER_MIN_RATE,
} from './common/constants';

import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

import {
  MAXIMUM_EMAIL_REMINDER_TIME,
  MAXIMUM_STREAM_WAIT_TIME,
  MINIMUM_STREAM_WAIT_TIME,
  MAXIMUM_CAPTIONING_DELAY,
  MINIMUM_CAPTIONING_DELAY,
} from '~/components/app/live_auto_captioning/common/constants';

function Settings({
  liveStaticEmbedKeys,
  liveTranscoders,
  maxStreamReconnectionWaitTime,
  flipperFeatures = {},
}) {
  const { liveSaveStreamEnabled, liveStaticEmbedKeyEnabled } = flipperFeatures;
  const [batches, setBatches] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasBrightcove, setHasBrightcove] = useState(false);
  const [defaultSettings, setDefaultSettings] = useState({});
  const [settings, setSettings] = useState({});
  const [settingsFetched, setSettingsFetched] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [validInputs, setValidInputs] = useState({
    batch: true,
    emailReminderTime: true,
    maxStreamTime: true,
    streamWaitTime: true,
    streamReconnectionWaitTime: true,
  });
  const [updating, setUpdating] = useState(false);

  const saveStreamEnabled = () => {
    const waitTimeIsValid = validInputs.streamReconnectionWaitTime;
    const hasSettings = !_.isEmpty(settings);
    // remove this line after we enable flipper
    const reconnectionDisabled = settings.streamReconnectionWaitTime === 0;

    return hasSettings && waitTimeIsValid && (liveSaveStreamEnabled || reconnectionDisabled);
  };
  const lacTranscoders = useLiveTranscoders(liveTranscoders, { lpc: false });
  const lpcTranscoders = useLiveTranscoders(liveTranscoders, { lpc: true });

  useEffect(() => {
    getProjectSettings({ defaultSettings: true });
    getProjectSettings({ defaultSettings: false });
  }, []);

  function getProjectSettings({ defaultSettings }) {
    threeplayApi
      .request(projectLiveSettingsQuery, { defaultSettings: defaultSettings })
      .then((res) => {
        const data = res.data || {};
        if (data.project.liveSettings) {
          if (defaultSettings) {
            setDefaultSettings(data.project.liveSettings);
          } else {
            setSettings(data.project.liveSettings);
            setSettingsFetched(true);
          }
        }
      });
  }

  useEffect(() => {
    isBrightcoveEnabled();
  }, []);

  function isBrightcoveEnabled() {
    threeplayApi.request(brightcoveEnabledQuery).then((response) => {
      if (response.data.project.brightcoveEnabled) {
        setHasBrightcove(response.data.project.brightcoveEnabled);
      }
    });
  }

  useEffect(() => {
    threeplayApi.request(batchesQuery).then((res) => {
      const data = res.data || {};
      if (data.batches) {
        const unarchivedBatches = sortBatches(data.batches);
        setBatches(unarchivedBatches);
      }
    });
  }, []);

  useEffect(() => {
    if (saveStreamEnabled()) {
      setSettings({ ...settings, saveEventStream: false });
    }
  }, [settings.streamReconnectionWaitTime]);

  const getSaveEventStreamTooltip = () => {
    return saveStreamEnabled()
      ? `If enabled, the live stream will be saved as the source for the file after the event.
      This is useful if service upgrades are anticipated, e.g. to Transcription.
      Leave this off otherwise as it incurs extra processing both during and after the event.
      This is only available for RTMP streams.`
      : `3Play currently doesn’t not offer the ability for streams to be saved if the stream reconnection wait time is set to a value greater than 0.`;
  };

  function verifyCaptioningDelay(value) {
    if (isNaN(value)) {
      setSettings({ ...settings, captioningDelay: MINIMUM_CAPTIONING_DELAY });
    }

    if (value < MINIMUM_CAPTIONING_DELAY) {
      setSettings({ ...settings, captioningDelay: MINIMUM_CAPTIONING_DELAY });
    } else if (value > MAXIMUM_CAPTIONING_DELAY) {
      setSettings({ ...settings, captioningDelay: MAXIMUM_CAPTIONING_DELAY });
    }
  }

  function validateAndUpdateState(type, value) {
    if (!isNaN(value)) {
      value = Number(value);
    }
    isValidValue(type, value);
    setSettings({ ...settings, [type]: value });
  }

  function isValidValue(type, value) {
    let isValid = false;
    switch (type) {
      case 'batch': {
        isValid = !!batches.find((batch) => batch.name === value);
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'emailReminderTime': {
        isValid = value <= MAXIMUM_EMAIL_REMINDER_TIME;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'maxStreamTime': {
        isValid = value <= defaultSettings.maxStreamTime;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'streamWaitTime': {
        isValid = value >= MINIMUM_STREAM_WAIT_TIME && value <= MAXIMUM_STREAM_WAIT_TIME;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
      case 'streamReconnectionWaitTime': {
        isValid = value >= 0 && value <= maxStreamReconnectionWaitTime;
        setValidInputs({ ...validInputs, [type]: isValid });
        return;
      }
    }
  }

  function updateProjectLiveSettings() {
    setUpdating(true);
    threeplayApi
      .request(updateProjectLiveSettingsMutation, { settings: settings })
      .then((response) => {
        if (response.errors) {
          setErrorMessage('Error Saving Settings: ' + response.errors[0].message);
          setSuccessMessage(null);
          setUpdating(false);
        } else if (response.data.updateProjectLiveSettings.error) {
          setErrorMessage(
            'Error Saving Settings: ' + response.data.updateProjectLiveSettings.error
          );
          setSuccessMessage(null);
          setUpdating(false);
        } else if (response.data.updateProjectLiveSettings.success) {
          setErrorMessage(null);
          setSuccessMessage(response.data.updateProjectLiveSettings.success);
          setUpdating(false);
          setTimeout(() => setSuccessMessage(null), 5000);
        }
      });
  }

  function saveButtonText() {
    if (updating) {
      return (
        <span>
          Saving Changes <i className="fa fa-spinner fa-spin"></i>
        </span>
      );
    } else {
      return 'Save Changes';
    }
  }

  function updateDefaultLacTranscoder(transcoder) {
    setSettings({ ...settings, defaultLacTranscoderId: transcoder?.id });
  }

  function updateDefaultLpcTranscoder(transcoder) {
    setSettings({ ...settings, defaultLpcTranscoderId: transcoder?.id });
  }

  function updateSelectedFolder(obj) {
    if (obj.length > 0) {
      setSettings({ ...settings, batchId: Number(obj[0].id) });
      setValidInputs({ ...validInputs, batch: true });
    }
  }

  /**
   * In order to set correct default values, we need to wait to render the form until
   * after the current settings are fetched. This results in the form appearing momentarily
   * empty, but allows selects to set the proper default values and protects against
   * controlled/uncontrolled component warnings.
   */
  if (!settingsFetched) return null;

  return (
    <ErrorBoundary component="LiveAutoCaptioningSettings">
      <Card>
        <Card.Header className="settings-card-header">Live Captioning Settings</Card.Header>
        <Card.Body>
          <p>
            Changing the settings below will change the default settings for all future events
            scheduled by users in this project, <strong>3Play Support</strong>.
            <br />
            Previously completed, in-progress, and scheduled events will not be affected. Users will
            have the option to overwrite these settings for individual events.
          </p>
          <Form>
            <>
              <Form.Group controlId="captioningServiceType">
                <Form.Label>
                  <strong>Service Type</strong>
                </Form.Label>
                <Form.Check
                  aria-label="Professional Captioning Service"
                  checked={settings.professionalCaptioning}
                  label={`Professional ($${HAL_EVENT_PER_MIN_RATE.toFixed(2)}/min)`}
                  onChange={() => setSettings({ ...settings, professionalCaptioning: true })}
                  name="professionalCaptioning"
                  type="radio"
                />
                <Form.Text muted>
                  Professional live captioning with higher levels of accuracy
                </Form.Text>
                <Form.Check
                  aria-label="Automatic Captioning Service"
                  checked={!settings.professionalCaptioning}
                  label={`Automatic ($${LAC_EVENT_PER_MIN_RATE.toFixed(2)}/min)`}
                  onChange={() => setSettings({ ...settings, professionalCaptioning: false })}
                  name="professionalCaptioning"
                  type="radio"
                />
                <Form.Text muted>
                  Automatically generated captions using 3Play’s proprietary technology
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="captioningFallback">
                <Form.Label>
                  <strong>Captioning Overtime Option</strong>
                  <ThreePlayTooltip
                    tooltipText="What should 3Play do if your event exceeds the estimated duration?
                  If choosing to continue with captions, please note that the price of professional
                  captions increases to $3.00 per minute after the estimated duration is exceeded."
                  />
                </Form.Label>
                <Form.Control
                  as="select"
                  className="w-50"
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      fallbackToAutomaticCaptions: e.target.value === 'true',
                    })
                  }
                  value={settings.fallbackToAutomaticCaptions}
                >
                  <option value={false}>
                    Continue with captions (contingent on captioner availability)
                  </option>
                  <option value={true}>Stop captions at end of estimated event duration</option>
                </Form.Control>
              </Form.Group>
            </>
            <Form.Group controlId="captioningDelay">
              <Form.Label>
                <strong>Maximum Captioning Delay</strong>
                <ThreePlayTooltip
                  tooltipText="This will set a delay between the stream we receive and the captions we output.
                  Longer latency will result in a more accurate output.
                  You can delay the playback stream to your viewers to keep the video and captions in sync.
                  Warning: do not delay the stream provided to us."
                />
              </Form.Label>
              <p>
                Longer latency will result in a more accurate output. You can delay the playback
                stream to your viewers to keep the video and captions in sync.
              </p>
              <InputGroup className="w-25">
                <Form.Control
                  aria-describedby="captionDelay"
                  aria-label="captionDelay"
                  isInvalid={
                    MINIMUM_CAPTIONING_DELAY > settings.captioningDelay ||
                    settings.captioningDelay > MAXIMUM_CAPTIONING_DELAY
                  }
                  onBlur={(e) => verifyCaptioningDelay(e.target.value)}
                  onChange={(e) =>
                    setSettings({ ...settings, captioningDelay: Number(e.target.value) || 0 })
                  }
                  placeholder="5000"
                  type="text"
                  value={settings.captioningDelay}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="captionDelay">ms</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  The captioning delay has to be a number between {MINIMUM_CAPTIONING_DELAY} and{' '}
                  {MAXIMUM_CAPTIONING_DELAY}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="w-25 d-flex" controlId="captionDelayRange">
              <span className="align-self-center">Quicker</span>
              <Form.Control
                className="mx-2 p-0"
                aria-label="captionDelaySlider"
                onChange={(e) =>
                  setSettings({ ...settings, captioningDelay: Number(e.target.value) })
                }
                min="2000"
                max="15000"
                step="500"
                type="range"
                value={settings.captioningDelay}
              />
              <span>More accurate</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Stream Wait Time</strong>
                <ThreePlayTooltip
                  tooltipText="Set an amount of time for our system to wait after the Stream Wait Time if no audio is detected.
                  Costs will be calculated from the Stream Wait Time, and the service will end if no audio is detected by the conclusion of the Stream Wait Time."
                />
              </Form.Label>
              <InputGroup className="w-25">
                <Form.Control
                  aria-describedby="streamWaitTime"
                  aria-label="streamWaitTime"
                  isInvalid={!validInputs.streamWaitTime}
                  onChange={(e) => validateAndUpdateState('streamWaitTime', e.target.value)}
                  placeholder="30"
                  type="text"
                  value={settings.streamWaitTime}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="streamWaitTime">min</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  The stream wait time has to be a valid number between {MINIMUM_STREAM_WAIT_TIME}{' '}
                  and {MAXIMUM_STREAM_WAIT_TIME}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Max Stream Time</strong>
                <ThreePlayTooltip
                  tooltipText="Set a maximum time for your live captioning event.
                  Maximum time will be calculated from Event Start Time, and will not include any delays or breaks.
                  When the max time is reached, captioning service will stop even if the event is still active.
                  Events in your project can be no longer than your Event Max Time Config."
                />
              </Form.Label>
              <InputGroup className="w-25">
                <Form.Control
                  aria-describedby="maxStreamTime"
                  aria-label="maxStreamTime"
                  isInvalid={!validInputs.maxStreamTime}
                  onChange={(e) => validateAndUpdateState('maxStreamTime', e.target.value)}
                  placeholder={defaultSettings.maxStreamTime}
                  type="text"
                  value={settings.maxStreamTime}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="maxStreamTime">min</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  The max wait time has to be a valid number under {defaultSettings.maxStreamTime}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="streamReconnectionWaitTime">
              <Form.Label>
                <strong>Stream Reconnection Wait Time</strong>
                <ThreePlayTooltip tooltipText="If your stream is disconnected from 3Play, specify how long 3Play should wait before ending your event. After this duration, you will no longer be able to reconnect to this event, and will need to schedule a new event in 3Play to continue captions." />
              </Form.Label>
              <InputGroup className="w-25">
                <Form.Control
                  aria-describedby="streamReconnectionWaitTime"
                  aria-label="streamReconnectionWaitTime"
                  isInvalid={
                    0 > settings.streamReconnectionWaitTime ||
                    settings.streamReconnectionWaitTime > maxStreamReconnectionWaitTime
                  }
                  onChange={(e) =>
                    validateAndUpdateState('streamReconnectionWaitTime', Number(e.target.value))
                  }
                  placeholder="10"
                  type="text"
                  value={settings.streamReconnectionWaitTime}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="captionDelay">min</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  The stream reconnection wait time has to be a number between 0 and{' '}
                  {maxStreamReconnectionWaitTime}.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Do you want to save all event streams?</strong>
                <ThreePlayTooltip tooltipText={getSaveEventStreamTooltip()} />
              </Form.Label>
              <Form.Check
                className="mb-2"
                disabled={!saveStreamEnabled()}
                aria-label="saveEventStream"
                onChange={(e) => setSettings({ ...settings, saveEventStream: e.target.checked })}
                label="Save Event Stream"
                type="checkbox"
                checked={settings.saveEventStream}
              />
            </Form.Group>
            {liveStaticEmbedKeyEnabled && (liveStaticEmbedKeys || []).length <= 1 && (
              <Form.Group controlId="use-static-embed-key-checkbox">
                <Form.Label>
                  <strong>Use Reserved Embed / External Webpage URL for caption delivery</strong>
                </Form.Label>
                <Form.Check
                  className="mb-2"
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      liveStaticEmbedKeyId: undefined,
                      useStaticEmbedKey: e.target.checked,
                    })
                  }
                  label="Enable reserved Embed / External Webpage URL"
                  type="checkbox"
                  checked={Boolean(settings.liveStaticEmbedKeyId) || settings.useStaticEmbedKey}
                />
              </Form.Group>
            )}
            {liveStaticEmbedKeyEnabled && (liveStaticEmbedKeys || []).length > 1 && (
              <Form.Group controlId="static-embed-key-select">
                <Form.Label className="d-block">
                  <strong>
                    Default Reserved Embed / External Webpage URL for caption delivery
                  </strong>
                </Form.Label>
                <span className="w-50 d-inline-block">
                  <Form.Control
                    as="select"
                    defaultValue={settings.liveStaticEmbedKeyId}
                    onChange={(e) =>
                      setSettings({ ...settings, liveStaticEmbedKeyId: e.target.value })
                    }
                  >
                    <option value={''}>None</option>
                    {liveStaticEmbedKeys.map((liveStaticEmbedKey) => (
                      <option key={liveStaticEmbedKey.id} value={liveStaticEmbedKey.id}>
                        {liveStaticEmbedKey.name}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label className="d-block">
                <strong>Default stream target for Live Auto Captioning</strong>
              </Form.Label>
              <span className="w-50 d-inline-block">
                <TranscoderSelect
                  transcoders={lacTranscoders}
                  selectedId={settings.defaultLacTranscoderId}
                  onChange={updateDefaultLacTranscoder}
                />
              </span>
            </Form.Group>
            <Form.Group>
              <Form.Label className="d-block">
                <strong>Default stream target for Professional Captioning</strong>
              </Form.Label>
              <span className="w-50 d-inline-block">
                <TranscoderSelect
                  transcoders={lpcTranscoders}
                  selectedId={settings.defaultLpcTranscoderId}
                  onChange={updateDefaultLpcTranscoder}
                />
              </span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Where would you like us to store this file upon completion?</strong>
              </Form.Label>
              {'batchId' in settings && batches.length > 0 && (
                <Typeahead
                  id="live-settings-batch-dropdown"
                  className="form-control-sm px-0 w-25"
                  isInvalid={!validInputs.batch}
                  labelKey="name"
                  options={batches}
                  onChange={(obj) => updateSelectedFolder(obj)}
                  onInputChange={(text) => isValidValue('batch', text)}
                  placeholder="Search Folder"
                  defaultInputValue={
                    batches.find((batch) => Number(batch.id) === Number(settings.batchId)).name
                  }
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Do you want to enable email reminders for scheduled live events?</strong>
              </Form.Label>
              <Form.Check
                className="mb-2"
                aria-label="enableEmailReminder"
                onChange={(e) =>
                  setSettings({ ...settings, enableEmailReminder: e.target.checked })
                }
                label="Enable Email Reminders"
                type="checkbox"
                checked={settings.enableEmailReminder}
              />
              <div>
                Send out an email reminder to the event host{' '}
                <InputGroup className={css(styles.reminderTimeInput)}>
                  <Form.Control
                    aria-describedby="emailReminderTime"
                    aria-label="emailReminderTime"
                    className="d-inline"
                    disabled={!settings.enableEmailReminder}
                    isInvalid={!validInputs.emailReminderTime}
                    onChange={(e) => validateAndUpdateState('emailReminderTime', e.target.value)}
                    placeholder="15"
                    type="text"
                    value={settings.emailReminderTime}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="emailReminderTime">min</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    The email reminder time has to be a valid number under 120.
                  </Form.Control.Feedback>
                </InputGroup>{' '}
                prior to the captions start time for an upcoming live event.
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <strong>Profanity Filter</strong>
                <ThreePlayTooltip
                  tooltipText="Set a sensitivity level for filtering out offensive words.
                  By default, 'Normal' will filter out words that most people agree are offensive.
                  Contact us for more detail."
                />
              </Form.Label>
            </Form.Group>
            {PROFANITY_FILTER_OPTIONS.map((option, index) => {
              return (
                <Form.Group key={index}>
                  <Form.Check
                    aria-label="profanityFilter"
                    label={option.name}
                    onChange={() => setSettings({ ...settings, profanityFilter: option.level })}
                    name="profanityFilter"
                    type="radio"
                    checked={settings.profanityFilter === option.level}
                  />
                  <Form.Text muted>{option.description}</Form.Text>
                </Form.Group>
              );
            })}
          </Form>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
        </Card.Body>
        <Card.Footer>
          <Button
            aria-label="saveEventSettings"
            disabled={Object.keys(validInputs).some((key) => {
              return !validInputs[key];
            })}
            onClick={() => updateProjectLiveSettings()}
            variant="primary"
          >
            {saveButtonText()}
          </Button>
          <Button className="ml-4" variant="secondary" onClick={() => setSettings(defaultSettings)}>
            Reset Settings
          </Button>
        </Card.Footer>
      </Card>
    </ErrorBoundary>
  );
}

Settings.propTypes = {
  flipperFeatures: PropTypes.shape({
    liveStaticEmbedKeyEnabled: PropTypes.bool,
    liveSaveStreamEnabled: PropTypes.bool,
  }),
  liveStaticEmbedKeys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  liveTranscoders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  maxStreamReconnectionWaitTime: PropTypes.number,
};

const styles = StyleSheet.create({
  badgeFontSize: {
    fontSize: '90%',
  },
  reminderTimeInput: {
    maxWidth: '120px',
    display: 'inline-flex',
  },
});

export default Settings;
