/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
import { uniq } from 'lodash';
import React, { createContext, ReactNode, useState } from 'react';

export type BestPracticeOption = 'wordList' | 'lpc' | 'lac';

interface UpdateBestPracticesParams {
  add?: BestPracticeOption[];
  remove?: BestPracticeOption[];
}

const initialBestPractices: BestPracticeOption[] = [];

const initialUpdateBestPractices = (params: UpdateBestPracticesParams) => {};

export const BestPracticesContext = createContext({
  bestPractices: initialBestPractices,
  updateBestPractices: initialUpdateBestPractices,
});

interface BestPracticesProviderProps {
  children: ReactNode;
}

export function BestPracticesProvider({ children }: BestPracticesProviderProps) {
  const [bestPractices, setBestPractices] = useState(initialBestPractices);

  const updateBestPractices = ({ add = [], remove = [] }: UpdateBestPracticesParams) => {
    setBestPractices(
      uniq([...add, ...bestPractices]).filter((bestPractice) => !remove.includes(bestPractice))
    );
  };

  return (
    <BestPracticesContext.Provider value={{ bestPractices, updateBestPractices }}>
      {children}
    </BestPracticesContext.Provider>
  );
}
