import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';

import { eventShape } from '../common/shapes';

import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';
import TipPanel from '~/components/app/common/TipPanel';
import WithIcon from '~/components/app/common/WithIcon';

function LiveEventDetails(props) {
  return (
    <>
      <div>
        <h5 className="mb-0">
          {props.getEventDetailsTitleText(props.event)}
          <Button
            onClick={() => props.openEventSettingsModal(props.event)}
            size="sm"
            variant="link outline-primary"
          >
            <WithIcon icon="fa fa-cog">Edit Advanced Settings</WithIcon>
          </Button>
        </h5>
        <small className="text-muted">
          <strong>Scheduled for {props.event.eventStartTime}</strong>, captions start{' '}
          {props.event.streamStartTime === 0
            ? 'at event'
            : `${props.event.streamStartTime} minutes before`}{' '}
          start time.
        </small>
      </div>
      <Form>
        {props.eventNameIsEditable && (
          <Row className="mt-2">
            <Col xl={4}>
              <Form.Group>
                <Form.Label>
                  <strong>Event Name</strong>
                </Form.Label>
                <Form.Control
                  aria-describedby="Event Name"
                  aria-label="eventName"
                  onChange={(e) => props.updateEventName(props.event.id, e.target.value)}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </Form>
      <div className="mt-2">
        <strong>Add Wordlist (optional)</strong>
        <ThreePlayTooltip
          tooltipText="Help 3Play more accurately caption your live event
            by providing a wordlist of commonly used terms and phrases."
        />
        <Button
          onClick={() => props.openWordListModalForEvent(props.event)}
          size="sm"
          variant="link outline-primary"
        >
          {props.event.wordList === null ? (
            <WithIcon icon="fa fa-plus">Add Wordlist</WithIcon>
          ) : (
            <WithIcon icon="fa fa-file-text-o">Edit Wordlist</WithIcon>
          )}
        </Button>
      </div>
    </>
  );
}
function AutomaticLiveCaptions(props) {
  return (
    <Row>
      <Col lg={{ span: 4, order: 2 }}>
        <TipPanel header="Best Practices for Automatic Live Captioned Events">
          <strong>
            3Play recommends you follow the tips below to ensure more accurate captions for your
            automatic live captioned events:
          </strong>
          <ul>
            <li>
              Captions for live events work best when the event contains high-quality audio and one
              person is speaking at a time (no overlapping audio)
            </li>
            <li>
              Add a wordlist of commonly used terms and phrases to more accurately caption your
              events
            </li>
          </ul>
        </TipPanel>
      </Col>
      <Col lg={8}>
        <Card>
          <Card.Header>
            <strong>Automatic Live Captions</strong> <small className="text-muted">$0.60/min</small>
          </Card.Header>
          <ListGroup className="list-group-flush">
            {props.events.map((e) => {
              return (
                <ListGroupItem key={e.id}>
                  <LiveEventDetails {...props} event={e} />
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
}

AutomaticLiveCaptions.propTypes = {
  events: PropTypes.arrayOf(eventShape),
  eventNameIsEditable: PropTypes.bool,
  getEventDetailsTitleText: PropTypes.func,
  updateEventName: PropTypes.func,
};

LiveEventDetails.propTypes = {
  event: eventShape,
  eventNameIsEditable: PropTypes.bool,
  getEventDetailsTitleText: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  updateEventName: PropTypes.func,
};

export default AutomaticLiveCaptions;
