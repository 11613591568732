import React from 'react';
import { Alert, Card } from 'react-bootstrap';

export function LpcBestPractices() {
  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Best Practices for Professional Events</Card.Title>
          <hr />
          <p>
            3Play recommends you follow the tips below to ensure more accurate captions for your
            professional live captioned events:
          </p>
          <ul>
            <li>
              Captions for live events work best when the event contains high-quality audio and one
              person is speaking at a time (no overlapping audio)
            </li>
            <li>
              Add event instructions and a wordlist to help 3Play better understand the context
              around your event
            </li>
          </ul>
        </Card.Body>
      </Card>
      <Alert className="mt-4" variant="warning">
        <strong>Note:</strong> Most events are successfully captioned by one of 3Play&apos;s
        captioners, but sometimes we are unable to provide a captioner for events due to capacity or
        time constraints.
      </Alert>
    </>
  );
}
