import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import { integrationDisplayName } from '~/helpers/strings';

function IntegrationSelector(props) {
  if (props.integrations.length === 1) {
    return <span>{integrationDisplayName(props.integrations[0].name, props.platform)}</span>;
  } else {
    return (
      <Form.Control
        as="select"
        className="w-25"
        onChange={(e) =>
          props.setFilterOptions({ ...props.filterOptions, integration: Number(e.target.value) })
        }
        size="sm"
        value={props.selectedIntegration}
      >
        {props.integrations.map((integration) => {
          return (
            <option key={integration.id} value={integration.id}>
              {integrationDisplayName(integration.name, props.platform)}
            </option>
          );
        })}
      </Form.Control>
    );
  }
}

IntegrationSelector.propTypes = {
  filterOptions: PropTypes.object,
  integrations: PropTypes.arrayOf(PropTypes.object),
  platform: PropTypes.string,
  selectedIntegration: PropTypes.number,
  setFilterOptions: PropTypes.func,
};

export default IntegrationSelector;
