import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import DateTimeComponent from '../DateTimeComponent';
import InfoPopover from '~/components/app/common/InfoPopover';
import ServiceTypeInfo from '../../common/ServiceTypeInfo';
import StartTimeOptions from '../../common/StartTimeOptions';
import moment from 'moment-timezone';

import { HAL_EVENT_PER_MIN_RATE, LAC_EVENT_PER_MIN_RATE } from '../../common/constants';
import { getAvailableStreamStartTimeOptions } from '../../common/startTimeHelper';

import WithIcon from '~/components/app/common/WithIcon';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

import { profileSettingsPath } from '~/helpers/app/paths';
import { eventShape } from '../../common/shapes';
import { validDate } from '../../common/validDateHelper';

function RealtimeWowza(props) {
  return (
    <>
      <p className="my-1 pt-2 realtime-wowza-appcues">
        <strong>
          Advanced Option: Do you want to use your own RTMP stream target (transcoder)?
        </strong>
      </p>
      <Form.Group className="mb-1">
        <Form.Check
          className="mr-2 d-inline"
          aria-label="Override 3Play provided RTMP stream target (transcoder)"
          onChange={() => props.updateRealTimeWowzaSelection()}
          label="Override 3Play provided RTMP stream target (transcoder)"
          type="checkbox"
        />
      </Form.Group>
    </>
  );
}

function StreamStartTime({ event, dispathSelectedEvent, userTimezone }) {
  const availableStreamTimes = getAvailableStreamStartTimeOptions(
    event.eventStartTime,
    userTimezone,
    event?.settings?.streamWaitTime
  );

  function setStreamStartTime(_eventId, streamStartTime) {
    dispathSelectedEvent({ type: 'streamStartTime', time: streamStartTime });
  }

  return (
    <div className="my-0 pt-1">
      <strong>
        How long before your event start time do you want to start streaming to 3Play?
        <ThreePlayTooltip
          tooltipText="The Stream Start Time determines how long before your Event Start Time 3Play listens for an active stream.
          Our captioners will check in 20 minutes prior to the Event Start Time to prep for the event and
          ensure captions show up at your specified Event Start Time."
        />
      </strong>
      <div className="w-25">
        <StartTimeOptions
          availableStartTimes={availableStreamTimes}
          eventId={event.id}
          startTime={event.streamStartTime}
          setStartTime={setStreamStartTime}
        />
      </div>
    </div>
  );
}

function Rtmp(props) {
  const [calendarOpen, setCalenderOpen] = useState(false);
  const [overrideRealtimeWowzaChecked, setOverrideRealtimeWowzaChecked] = useState(false);

  const selectedEvent = props.selectedEvents[0];
  const { eventsDispatch } = props;

  const MINUTES_AFTER_CURRENT_TIME_FOR_VALID_EVENT = 5;

  function dispathSelectedEvent(action) {
    eventsDispatch({ id: selectedEvent?.id, ...action });
  }

  function validRTMPDate() {
    if (!selectedEvent) {
      return true;
    }

    return validDate(
      selectedEvent.eventStartTime,
      props.userTimeZone,
      MINUTES_AFTER_CURRENT_TIME_FOR_VALID_EVENT
    );
  }
  useEffect(() => {
    // Selected Events are cleared on platform selection, triggering this form setup step only once
    if (props.selectedEvents.length === 0) {
      setupEventDefaults();
    }
    // since `defaultEvent` can change with async queries (projectSettings), we need it as a dependency.
    // TODO: pass projectSettings in as a prop to OrderPage, we can remove this dep
  }, [props.selectedEvents, props.defaultEvent]);

  function setupEventDefaults() {
    props.setIntegrationId(null);
    const existingEvent = selectedEvent || {};
    const event = { ...props.defaultEvent, ...existingEvent, id: '1' };
    eventsDispatch({ type: 'setEvents', events: [event] });
  }

  function updateStreamDetails(value, type) {
    // TODO: add validity states into the reducer
    dispathSelectedEvent({ type: 'streamUrl', ...selectedEvent.streamDetails, [type]: value });
  }

  function updateEventStartTime(time) {
    dispathSelectedEvent({ type: 'eventStartTime', time });
  }

  function updateEventName(name) {
    dispathSelectedEvent({ type: 'name', name });
  }

  function updateRealTimeWowzaSelection() {
    dispathSelectedEvent({ type: 'realTimeWowza', value: !selectedEvent.realtimeWowza });
    // TODO: use the event state value, not a new state value for realTimeWowzaChecked
    setOverrideRealtimeWowzaChecked(!overrideRealtimeWowzaChecked);
  }

  function updateEventServiceType(professionalCaptioning) {
    dispathSelectedEvent({ type: professionalCaptioning ? 'setLpc' : 'setLac' });
  }

  return (
    <>
      <>
        <p className="my-1 pt-1">
          <strong>
            Service Type <ServiceTypeInfo />
          </strong>
        </p>
        <div>
          <Form.Control
            className="w-25"
            as="select"
            onChange={(e) => updateEventServiceType(e.target.value === 'true')}
            size="sm"
            value={selectedEvent?.professionalCaptioning}
          >
            <option value={true}>Professional (${HAL_EVENT_PER_MIN_RATE.toFixed(2)}/min)</option>
            <option value={false}>Automatic (${LAC_EVENT_PER_MIN_RATE.toFixed(2)}/min)</option>
          </Form.Control>
        </div>
      </>
      <p className="my-0 pt-2">
        <strong>
          When will your event start?{' '}
          <InfoPopover linkText="Edit Timezone" title="Event Start Time">
            The <strong>Event Start Time</strong> is the expected start time your event will be
            displayed to your audience.
          </InfoPopover>
        </strong>
      </p>
      <span>
        {selectedEvent?.professionalCaptioning ? 'Professional' : 'Automatic'} captions will start
        at this time. Your timezone is currently set to <strong>{props.userTimeZone}</strong>.{' '}
        <a href={profileSettingsPath}>Edit Timezone</a>{' '}
        <InfoPopover linkText="Edit Timezone" title="Editing Timezones">
          If you have updated your timezone and are in the process of ordering a service in another
          tab, please restart your order to ensure your timezone is updated appropriately.
        </InfoPopover>
      </span>
      <div className="w-25">
        <DateTimeComponent
          calendarOpen={calendarOpen}
          handleTimeChange={(time) => updateEventStartTime(time)}
          isValidDate={(current) =>
            current.isAfter(moment().tz(props.userTimeZone).subtract(1, 'day'))
          }
          setCalenderOpen={setCalenderOpen}
          validDate={validRTMPDate}
          value={selectedEvent?.eventStartTime}
        />
      </div>
      {selectedEvent?.professionalCaptioning && (
        <StreamStartTime
          event={selectedEvent}
          dispathSelectedEvent={dispathSelectedEvent}
          userTimezone={props.userTimeZone}
        />
      )}
      {!selectedEvent?.settings?.transcoder && !selectedEvent?.professionalCaptioning && (
        <RealtimeWowza updateRealTimeWowzaSelection={updateRealTimeWowzaSelection} />
      )}
      {(selectedEvent?.settings?.transcoder || overrideRealtimeWowzaChecked) && (
        <>
          <p className="my-1 pt-1">
            <strong>What is your input stream’s URL and associated RTMP key?</strong>
          </p>
          {!selectedEvent?.settings?.transcoder && (
            <span>
              This URL is the media resource locator (MRL) for the live stream. It must include an
              audio signal and be accessible to our system. It must begin with &apos;rtmp://&apos;.
            </span>
          )}
          {selectedEvent?.settings?.transcoder && (
            <span>
              You have selected a pre-configured stream target with the following media resource
              locator (MRL)
            </span>
          )}
          <Form.Group>
            <FormControl
              aria-label="Stream URL"
              className="d-inline w-25"
              onChange={(e) => updateStreamDetails(e.target.value, 'url')}
              readOnly={selectedEvent?.settings?.transcoder}
              isInvalid={
                selectedEvent?.streamDetails?.url !== '' &&
                !props.validateStreamUrl(selectedEvent?.streamDetails?.url)
              }
              placeholder="rtmp://"
              size="sm"
              value={selectedEvent?.streamDetails?.url}
            />
            <FormControl
              aria-label="Stream key"
              className="d-inline ml-3 w-25"
              onChange={(e) => updateStreamDetails(e.target.value, 'key')}
              readOnly={selectedEvent?.settings?.transcoder}
              placeholder="RTMP Key (if required)"
              size="sm"
              value={selectedEvent?.streamDetails?.key}
            />
            <Form.Control.Feedback type="invalid">
              Sorry, you entered an invalid URL. Stream URLs start with &quot;rtmp://&quot;
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      <p className="my-1">
        <strong>Event Name</strong>
      </p>
      <FormControl
        aria-label="Event Name"
        className="w-25"
        onChange={(e) => updateEventName(e.target.value)}
        placeholder="Event Name"
        size="sm"
        value={props.selectedEvents.length > 0 && selectedEvent.name}
      />

      <Button
        className="pt-3"
        onClick={() => props.openWordListModalForEvent(selectedEvent)}
        size="sm"
        variant="link outline-primary"
      >
        {props.selectedEvents.length > 0 && selectedEvent.wordList === null ? (
          <WithIcon icon="fa fa-plus">Add WordList</WithIcon>
        ) : (
          <WithIcon icon="fa fa-file-text-o">Edit WordList</WithIcon>
        )}
      </Button>

      <Button
        className="d-block pt-2"
        onClick={() => props.openEventSettingsModal(selectedEvent)}
        size="sm"
        variant="link outline-primary"
      >
        <WithIcon icon="fa fa-cog">Edit Advanced Settings</WithIcon>
      </Button>
    </>
  );
}

RealtimeWowza.propTypes = {
  updateRealTimeWowzaSelection: PropTypes.func,
};

const projectLiveSettingsShape = PropTypes.shape({
  batchId: PropTypes.number,
  captioningDelay: PropTypes.number,
  streamWaitTime: PropTypes.number,
  maxStreamTime: PropTypes.number,
  saveEventStream: PropTypes.bool,
  profanityFilter: PropTypes.number,
});

Rtmp.propTypes = {
  defaultEvent: PropTypes.object,
  eventsDispatch: PropTypes.func.isRequired,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  projectLiveSettings: projectLiveSettingsShape,
  selectedEvents: PropTypes.arrayOf(PropTypes.object),
  setIntegrationId: PropTypes.func,
  userTimeZone: PropTypes.string,
  validateStreamUrl: PropTypes.func,
};

StreamStartTime.propTypes = {
  event: eventShape,
  dispathSelectedEvent: PropTypes.func.isRequired,
  userTimezone: PropTypes.string,
};

export default Rtmp;
