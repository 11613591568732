import React, { ReactElement, useContext } from 'react';
import { BestPracticeOption, BestPracticesContext } from '../../BestPracticesProvider';
import { LacBestPractices } from './LacBestPractices';
import { LpcBestPractices } from './LpcBestPractices';
import { WordListBestPractices } from './WordListBestPractices';

const BEST_PRACTICES_COMPONENTS: Record<BestPracticeOption, () => ReactElement> = {
  lac: LacBestPractices,
  lpc: LpcBestPractices,
  wordList: WordListBestPractices,
};

export function BestPractices() {
  const { bestPractices } = useContext(BestPracticesContext);

  return (
    <>
      {bestPractices.map((bestPractice: BestPracticeOption) => {
        const Component = BEST_PRACTICES_COMPONENTS[bestPractice];
        return <Component key={bestPractice} />;
      })}
    </>
  );
}
