import camelCase from 'lodash/camelCase';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import ModalErrorBoundary from '~/components/app/common/error_boundaries/ModalErrorBoundary';

import OrderConfirmation from './OrderConfirmation';
import OrderStepOne from './OrderStepOne';
import OrderStepTwo from './OrderStepTwo';

import EventSettingsModal from './EventSettingsModal';
import EventInstructionsModal from '../common/EventInstructionsModal';
import WordlistModal from '../../common/wordlist/WordlistModal';

import { userLogger } from '~/logic/UserLogger';
import { threeplayApi } from '~/logic/ThreeplayApi';

import { scheduleLiveEventsMutation } from '~/components/app/live_auto_captioning/data/mutations';
import { flipperFeaturesShape, liveStaticEmbedKeyShape } from '../common/shapes';
import { getRtmpStreamInfo } from '../common/helpers';
import defaultEventTemplate from './defaultEventTemplate';
import liveEventsReducer from './liveEventsReducer';
import { projectLiveSettingsQuery } from '../data/queries';

import '~/components/app/common/app.css';
import { stubTrue } from 'lodash';

export function stepTwoUISubmissionErrors(userSelectedEvents) {
  const errors = [];

  userSelectedEvents.forEach((event) => {
    if (!event.name) {
      errors.push('Please enter a name for all events.');
    } else if (event.professionalCaptioning) {
      if (event.professionalCaptioningSettings.eventType == 0)
        errors.push(`Please select an event type for ${event.name}.`);
      if (event.professionalCaptioningSettings.duration == 0)
        errors.push(`Please select a duration for ${event.name}.`);
      if (event.settings.maxStreamTime < event.professionalCaptioningSettings.duration) {
        errors.push(
          `${event.name}'s duration exceeds the max stream time. Please increase the max stream time or decrease your event duration time.`
        );
      }
    }
  });

  return errors;
}

export const FlipperFlagContext = React.createContext();

function OrderPage(props) {
  // TODO: destructure props in function definition
  const {
    batches,
    liveTranscoders,
    liveStaticEmbedKeys,
    userTimeZone = 'America/New_York',
  } = props;
  const [eventList, setEventList] = useState([]);
  const [error, setError] = useState(false);
  const [eventInstructions, setEventInstructions] = useState({ modal: false });
  const [eventSettings, setEventSettings] = useState({ modal: false });
  const [filterOptions, setFilterOptions] = useState({});
  const [integrationId, setIntegrationId] = useState(null);
  const [orders, setOrders] = useState({ confirmed: false, ordering: false });
  const [orderStep, setOrderStep] = useState(1);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [paginationParams, setPaginationParams] = useState({ entriesPerPage: 5 });
  const [projectLiveSettings, setProjectLiveSettings] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [wordList, setWordList] = useState({ modal: false });
  const [zoomUserDetails, setZoomUserDetails] = useState({});
  const [zoomUserSearch, setZoomUserSearch] = useState({ userEmail: '' });

  const DEFAULT_ENTRIES_PER_PAGE = 5;

  const defaultTranscoders = useMemo(() => {
    return getDefaultTranscoders({ projectLiveSettings, liveTranscoders });
  }, [projectLiveSettings, liveTranscoders]);

  // TODO - Maybe extract this into a custom hook to return a default event
  // which itself could be called from a reducer action like init
  const defaultEvent = useMemo(() => {
    return defaultEventTemplate({
      ...projectLiveSettings,
      ...defaultTranscoders,
      lpcEnabled: stubTrue,
      timeZone: userTimeZone,
      hasMultipleLiveStaticEmbedKeys: (liveStaticEmbedKeys || []).length > 1,
    });
  }, [defaultTranscoders, liveStaticEmbedKeys, projectLiveSettings, userTimeZone]);

  const [{ events: selectedEvents }, eventsDispatch] = useReducer(liveEventsReducer, {
    timeZone: userTimeZone,
    ...defaultTranscoders,
    events: [],
  });

  // TODO: temporary method to bridge the old (setSelectedEvents) and the new (eventsDispatch)
  function setSelectedEvents(value) {
    if (typeof value === 'function') {
      eventsDispatch({ type: 'setEvents', events: value(selectedEvents) });
    } else {
      eventsDispatch({ type: 'setEvents', events: value });
    }
  }

  useEffect(() => {
    eventsDispatch({ type: 'setDefaultTranscoders', ...defaultTranscoders });
  }, [defaultTranscoders]);

  useEffect(() => {
    if (selectedPlatform === null) {
      return;
    }

    if (selectedEvents.length) {
      setSelectedEvents([]);
    }

    setError(false);
    resetFilterOptions();
  }, [selectedPlatform]);

  // TODO: Move this query into components that need it; or pass in as a prop
  useEffect(() => {
    getProjectLiveSettings();
  }, []);

  const resetFilterOptions = () => {
    const integration = props.integrations[camelCase(selectedPlatform)];
    setFilterOptions({ integration: integration?.[0].id });
    setPaginationParams({ entriesPerPage: DEFAULT_ENTRIES_PER_PAGE });
    setEventList([]);
  };

  async function getProjectLiveSettings() {
    threeplayApi.request(projectLiveSettingsQuery, { defaultSettings: false }).then((res) => {
      const data = res.data || {};
      if (data.project?.liveSettings) {
        updateWordListForEvent;
        setProjectLiveSettings(data.project.liveSettings);
      }
    });
  }

  function formatEventsForOrder(events) {
    return events.map((event) => {
      // replace transcoder object with just external id
      const formattedSettings = {
        ...event.settings,
        transcoderId: event.settings.transcoder?.externalId,
      };
      delete formattedSettings.transcoder;
      return { ...event, settings: formattedSettings };
    });
  }

  async function scheduleLiveEvents() {
    amplitudeLogging();
    setOrders((prevState) => ({ ...prevState, ordering: true }));
    const eventsToSchedule = formatEventsForOrder(selectedEvents);

    const response = await threeplayApi.request(scheduleLiveEventsMutation, {
      events: eventsToSchedule,
      integrationId: integrationId,
      userEmail: userEmail,
    });

    if (response.errors || response.data.scheduleLiveEvents.error) {
      setError(true);
      setOrders((prevState) => ({ ...prevState, ordering: false }));
    } else {
      const eventDetails = response.data.scheduleLiveEvents.liveEventDetails;
      const eventErrors = response.data.scheduleLiveEvents.liveEventErrors;
      const userEmail = response.data.scheduleLiveEvents.userEmail;

      setError(false);
      setOrders((prevState) => ({
        ...prevState,
        confirmed: true,
        events: eventDetails,
        eventErrors: eventErrors,
        ordering: false,
        userEmail: userEmail,
      }));
    }
  }

  function amplitudeLogging() {
    userLogger.logEvent('LiveUX-v2', 'Schedule Live Captions', {
      Platform: selectedPlatform,
      'Type of event': getEventType(),
      '# of Events': selectedEvents.length,
      'Event Details': selectedEvents.map((event) => {
        return {
          id: event.externalId,
          settings: event.settings,
          wordListAdded: event.wordList !== null,
        };
      }),
    });
  }

  function getEventType() {
    switch (selectedEvents[0].jobType) {
      case 'meeting':
        return 'Zoom Meeting';
      case 'webinar':
        return 'Zoom Webinar';
      default:
        return 'Default';
    }
  }

  function getStreamDetailsForBatch(batchId) {
    const selectedBatch = batches.find((batch) => Number(batch.id) === batchId);
    return {
      key: selectedBatch?.reservedWowzaInstance?.rtmpKey || '',
      url: selectedBatch?.reservedWowzaInstance?.streamUrl || '',
    };
  }

  function openEventInstructionsModal(event) {
    setEventInstructions({
      ...eventInstructions,
      modal: true,
      event: event,
      instructions: event.instructions,
    });
  }

  function openEventSettingsModal(event) {
    setEventSettings({ ...eventSettings, modal: true, event: event });
  }

  function openWordListModalForEvent(event) {
    setWordList({ ...wordList, modal: true, event: event });
  }

  function findEvent(eventId) {
    // TODO: Use find instead of filter, and replace all instances of event[0] with just event
    return selectedEvents.filter((selectedEvent) => eventId === selectedEvent.id);
  }

  function updateEventInstructions(newInstructions) {
    const event = eventInstructions.event;
    // TODO: Better handle this case
    if (event === null) {
      return;
    }

    event.instructions = newInstructions;
    event.name = newInstructions.name;

    // updateSelectedEvents expects an array
    updateSelectedEvents([event]);
    setEventInstructions((prevState) => ({ ...prevState, modal: false }));
  }

  function updateEventSettings(eventId, settings) {
    const event = findEvent(eventId);
    event[0].settings = settings;
    event[0].streamDetails = getRtmpStreamInfo(settings.transcoder && settings.transcoder.url);
    updateSelectedEvents(event);
    setEventSettings((prevState) => ({ ...prevState, modal: false }));
    setProjectLiveSettings({ ...projectLiveSettings, ...settings });
  }

  function updateProfessionalEventSettings(eventId, type, value) {
    const event = findEvent(eventId);
    event[0].professionalCaptioningSettings = {
      ...event[0].professionalCaptioningSettings,
      [type]: value,
    };

    updateSelectedEvents(event);
  }

  function updateEventName(eventId, value) {
    const [event] = findEvent(eventId);
    const updatedEvent = { ...event, name: value };
    updateSelectedEvents([updatedEvent]);
  }

  function updateWordListForEvent(newWordlist) {
    if (newWordlist === '') {
      newWordlist = null;
    }

    const event = wordList.event;
    // TODO: Better handle this case
    if (event === null) {
      return;
    }
    event.wordList = newWordlist;

    // updateSelectedEvents expects an array
    updateSelectedEvents([event]);
  }

  function updateSelectedEvents(event) {
    const newArray = [...selectedEvents];
    const indexForEvent = newArray.findIndex((selectedEvent) => event[0].id === selectedEvent.id);
    newArray[indexForEvent] = event[0];
    setSelectedEvents(newArray);
  }

  function validDate() {
    if (selectedEvents.length === 0) {
      return false;
    }

    // TODO - This only verifies the format, doesn't check if the time is in future
    return selectedEvents.every((event) => moment(event.eventStartTime).isValid());
  }

  function validateStreamUrl(url) {
    if (url === null) {
      return false;
    }
    const streamUrlRegEx = /^(rtmp:\/\/|rtsp:\/\/)/;
    return streamUrlRegEx.test(url);
  }

  function disableOrderButton() {
    if (selectedEvents.length === 0 || orders.ordering) {
      return true;
    }

    switch (selectedPlatform) {
      case 'RTMP': {
        return (
          (!selectedEvents[0].realtimeWowza &&
            (selectedEvents[0].settings.transcoderId ||
              !validateStreamUrl(selectedEvents[0].streamDetails?.url))) ||
          !validDate()
        );
      }

      case 'Streamless': {
        return !selectedEvents[0].meetingLocation || !validDate();
      }

      case 'Zoom': {
        return !validDate();
      }
    }
  }

  const closeWordlistModal = () => setWordList({ ...wordList, modal: false });

  if (orders.confirmed) {
    return (
      <ErrorBoundary component="NewLiveAutoCaptioningOrderConfirmation">
        <FlipperFlagContext.Provider value={props.flipperFeatures}>
          <OrderConfirmation
            events={orders.events}
            eventErrors={orders.eventErrors}
            platform={selectedPlatform}
            userEmail={orders.userEmail}
            userTimeZone={userTimeZone}
          />
        </FlipperFlagContext.Provider>
      </ErrorBoundary>
    );
  } else if (orderStep === 1) {
    return (
      <FlipperFlagContext.Provider value={props.flipperFeatures}>
        <OrderStepOne
          {...props}
          defaultEvent={defaultEvent}
          defaultTranscoders={defaultTranscoders}
          disableOrderButton={disableOrderButton}
          error={error}
          eventList={eventList}
          filterOptions={filterOptions}
          findEvent={findEvent}
          getStreamDetailsForBatch={getStreamDetailsForBatch}
          integrationId={integrationId}
          eventsDispatch={eventsDispatch}
          openEventSettingsModal={openEventSettingsModal}
          openWordListModalForEvent={openWordListModalForEvent}
          orders={orders}
          paginationDetails={paginationDetails}
          paginationParams={paginationParams}
          projectLiveSettings={projectLiveSettings}
          scheduleLiveEvents={scheduleLiveEvents}
          selectedEvents={selectedEvents}
          selectedPlatform={selectedPlatform}
          setEventList={setEventList}
          setFilterOptions={setFilterOptions}
          setIntegrationId={setIntegrationId}
          setOrderStep={setOrderStep}
          setPaginationDetails={setPaginationDetails}
          setPaginationParams={setPaginationParams}
          setSelectedEvents={setSelectedEvents}
          setSelectedPlatform={setSelectedPlatform}
          setUserEmail={setUserEmail}
          setWordList={setWordList}
          setZoomUserDetails={setZoomUserDetails}
          setZoomUserSearch={setZoomUserSearch}
          updateSelectedEvents={updateSelectedEvents}
          userTimeZone={userTimeZone}
          validateStreamUrl={validateStreamUrl}
          validDate={validDate}
          wordList={wordList}
          zoomUserDetails={zoomUserDetails}
          zoomUserSearch={zoomUserSearch}
        />
        {eventSettings.modal && (
          <ModalErrorBoundary component="EventSettingsModal">
            <FlipperFlagContext.Provider value={props.flipperFeatures}>
              <EventSettingsModal
                batches={batches}
                closeEventSettingsModal={() =>
                  setEventSettings((prevState) => ({ ...prevState, modal: false }))
                }
                event={eventSettings.event}
                liveTranscoders={liveTranscoders}
                maxStreamReconnectionWaitTime={props.maxStreamReconnectionWaitTime}
                maxStreamTime={props.maxStreamTime}
                platform={selectedPlatform}
                scheduledEvent={false}
                show={eventSettings.modal}
                liveStaticEmbedKeys={liveStaticEmbedKeys}
                updateEventSettings={updateEventSettings}
              />
            </FlipperFlagContext.Provider>
          </ModalErrorBoundary>
        )}
        {wordList.modal && (
          <ModalErrorBoundary component="WordlistModal">
            <WordlistModal
              onClose={closeWordlistModal}
              eventName={wordList.event.name}
              show={wordList.modal}
              onUpdate={updateWordListForEvent}
              onSave={closeWordlistModal}
              wordlist={wordList.event.wordList}
            />
          </ModalErrorBoundary>
        )}
      </FlipperFlagContext.Provider>
    );
  } else if (orderStep === 2) {
    return (
      <>
        <OrderStepTwo
          openEventInstructionsModal={openEventInstructionsModal}
          openEventSettingsModal={openEventSettingsModal}
          openWordListModalForEvent={openWordListModalForEvent}
          orders={orders}
          platform={selectedPlatform}
          scheduleLiveEvents={scheduleLiveEvents}
          selectedEvents={selectedEvents}
          setOrderStep={setOrderStep}
          stepTwoUISubmissionErrors={stepTwoUISubmissionErrors(selectedEvents)}
          updateEventName={updateEventName}
          updateProfessionalEventSettings={updateProfessionalEventSettings}
          userTimeZone={userTimeZone}
          eventsDispatch={eventsDispatch}
        />
        <ModalErrorBoundary component="EventSettingsModal">
          <FlipperFlagContext.Provider value={props.flipperFeatures}>
            <EventSettingsModal
              batches={batches}
              closeEventSettingsModal={() =>
                setEventSettings((prevState) => ({ ...prevState, modal: false }))
              }
              event={eventSettings.event}
              liveTranscoders={liveTranscoders}
              maxStreamReconnectionWaitTime={props.maxStreamReconnectionWaitTime}
              maxStreamTime={props.maxStreamTime}
              platform={selectedPlatform}
              scheduledEvent={false}
              show={eventSettings.modal}
              liveStaticEmbedKeys={liveStaticEmbedKeys}
              updateEventSettings={updateEventSettings}
            />
          </FlipperFlagContext.Provider>
        </ModalErrorBoundary>
        {eventInstructions.modal && (
          <ModalErrorBoundary component="EventInstructionsModal">
            <EventInstructionsModal
              onClose={() => setEventInstructions({ ...eventInstructions, modal: false })}
              show={eventInstructions.modal}
              onSave={updateEventInstructions}
              intialInstructions={eventInstructions.instructions}
              eventName={eventInstructions.event.name}
            />
          </ModalErrorBoundary>
        )}
        {wordList.modal && (
          <ModalErrorBoundary component="WordlistModal">
            <WordlistModal
              onClose={closeWordlistModal}
              eventId={wordList.event.id}
              eventName={wordList.event.name}
              show={wordList.modal}
              onUpdate={updateWordListForEvent}
              onSave={closeWordlistModal}
              wordlist={wordList.event.wordList}
            />
          </ModalErrorBoundary>
        )}
      </>
    );
  }
}

OrderPage.propTypes = {
  flipperFeatures: flipperFeaturesShape,
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  maxStreamReconnectionWaitTime: PropTypes.number,
  maxStreamTime: PropTypes.number,
  platforms: PropTypes.arrayOf(PropTypes.string),
  userTimeZone: PropTypes.string,
};

function getDefaultTranscoders({ projectLiveSettings, liveTranscoders }) {
  if (!liveTranscoders) {
    return {};
  }

  const defaultLacTranscoder = liveTranscoders.find(
    (t) => t.id === projectLiveSettings.defaultLacTranscoderId
  );
  const defaultLpcTranscoder = liveTranscoders.find(
    (t) => t.id === projectLiveSettings.defaultLpcTranscoderId
  );
  return { defaultLacTranscoder, defaultLpcTranscoder };
}

export default OrderPage;
