import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

import { liveTranscoderShape } from '../common/shapes';

function TranscoderSelect({ transcoders, selectedId = '', onChange }) {
  function updateSelection(event) {
    const transcoderId = event.target.value;
    const transcoder = transcoderId ? transcoders.find((t) => String(t.id) === transcoderId) : null;
    onChange(transcoder);
  }

  return (
    <Form.Control
      as="select"
      value={selectedId}
      onChange={updateSelection}
      aria-label="transcoder-dropdown"
    >
      <option value={''}>None</option>
      {transcoders.map((transcoder) => (
        <option key={transcoder.id} value={transcoder.id}>
          {transcoder.name || 'Unnamed stream target'}
        </option>
      ))}
    </Form.Control>
  );
}

TranscoderSelect.propTypes = {
  transcoders: PropTypes.arrayOf(liveTranscoderShape),
  selectedId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default TranscoderSelect;
