import { z } from 'zod';

export const lpcInfoInput = z
  .object({
    duration: z.number().int().positive().describe('Event duration'),
    eventType: z.number().int().describe('Event type'),
    captioningOvertimeOption: z
      .enum(['Continue with professional captions', 'Fallback to automatic captions'])
      .describe('The captioning overtime options'),
    description: z.string().describe('Event description').optional(),
    speakerNames: z.object({ name: z.string() }).array().describe('Speaker names').optional(),
  })
  .describe('Professional event information');
