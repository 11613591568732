import React from 'react';
import { UseFormReturn, Path } from 'react-hook-form';

import { Input } from '@threeplayground/index';
import { ContactInfoInput } from '../../_types';

interface NestedContactInfoInput {
  contactInfo?: ContactInfoInput;
}

/**
 * The form partial for the contact information
 *
 * Note: This is currently present in all live order types, so it always returns
 * a JSX.Element. If this changes, then this will need an overload that returns
 * null when the contactInfo is not present for a given order type to avoid
 * typing issues in the generic OrderForm component.
 */
export function ContactInfo<TFieldValues extends NestedContactInfoInput>({
  methods,
}: {
  methods: UseFormReturn<TFieldValues>;
}) {
  const {
    register,
    formState: { errors },
  } = methods;
  return (
    <div className="mt-5 mb-5">
      <div className="mb-2">
        <h3 className="mb-0">Event Contact Information (optional)</h3>
        <small>
          Add contact information for the person managing your live event in case our support team
          needs to reach out.
        </small>
      </div>
      <Input
        label="Name*"
        error={errors?.contactInfo?.name}
        {...{
          ...register('contactInfo.name' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Input
        label="Email*"
        type="email"
        error={errors?.contactInfo?.email}
        {...{
          ...register('contactInfo.email' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
      <Input
        label="Phone number*"
        type="tel"
        error={errors?.contactInfo?.phoneNumber}
        {...{
          ...register('contactInfo.phoneNumber' as Path<TFieldValues>),
          min: undefined,
          max: undefined,
        }}
      />
    </div>
  );
}
