import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import DateTimeComponent from './DateTimeComponent';
import moment from 'moment-timezone';

import EventListingEmptyState from './EventListingEmptyState';
import FacebookPaginator from '../common/FacebookPaginator';
import Paginator from '../common/Paginator';
import InfoPopover from '~/components/app/common/InfoPopover';
import ServiceTypeInfo from '../common/ServiceTypeInfo';
import StartTimeOptions from '../common/StartTimeOptions';
import TokenPaginator from '../common/TokenPaginator';
import TooltipIfErrors from '~/components/app/common/TooltipIfErrors';

import {
  DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
  HAL_EVENT_PER_MIN_RATE,
  LAC_EVENT_PER_MIN_RATE,
  LANGUAGE_OPTIONS,
} from '../common/constants';
import {
  getAvailableStreamStartTimeOptions,
  getDefaultCaptionStartTime,
} from '../common/startTimeHelper';
import {
  eventShape,
  liveStaticEmbedKeyShape,
  paginationDetailsShape,
  projectLiveSettingsShape,
} from '../common/shapes';

import WithIcon from '~/components/app/common/WithIcon';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';

import { profileSettingsPath } from '~/helpers/app/paths';
import { validDate } from '../common/validDateHelper';
import { pluralize } from '~/helpers/strings';
import { Dropdown } from 'react-bootstrap';

function PaginationComponent(props) {
  switch (props.paginationDetails.type) {
    case 'facebook_paginator':
      return (
        <FacebookPaginator
          paginationDetails={props.paginationDetails}
          setPageAfter={(token) => props.setPageAfter(token)}
          setPageBefore={(token) => props.setPageBefore(token)}
        />
      );
    case 'paginator':
      return (
        <Paginator
          paginationParams={props.paginationParams}
          setPageNumber={(pageNumber) => props.setPaginationParams(Number(pageNumber))}
          totalPages={props.paginationDetails.totalPages}
        />
      );
    case 'token_paginator':
      return (
        <TokenPaginator
          paginationDetails={props.paginationDetails}
          setPageToken={(token) => props.setPaginationToken(token)}
        />
      );
    default:
      return null;
  }
}

function TimeComponent(props) {
  const MINUTES_AFTER_CURRENT_TIME_FOR_VALID_EVENT = 5;

  if (props.event.jobType === 'live_meeting') {
    return 'Active';
  }

  if (props.event.eventTimePresent && props.platform !== 'Brightcove') {
    return props.event.eventStartTime;
  }

  return (
    <DateTimeComponent
      calendarOpen={props.calendarOpen}
      handleTimeChange={(time) => props.updateTime(time)}
      isValidDate={(current) => current.isAfter(moment().tz(props.userTimeZone).subtract(1, 'day'))}
      setCalenderOpen={props.setCalenderOpen}
      userTimeZone={props.userTimeZone}
      validDate={() =>
        validDate(
          props.event.eventStartTime,
          props.userTimeZone,
          MINUTES_AFTER_CURRENT_TIME_FOR_VALID_EVENT
        )
      }
      value={props.event.eventStartTime}
    />
  );
}

function EventTableHeaders(props) {
  if (props.selectedEvents.length === 0) {
    return (
      <>
        <th className={css(styles.streamStartColumn)}></th>
        <th className={css(styles.wordListColumn)}></th>
        <th></th>
        <th></th>
      </>
    );
  }
  return (
    <ProfessionalServicesTableHeader enableLanguageSelection={props.enableLanguageSelection} />
  );
}

function ProfessionalServicesTableHeader(props) {
  return (
    <>
      {props.enableLanguageSelection && <th>Event Language</th>}
      <th className={css(styles.streamStartColumn)}>
        Service Type <ServiceTypeInfo />
      </th>
      <StreamStartHeader />
      <CaptionStartHeader />
    </>
  );
}

function CaptionStartHeader() {
  const lacText = 'Captions will start at Stream Start Time for automatically captioned events.';
  const lpcText = ' Captions will start at Event Start Time for professionally captioned events.';
  return (
    <th className={css(styles.wordListColumn)}>
      Caption Start
      <ThreePlayTooltip tooltipText={lacText + lpcText} />
    </th>
  );
}

const StreamStartHeader = () => {
  return (
    <th className={css(styles.streamStartColumn)}>
      Stream Start Time
      <ThreePlayTooltip
        tooltipText="Stream Start Time determines how long before your Event Start Time 3Play
        listens for an active stream"
      />
    </th>
  );
};

function EventTableData(props) {
  if (props.selectedEvents.length === 0) {
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    );
  }

  if (!props.eventSelected(props.event.id) || props.event.disabled) {
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    );
  }

  return <ProfessionalServicesTableData {...props} />;
}

function LACTableData(props) {
  return (
    <>
      <td>
        <EventTimeData {...props} />
      </td>
      <td>At stream start time</td>
      <td>
        <Button
          onClick={() => props.openWordListModalForEvent(props.event)}
          size="sm"
          variant="link outline-primary"
        >
          {props.event.wordList === null ? (
            <WithIcon icon="fa fa-plus">Add WordList</WithIcon>
          ) : (
            <WithIcon icon="fa fa-file-text-o">Edit WordList</WithIcon>
          )}
        </Button>
      </td>
      <td>
        <Button
          onClick={() => props.openEventSettingsModal(props.event)}
          size="sm"
          variant="link outline-primary"
        >
          <WithIcon icon="fa fa-cog">Edit Advanced Settings</WithIcon>
        </Button>
      </td>
    </>
  );
}

function ProfessionalServicesTableData(props) {
  const [canSelectServiceType, setCanSelectServiceType] = React.useState(true);

  React.useEffect(() => {
    // Only allow selecting service type for English events
    if (props.event.languageId) {
      setCanSelectServiceType(props.event.languageId === 1);
      props.setProfessionalService(props.event.id, props.event.languageId !== 1);
    }
  }, [props.event.languageId]);

  return (
    <>
      {props.enableLanguageSelection && (
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              {LANGUAGE_OPTIONS[props.event.languageId] || 'English'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.keys(LANGUAGE_OPTIONS).map((key) => {
                return (
                  <Dropdown.Item
                    key={key}
                    onClick={() => props.setEventLanguage(props.event.id, key)}
                  >
                    {LANGUAGE_OPTIONS[key]}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
      {canSelectServiceType ? (
        <td>
          <Form.Control
            as="select"
            onChange={(e) =>
              props.setProfessionalService(props.event.id, e.target.value === 'true')
            }
            size="sm"
            value={props.event.professionalCaptioning}
          >
            <option value={true}>Professional (${HAL_EVENT_PER_MIN_RATE.toFixed(2)}/min)</option>
            <option value={false}>Automatic (${LAC_EVENT_PER_MIN_RATE.toFixed(2)}/min)</option>
          </Form.Control>
        </td>
      ) : (
        <td>Professional (${HAL_EVENT_PER_MIN_RATE.toFixed(2)}/min)</td>
      )}
      <td>
        <EventTimeData {...props} />
      </td>
      <td>At {props.event.professionalCaptioning ? 'event' : 'stream'} start time</td>
    </>
  );
}

const EventTimeData = ({ event = {}, userTimeZone, setStreamStartTime }) => {
  const { eventStartTime, settings = {} } = event;
  const availableStreamTimes = getAvailableStreamStartTimeOptions(
    eventStartTime,
    userTimeZone,
    settings?.streamWaitTime
  );
  return event.jobType === 'live_meeting' ? (
    <span>
      ASAP
      <ThreePlayTooltip tooltipText="Live Auto Captioning events for Live Meetings will start 2 minutes after scheduling is complete." />
    </span>
  ) : (
    <>
      <span className={css(styles.startTimeSelect)}>
        <StartTimeOptions
          availableStartTimes={availableStreamTimes}
          eventId={event.id}
          startTime={event.streamStartTime}
          setStartTime={setStreamStartTime}
        />
      </span>
      {event.professionalCaptioning ? (
        <ThreePlayTooltip
          tooltipText="Stream Start Time determines how long before your Event Start Time 3Play
            listens for an active stream. Our captioners will check in 20 minutes prior to the
            Event Start Time to prep for the event and ensure captions show up at your specified
            Event Start Time."
        />
      ) : (
        <ThreePlayTooltip
          tooltipText="Stream Start Time determines how long before your Event Start Time 3Play
            listens for an active stream. For automatic captioned events, this is also the time
            captions will start to be sent to your stream."
        />
      )}
    </>
  );
};

function EventListing(props) {
  const [calendarOpen, setCalenderOpen] = useState({});

  const hasMultipleLiveStaticEmbedKeys = (props.liveStaticEmbedKeys || []).length > 1;

  function getEventRange(paginationParam, totalVideos) {
    const startIndex = (paginationParam.currentPage - 1) * paginationParam.entriesPerPage + 1;
    const endIndex = paginationParam.currentPage * paginationParam.entriesPerPage;
    return `${startIndex} - ${Math.min(endIndex, totalVideos)}`;
  }

  function selectedAllEvents(checked) {
    if (checked) {
      props.eventList.map((event) => {
        // Select only if not currently selected
        if (props.findEvent(event.id).length === 0) {
          updateEventSelection(event, checked);
        }
      });
    } else {
      props.setSelectedEvents([]);
    }
  }

  function updateEventSelection(event, checked) {
    const newArray = [...props.selectedEvents];
    if (checked) {
      const defaultProjectLiveSettings = props.projectLiveSettings || {};
      // Set the default caption start time to be same as the event start time
      const availableStreamStartTimes = getAvailableStreamStartTimeOptions(
        event.eventStartTime,
        props.userTimeZone,
        defaultProjectLiveSettings.streamWaitTime
      );
      event.streamStartTime = getDefaultCaptionStartTime(availableStreamStartTimes);

      event.wordList = null;

      const { defaultLpcTranscoder, defaultLacTranscoder } = props.defaultTranscoders || {};
      const defaultTranscoder = defaultProjectLiveSettings.professionalCaptioning
        ? defaultLpcTranscoder
        : defaultLacTranscoder;
      event.settings = {
        batchId: defaultProjectLiveSettings.batchId,
        captioningDelay: defaultProjectLiveSettings.captioningDelay,
        liveStaticEmbedKeyId: hasMultipleLiveStaticEmbedKeys
          ? defaultProjectLiveSettings.liveStaticEmbedKeyId
          : undefined,
        maxStreamTime: defaultProjectLiveSettings.maxStreamTime,
        profanityFilter: defaultProjectLiveSettings.profanityFilter,
        saveEventStream: defaultProjectLiveSettings.saveEventStream,
        streamReconnectionWaitTime: defaultProjectLiveSettings.streamReconnectionWaitTime,
        streamWaitTime: defaultProjectLiveSettings.streamWaitTime,
        transcoder: defaultTranscoder,
        useStaticEmbedKey: hasMultipleLiveStaticEmbedKeys
          ? undefined
          : Boolean(defaultProjectLiveSettings.liveStaticEmbedKeyId),
      };
      event.professionalCaptioning = defaultProjectLiveSettings.professionalCaptioning;
      event.professionalCaptioningSettings = {
        duration: 0,
        eventType: 0,
        fallbackToAutomaticCaptions: defaultProjectLiveSettings.fallbackToAutomaticCaptions,
      };

      event.instructions = {
        description: '',
        speakerNames: [''],
      };

      // Caption Conductor is a unique case where there is a live stream that the connector can
      // consume, but we don't have a stream that we can present to SWATEI (yet).
      event.hasLiveStream = true;

      props.setSelectedEvents((prevState) => prevState.concat(event));
    } else {
      props.setSelectedEvents(newArray.filter((selectedEvent) => event.id !== selectedEvent.id));
    }
  }

  function handleEventStartTimeChange(eventId, time) {
    try {
      time = time.format(DATE_TIME_FORMAT_WITHOUT_TIMEZONE);
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      // eslint-disable-next-line react/prop-types
      updateEventTime(time, eventId, props.eventList, props.setEventList);
      if (eventSelected(eventId)) {
        updateEventTime(time, eventId, props.selectedEvents, props.setSelectedEvents);
      }
    }
  }

  function updateEventTime(time, eventId, list, setList) {
    const index = list.findIndex((selectedEvent) => eventId === selectedEvent.id);
    const event = list[index];
    event.eventStartTime = time;

    const availableStreamStartTimes = getAvailableStreamStartTimeOptions(
      event.eventStartTime,
      props.userTimeZone,
      props?.projectLiveSettings?.streamWaitTime
    );
    event.streamStartTime = getDefaultCaptionStartTime(availableStreamStartTimes);

    const newArray = [...list];
    newArray[index] = event;
    setList(newArray);
  }

  function eventSelected(eventId) {
    return props.findEvent(eventId).length > 0;
  }

  function setRestreamUrl(eventId, restreamUrl) {
    const event = props.findEvent(eventId);
    event[0].restreamUrl = restreamUrl;

    props.updateSelectedEvents(event);
  }

  function setStreamStartTime(eventId, streamStartTime) {
    const event = props.findEvent(eventId);
    event[0].streamStartTime = streamStartTime;

    props.updateSelectedEvents(event);
  }

  function setProfessionalService(eventId, professionalCaptioning) {
    const event = props.findEvent(eventId);
    event[0].professionalCaptioning = professionalCaptioning;
    const { defaultLpcTranscoder, defaultLacTranscoder } = props.defaultTranscoders || {};
    event[0].settings.transcoder = professionalCaptioning
      ? defaultLpcTranscoder
      : defaultLacTranscoder;

    props.updateSelectedEvents(event);
  }

  function setEventLanguage(eventId, newLanguageId) {
    const event = props.findEvent(eventId);
    event[0].languageId = parseInt(newLanguageId, 10);

    props.updateSelectedEvents(event);
  }

  if (props.fetchingData) {
    return (
      <div className={css(styles.loading)}>
        <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
        <div className={css(styles.loadingText)}>Loading your data...</div>
      </div>
    );
  } else {
    return (
      <>
        <p className="my-1 pt-2">
          <strong>Which {props.eventType}(s) do you want to caption?</strong>
        </p>
        <p>
          Make sure your {props.eventType}s are scheduled in {props.platform} to have them show up
          here.
        </p>
        {props.eventList.length === 0 && (
          <EventListingEmptyState platform={props.platform} type={props.eventType} />
        )}
        {props.eventList.length > 0 && (
          <>
            <Table className="v-align-middle-table" bordered>
              <thead>
                <tr>
                  <th width="2%">
                    <Form.Check
                      className="text-center"
                      onChange={(e) => selectedAllEvents(e.target.checked)}
                      type="checkbox"
                    />
                  </th>
                  <th className={css(styles.nameColumn)}>{props.nameColumnText}</th>
                  <th className={css(styles.startTimeColumn)}>
                    Event Start Time ({moment.tz(props.userTimeZone).zoneAbbr()}){' '}
                    <InfoPopover linkText="Edit Timezone" linkUrl={profileSettingsPath}>
                      All event times are displayed in your local timezone. Your default timezone
                      can be edited in the Profile Settings page.
                    </InfoPopover>
                  </th>
                  <EventTableHeaders
                    enableLanguageSelection={props.liveCaptionConductorSpanishEnabled}
                    selectedEvents={props.selectedEvents}
                  />
                </tr>
              </thead>
              <tbody>
                {props.eventList.map((event) => {
                  return (
                    <TooltipIfErrors
                      errors={
                        props.disableAlreadyOrderedEvents && event.previouslyOrdered
                          ? [
                              'Captions have already been scheduled for this event. If this is not a recurring meeting, your event might not be successfully captioned.',
                            ]
                          : []
                      }
                      key={event.id}
                      location="top"
                      noSpan
                    >
                      <tr
                        className={
                          props.disableAlreadyOrderedEvents && event.previouslyOrdered
                            ? css(styles.disabled)
                            : ''
                        }
                      >
                        <td>
                          <Form.Check
                            checked={eventSelected(event.id)}
                            className="text-center"
                            onChange={(e) => updateEventSelection(event, e.target.checked)}
                            type="checkbox"
                          />
                        </td>
                        <td>{event[props.nameColumnKey || 'name']}</td>
                        <td>
                          <TimeComponent
                            calendarOpen={calendarOpen[event.id] || false}
                            event={event}
                            platform={props.platform}
                            setCalenderOpen={(open) =>
                              setCalenderOpen({ ...calendarOpen, [event.id]: open })
                            }
                            updateTime={(time) => handleEventStartTimeChange(event.id, time)}
                            userTimeZone={props.userTimeZone}
                          />
                        </td>
                        <EventTableData
                          event={event}
                          eventSelected={eventSelected}
                          openEventSettingsModal={props.openEventSettingsModal}
                          openWordListModalForEvent={props.openWordListModalForEvent}
                          selectedEvents={props.selectedEvents}
                          setStreamStartTime={setStreamStartTime}
                          setRestreamUrl={setRestreamUrl}
                          setProfessionalService={setProfessionalService}
                          userTimeZone={props.userTimeZone}
                          validateStreamUrl={props.validateStreamUrl}
                          setEventLanguage={setEventLanguage}
                          enableLanguageSelection={props.liveCaptionConductorSpanishEnabled}
                        />
                      </tr>
                    </TooltipIfErrors>
                  );
                })}
              </tbody>
            </Table>
            <div className={css(styles.pagination)}>
              <div className="d-flex justify-content-between">
                <span className="m-2">
                  {props.selectedEvents.length > 0
                    ? `Selected ${pluralize('Event', props.selectedEvents.length)}`
                    : ''}
                </span>
                <PaginationComponent {...props} />
                <span className="m-2">
                  {props.paginationDetails.totalVideos !== -1 &&
                    `Showing ${
                      props.paginationDetails.totalPages === 1
                        ? 'all'
                        : getEventRange(props.paginationParams, props.paginationDetails.totalVideos)
                    } of ${pluralize('Event', props.paginationDetails.totalVideos)}`}
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  disabled: {
    background: '#D5D5D5',
  },
  loading: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  nameColumn: {
    width: '40%',
  },
  startTimeColumn: {
    width: '15%',
  },
  streamStartColumn: {
    width: '15%',
  },
  wordListColumn: {
    width: '10%',
  },
  startTimeSelect: {
    width: '85%',
    display: 'inline-block',
  },
  loadingText: {
    fontSize: '1.5rem',
    marginTop: '.2rem',
  },
  pagination: {
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    padding: '5px 10px',
  },
});

EventListing.propTypes = {
  brightcove608: PropTypes.bool,
  projectLiveSettings: projectLiveSettingsShape,
  updateSelectedEvents: PropTypes.func,
  disableAlreadyOrderedEvents: PropTypes.bool,
  eventList: PropTypes.arrayOf(eventShape),
  eventType: PropTypes.string,
  fetchingData: PropTypes.bool,
  findEvent: PropTypes.func,
  liveStaticEmbedKeys: PropTypes.arrayOf(liveStaticEmbedKeyShape),
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  paginationDetails: paginationDetailsShape,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number,
    entriesPerPage: PropTypes.number,
  }),
  defaultTranscoders: PropTypes.shape({
    defaultLacTranscoder: PropTypes.number,
    defaultLpcTranscoder: PropTypes.number,
  }),
  platform: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(eventShape),
  setEventlist: PropTypes.func,
  nameColumnKey: PropTypes.string,
  setSelectedEvents: PropTypes.func,
  userTimeZone: PropTypes.string,
  nameColumnText: PropTypes.string,
  validateStreamUrl: PropTypes.string,
  liveCaptionConductorSpanishEnabled: PropTypes.bool,
};

EventTableHeaders.propTypes = {
  enableLanguageSelection: PropTypes.bool,
  selectedEvents: PropTypes.arrayOf(eventShape),
};

EventTableData.propTypes = {
  event: eventShape,
  eventSelected: PropTypes.func,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(eventShape),
  setStreamStartTime: PropTypes.func,
  setProfessionalService: PropTypes.func,
  wordList: PropTypes.shape({
    show: PropTypes.bool,
    event: eventShape,
  }),
  enableLanguageSelection: PropTypes.bool,
};

LACTableData.propTypes = {
  event: eventShape,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  restreamUrl: PropTypes.string,
  setStreamStartTime: PropTypes.func,
  setRestreamUrl: PropTypes.func,
  validateStreamUrl: PropTypes.func,
};

EventTimeData.propTypes = {
  event: eventShape,
  userTimeZone: PropTypes.string,
  setStreamStartTime: PropTypes.func,
};

ProfessionalServicesTableData.propTypes = {
  event: eventShape,
  openEventSettingsModal: PropTypes.func,
  openWordListModalForEvent: PropTypes.func,
  restreamUrl: PropTypes.string,
  setStreamStartTime: PropTypes.func,
  setProfessionalService: PropTypes.func,
  setRestreamUrl: PropTypes.func,
  validateStreamUrl: PropTypes.func,
  setEventLanguage: PropTypes.func,
  enableLanguageSelection: PropTypes.bool,
};

PaginationComponent.propTypes = {
  paginationDetails: paginationDetailsShape,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number,
    entriesPerPage: PropTypes.number,
  }),
  setPageAfter: PropTypes.func,
  setPageBefore: PropTypes.func,
  setPaginationParams: PropTypes.func,
  setPaginationToken: PropTypes.func,
};

TimeComponent.propTypes = {
  calendarOpen: PropTypes.bool,
  event: eventShape,
  platform: PropTypes.string,
  setCalenderOpen: PropTypes.func,
  updateTime: PropTypes.func,
  userTimeZone: PropTypes.string,
};
ProfessionalServicesTableHeader.propTypes = {
  enableLanguageSelection: PropTypes.bool,
};

export default EventListing;
